import React from 'react'

export default function ViewRouteIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
      <g clipPath="url(#clip0_8625_3746)">
        <path d="M5.74999 17.9203C5.61655 17.8976 5.48457 17.8671 5.35474 17.8288L3.20299 17.1538C2.42488 16.9252 1.74153 16.4511 1.25493 15.8023C0.768335 15.1535 0.504567 14.3648 0.502991 13.5538V4.4998C0.503771 3.88333 0.656517 3.27657 0.94771 2.73321C1.2389 2.18985 1.65956 1.72666 2.17245 1.38463C2.68533 1.04261 3.27463 0.832305 3.88817 0.772332C4.50171 0.71236 5.12058 0.80457 5.68999 1.0408L5.74999 1.06855V17.9203ZM16.01 0.875051L15.9942 0.869801L13.958 0.194801C13.7276 0.120086 13.4905 0.0680884 13.25 0.0395508V16.7361L14.7905 17.1801C15.2319 17.2875 15.6919 17.2933 16.1359 17.197C16.5799 17.1007 16.9962 16.9048 17.3534 16.6241C17.7106 16.3435 17.9995 15.9853 18.1981 15.5768C18.3967 15.1682 18.4999 14.7198 18.5 14.2656V4.4068C18.4989 3.63343 18.2591 2.87925 17.8135 2.24717C17.3678 1.6151 16.738 1.13593 16.01 0.875051ZM11.75 0.142301C11.75 0.142301 7.36774 1.39705 7.24999 1.4098V17.9098C7.32499 17.8941 11.75 16.6828 11.75 16.6828V0.142301Z" fill="#F5F5FF"/>
      </g>
      <defs>
        <clipPath id="clip0_8625_3746">
          <rect width="18" height="18" fill="white" transform="translate(0.5)"/>
        </clipPath>
      </defs>
    </svg>
  )
}