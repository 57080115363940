import React, { useEffect, useMemo, useState } from 'react';
import {
    TextField,
    Grid,
    Typography,
    Card,
    InputLabel,
    Box,
    Select,
    MenuItem,
    FormControl,
    FormHelperText,
    Stack,
    CircularProgress,
    Alert,
    InputAdornment,
    FormGroup,
    FormControlLabel,
    Switch,
} from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { makeStyles } from '@mui/styles';
import MDButton from 'components/MDButton';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories, openSnackbar, uploadProductImage } from '../../redux/slices/common';
import { addProduct, createProductBatch, deleteProduct, deleteProductBatch, fetchProductInstructions, updateProduct, updateProductBatch } from '../../redux/slices/products';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import BatchTable from './batch';
import InventroyTable from './inventory';
import { productValidations } from 'validations/product';
import { isEmptyObject } from 'utilities/common';
import ProductTerpenoids from './productTerpenoids';
import AddProductTerpenoid from './productTerpenoids/addProductTerpenoid';
import { closeCustomModalDialog, openCustomModalDialog, setModalLoader } from '../../redux/slices/modalSlice';
import { batchValidation } from 'validations/batch';
import { DatePicker } from '@mui/x-date-pickers';
import CalendarIcon from 'common/customIcons/calendar';
import dayjs from 'dayjs';
import { AccountCircle } from '@mui/icons-material';
import CommonQuillEditor from 'common/editor';
import { grey } from '@mui/material/colors';
import { isNumber } from 'utilities/common';
import ImageDragger from 'common/imageDragger';
import ProductActivityLogs from './ProductActivityLogs';

const useStyles = makeStyles((theme) => ({
    form: {
        marginTop: theme.spacing(4),
    },
    inputField: {
        marginBottom: theme.spacing(2),
    },
}));

const initialState = {
    productId: '', // You might generate this automatically
    productName: '',
    productImages: [],
    status: 'inactive',
    isSalePrice: false,
    unitPrice: 0,
    salePrice: '',
    categoryName: '',
    brandName: '',
    createdBy: '',
    tags: [],
    strainSlug: '',
    strainType: '',
    infoEffects: [],
    categoryId: '',
    sku: '',
    brandDescription: '',
    productDescription: '',
    purchaseDate: '',
    uniqueNumber: '',
    expirationDate: null,
    batchStatus: 'active',
    trackingSystem: '',
    purchaseQuantity: '',
    currentQuantity: '',
    unitCost: '',
    thcUnit: "",
    thcValue: "",
    productWeightUnit: "",
    productWeightValue: '',
    heading: "",
    ingredientStatus: false,
    ingredients: "",
    instructionStatus: false,
    instructions: ''
}

const AddProductForm = () => {
    const classes = useStyles();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { infoEffectData = [], categoriesData, isUploading = false } = useSelector(state => state.common)
    const { singleProduct = {}, loading = false, isUpdating } = useSelector(state => state.products)
    const { user } = useSelector(state => state.auth)
    const { brandsData = {} } = useSelector(state => state.brands)
    const [currentTab, setCurrentTab] = useState('products')
    const { productId = "" } = useParams()
    const [isBatchAddOpen, setIsBatchAddOpen] = useState(false)
    const [errors, setErrors] = useState({})
    const { search } = useLocation()
    const [editBatch, setEditBatch] = useState({})
    const [editTerpenoid, setEditTerpenoid] = useState({})

    const [isTerpeneAddOpen, setIsTerpeneAddOpen] = useState(false)


    const [formData, setFormData] = useState({
        productId: '', // You might generate this automatically
        productName: '',
        productImages: [],
        status: 'inactive',
        isSalePrice: false,
        unitPrice: 0,
        salePrice: '',
        categoryName: '',
        brandName: '',
        createdBy: user?.name || "",
        tags: [],
        strainSlug: '',
        strainType: '',
        infoEffects: [],
        categoryId: '',
        sku: '',
        brandDescription: '',
        productDescription: '',
        purchaseDate: undefined,
        uniqueNumber: '',
        expirationDate: null,
        trackingSystem: '',
        purchaseQuantity: '',
        currentQuantity: '',
        unitCost: '',
        batchStatus: 'active',
        ingredientStatus: false,
        ingredients: "",
        instructionStatus: false,
        instructions: ''

    });

    const removeImageHandler = (productIdx = '') => {
        let productImages = [...formData?.productImages];
        let newProductImages = productImages?.filter(((product, _productIdx) => _productIdx !== productIdx))
        setFormData({ ...formData, productImages: newProductImages })

    }


    const handleSubmit = (e) => {
        e.preventDefault();
        const { isValid, errors } = productValidations(formData)
        setErrors(errors)
        if (!isValid) return;
        // Add logic here to handle form submission
        let payload = { ...formData, updatedBy: user?.name }
        let customWeight = formData.productWeightValue && formData?.productWeightUnit ? `${formData.productWeightValue}${formData?.productWeightUnit}` : ""
        let thcData = formData.thcValue && formData?.thcUnit ? `${formData.thcValue}${formData?.thcUnit}` : ""
        payload = {
            ...payload,
            salePrice: +payload.salePrice,
            unitPrice: +payload.unitPrice,
            unitCost: +payload.unitPrice,
            customWeight,
            thcData,
        }
        delete payload.productWeightValue
        delete payload.productWeightUnit
        delete payload.thcValue
        delete payload.thcUnit
        if (productId) {
            dispatch(updateProduct({ data: payload, productId })).unwrap().then((res) => {
                showError(res?.message, 'success')
                navigate(`/products/details/${productId}?filter=products`)
            }).catch((err) => {
                showError(err?.message, 'error')
            })
        } else {
            dispatch(addProduct(payload)).unwrap().then((res) => {
                showError(res?.message, 'success')
                navigate(`/products/details/${res?.productData?.productId}?filter=products`)
            }).catch((err) => {
                showError(err?.message, 'error')
            })
        }
    };

    const showError = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
    }

    const handleInstructions = (value) => {
        dispatch(fetchProductInstructions({ categoryName: value })).unwrap()
            .then(res => {
                setFormData((prevData) => ({ ...prevData, instructions: res?.instruction }))
            })
    }

    const handleBatchProductSubmit = (e) => {
        e.preventDefault();
        const { purchaseDate, uniqueNumber, expirationDate, batchStatus, trackingSystem, purchaseQuantity, currentQuantity, unitCost } = formData;

        const { isValid = false, errors = {} } = batchValidation({ purchaseDate, uniqueNumber, expirationDate, batchStatus, trackingSystem, purchaseQuantity, currentQuantity, unitCost })

        if (!isValid) {
            setErrors(errors)
            return;
        }
        setErrors({})
        // Prepare payload
        const payload = {
            purchaseDate: dayjs(purchaseDate).valueOf(),
            uniqueNumber,
            expirationDate: expirationDate ? dayjs(expirationDate).valueOf() : null,
            status: batchStatus,
            trackingSystem,
            purchaseQuantity: +purchaseQuantity,
            currentQuantity: +currentQuantity,
            unitCost: +unitCost,
            updatedBy: user?.name,
        };

        if (!isEmptyObject(editBatch)) {
            // If editing an existing batch
            const { purchaseDate, uniqueNumber, expirationDate, batchStatus, trackingSystem, purchaseQuantity, currentQuantity, unitCost } = formData;
            const { productBatchId } = editBatch;

            dispatch(updateProductBatch({ data: { ...payload, productId }, productBatchId }))
                .unwrap()
                .then((res) => {
                    showError(res?.message, 'success')
                    batchHandler();
                    setFormData({ ...formData, purchaseDate: "", uniqueNumber: "", expirationDate: null, batchStatus: "", trackingSystem: "", purchaseQuantity: "", currentQuantity: "", unitCost: "" })
                }).catch((err) => {
                    showError(err?.message, 'error')
                });
        } else {
            // If creating a new batch
            dispatch(createProductBatch({ ...payload, productId }))
                .unwrap()
                .then((res) => {
                    showError(res?.message, 'success')
                    batchHandler();
                }).catch((err) => {
                    showError(err?.message, 'error')
                })
        }
    };
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (name === 'productWeightValue' && !formData?.productWeightUnit) {
            setErrors({ ...errors, productWeightUnit: 'Please select product weight unit first' })
            return
        };
        if (name === 'thcValue' && !formData?.thcUnit) {
            setErrors({ ...errors, thcUnit: 'Please select product thc unit first' })
            return;
        }
        if ((name === 'currentQuantity' && value && !isNumber(value)) || (name === 'purchaseQuantity' && value && !isNumber(value))) return;
        if ((name === 'thcValue' && Math.sign(value) == -1) || (name === 'productWeightValue' && Math.sign(value) == -1) || (name === 'salePrice' && Math.sign(value) == -1) || (name === 'unitPrice' && Math.sign(value) == -1) || (name === 'unitCost' && Math.sign(value) == -1)) return;
        if (name === 'productWeightValue' && value >= 100000) return;
        if (name === 'thcValue' && formData?.thcUnit === '%' && value > 100) return;
        if (name === 'thcValue' && formData?.thcUnit === 'mg' && value >= 100000) return;

        if (name === 'categoryName') {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
            handleInstructions(value)
        }

        if (name === 'tags') {
            if (value.includes(',')) {
                const separatedArray = value.split(',');
                setFormData({ ...formData, tags: separatedArray })
            } else {
                setFormData({ ...formData, tags: [value] })
            }
            return
        }
        let errorObj = { ...errors }
        delete errorObj[name]
        setErrors({ ...errorObj })
        if (name === 'productWeightUnit') {
            setFormData({ ...formData, productWeightValue: '', productWeightUnit: value })
            return;
        }
        if (name === 'thcUnit') {
            setFormData({ ...formData, thcValue: '', thcUnit: value })
            return;
        }
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));

    };

    const convertHtmlToText = (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    };

    const isAllowProductUpload = useMemo(() => {
        return formData?.productName && formData?.brandName && formData?.categoryName ? true : false
    }, [formData])

    useEffect(() => {
        if (!isAllowProductUpload) {
            setErrors({ ...errors, productImages: "Please add product name , brand name and category name first" })
        } else {
            setErrors({ ...errors, productImages: "" })
        }
    }, [isAllowProductUpload])

    const productImageUploadHandler = async (e) => {
        if (e.target.files.length) {
            const file = e.target.files[0];
            const body = new FormData()
            body.append("filePath", file);
            body.append('productName', formData?.productName)
            body.append('categoryName', formData?.categoryName)
            body.append('brandName', formData?.brandName)
            dispatch(uploadProductImage(body)).unwrap().then((res => {
                let newErrors = { ...errors }
                delete newErrors.productImages
                setErrors(newErrors)
                setFormData({ ...formData, productImages: [...formData.productImages, res.fileUrl] })
            })).catch((err) => {
                showError(err?.message, 'error')
            })
        }

    }
    const batchHandler = () => {
        setFormData({ ...formData, purchaseDate: "", uniqueNumber: "", expirationDate: null, batchStatus: "active", trackingSystem: "", purchaseQuantity: "", currentQuantity: "", unitCost: "" })
        setEditBatch({})
        setErrors({})
        setIsBatchAddOpen(!isBatchAddOpen)
    }

    const deleteBatchHandler = (productIds) => {
        dispatch(deleteProductBatch(productIds)).unwrap().then(() => {
            dispatch(closeCustomModalDialog())
            batchHandler()
        }).catch(() => { })
    }

    const terpenoidHandler = () => {
        setIsTerpeneAddOpen(!isTerpeneAddOpen)
    }

    const editBatchHandler = (editData) => {
        setEditBatch(editData)
    }

    const editTerpenoidHandler = (editData) => {
        setEditTerpenoid(editData)
        terpenoidHandler()
    }

    const deleteHandler = () => {
        dispatch(setModalLoader(true))
        dispatch(deleteProduct(productId)).unwrap().then((res) => {
            showError(res?.message, 'success')
            dispatch(closeCustomModalDialog())
            navigate('/products')
        }).catch((err) => {
            dispatch(setModalLoader(false))
            showError(err?.message, 'error')
        })
    }

    useEffect(() => {
        dispatch(fetchCategories({ skip: 0, limit: 1000, userType: 'admin' }))
    }, [])


    useEffect(() => {
        if (!loading && !isEmptyObject(singleProduct?.productDetails)) {
            let payload = Object.keys(initialState)?.reduce((acc, curr) => {
                return acc = { ...acc, [curr]: singleProduct?.productDetails[curr] }
            }, {})
            const { customWeight, thcData = '' } = singleProduct.productDetails
            if (customWeight) {
                const customWeightValue = customWeight.includes('mg') ? customWeight?.split('mg')[0] : customWeight.includes('g') ? customWeight?.split('g')[0] : customWeight
                payload = { ...payload, productWeightValue: customWeightValue, productWeightUnit: customWeight?.includes('mg') ? "mg" : "g" }
            }
            if (thcData) {
                const thcDataValue = thcData.includes('%') ? thcData?.split('%')[0] : thcData.includes('mg') ? thcData?.split('mg')[0] : thcData
                payload = { ...payload, thcValue: thcDataValue, thcUnit: thcData?.includes('%') ? "%" : "mg" }
            }
            setFormData({ ...payload, heading: singleProduct?.productDetails?.seoData?.heading, createdBy: singleProduct?.productDetails?.createdBy ? singleProduct?.productDetails?.createdBy : user?.name })
        }
    }, [singleProduct?.productDetails, loading])


    useEffect(() => {
        if (!isEmptyObject(editBatch)) {
            const { purchaseDate, uniqueNumber, expirationDate, trackingSystem, purchaseQuantity, status, currentQuantity, unitCost } = editBatch || {}
            setFormData({ ...formData, ... { purchaseDate: dayjs(purchaseDate), uniqueNumber, expirationDate: expirationDate ? dayjs(expirationDate) : null, batchStatus: status, trackingSystem, purchaseQuantity, currentQuantity, unitCost } })
        }
    }, [editBatch])

    useEffect(() => {
        if (search) {
            setCurrentTab(search?.split('=')[1])
        }
    }, [search])
    useEffect(() => {
        setEditBatch({})
        setIsBatchAddOpen(false)
        setIsTerpeneAddOpen(false)
        setEditTerpenoid({})
    }, [currentTab])

    const circularProgressIcon = {
        backgroundColor: "#fff",
        borderRadius: 10,
        p: .5,
        position: "absolute"
    };


    useEffect(() => {
        if (user?.name) {
            setFormData({ ...formData, createdBy: formData?.createdBy ? formData?.createdBy : user?.name })
        }
    }, [user?.name])


    return (
        <TabContext value={currentTab}>
            <TabPanel value="products" sx={{ p: 0 }}>
                <form className={classes.form} onSubmit={handleSubmit} style={{ marginTop: 0 }}>
                    <Card sx={{ p: { xs: 2, md: 3 }, mb: 4, borderRadius: 2.5, boxShadow: "none" }}>
                        <Stack direction={{ xs: "column", sm: "row" }} alignItems={{ sm: "center" }} justifyContent="space-between" gap={2} mb={4.5}>
                            <Link to='/products/add'>
                                <Typography variant='h4' fontWeight="semibold" color="secondary">Add Product</Typography>
                            </Link>
                            <Box display="flex" alignItems="center" gap={1.25}>
                                <Typography variant='h5' fontWeight="semibold" color="secondary">Status</Typography>
                                <Stack direction="row" alignItems="center" gap={.75} lineHeight={1} p={.75} borderRadius={2} bgcolor="#F2F3F7">
                                    <MDButton
                                        sx={{ minWidth: "92px", borderRadius: 1.75, minHeight: "28px", p: .25, fontWeight: 500 }}
                                        type='button'
                                        onClick={() => setFormData({ ...formData, status: 'active' })}
                                        variant={formData?.status === 'active' ? 'contained' : 'text'}
                                        color={formData?.status === 'active' ? 'success' : 'dark'}
                                        size="small"
                                    >
                                        Active
                                    </MDButton>
                                    <MDButton
                                        sx={{ minWidth: "92px", borderRadius: 1.75, minHeight: "28px", p: .25, fontWeight: 500 }}
                                        type='button'
                                        onClick={() => setFormData({ ...formData, status: 'inactive' })}
                                        variant={formData?.status !== 'active' ? 'contained' : 'text'}
                                        color={formData?.status !== 'active' ? 'error' : 'dark'}
                                        size="small"
                                    >
                                        Inactive
                                    </MDButton>
                                </Stack>
                            </Box>
                        </Stack>

                        <Typography variant='h5' fontWeight="semibold" mb={2}>Product Images</Typography>
                        <Stack direction="row" flexWrap="wrap" gap={1.25} mb={4.5}>

                            {
                                formData?.productImages?.length > 0 && <>
                                    <ImageDragger images={formData?.productImages} imageDragHandler={(updatedImages => setFormData({ ...formData, productImages: updatedImages }))} removeImageHandler={removeImageHandler} />
                                </>
                            }
                            <label htmlFor='product-image-input'>
                                <Box bgcolor="#F1F1F1" display="flex" alignItems="center" justifyContent="center" flexDirection="column" minWidth="160px" minHeight="160px" borderRadius="20px" p={2} sx={{ cursor: isAllowProductUpload ? 'pointer' : 'not-allowed' }}>
                                    {
                                        isUploading ? <CircularProgress size={32} color='primary' sx={circularProgressIcon} /> : <Typography variant='h5' fontWeight="medium" textAlign="center">+<br />  Add {formData?.productImages?.length > 0 && "More"}</Typography>
                                    }
                                </Box>
                            </label>
                            <TextField
                                id="product-image-input"
                                type='file'
                                name='productImageFile'
                                disabled={!isAllowProductUpload}
                                onChange={productImageUploadHandler}
                                sx={{ display: 'none' }}
                            />
                            {Boolean(errors?.productImages) &&
                                <Box display="inline-flex" width="100%">
                                    <Alert severity="error">{errors?.productImages}</Alert>
                                </Box>
                            }
                        </Stack>

                        <Typography variant='h5' fontWeight="semibold" mb={2}>Product Info</Typography>
                        <Grid container spacing={2.5} mb={4.5}>
                            <Grid item xs={12} md={6} lg={3}>
                                <TextField
                                    label="Product Name*"
                                    className="form-input"
                                    fullWidth
                                    placeholder="Product Name"
                                    name="productName"
                                    value={formData.productName}
                                    onChange={handleChange}
                                    error={Boolean(errors?.productName)}
                                    helperText={errors?.productName}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <TextField
                                    label="Unit Price ($)"
                                    className="form-input"
                                    fullWidth
                                    placeholder='Unit Price ($)'
                                    name="unitPrice"
                                    value={formData.unitPrice || ""}
                                    onChange={handleChange}
                                    type='number'
                                    error={Boolean(errors?.unitPrice)}
                                    helperText={errors?.unitPrice}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <TextField
                                    label="Sale Price ($)"
                                    className="form-input"
                                    fullWidth
                                    placeholder='Sale Price ($)'
                                    name="salePrice"
                                    value={formData.salePrice || ""}
                                    onChange={handleChange}
                                    type='number'
                                    error={Boolean(errors?.salePrice)}
                                    helperText={errors?.salePrice}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <FormControl fullWidth>
                                    <InputLabel>Category Name</InputLabel>
                                    <Select
                                        className="form-select"
                                        error={Boolean(errors?.categoryName)}
                                        onChange={handleChange} label="Category Name" name="categoryName" value={formData.categoryName}>
                                        {
                                            categoriesData?.categories?.map((category => {
                                                return <MenuItem key={category.categoryName} value={category.categoryName}>{category?.categoryName}</MenuItem>
                                            }))
                                        }

                                    </Select>
                                    {Boolean(errors?.categoryName) && <FormHelperText error>{errors?.categoryName}</FormHelperText>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <FormControl fullWidth>
                                    <InputLabel>Brand Name</InputLabel>
                                    <Select
                                        className="form-select"
                                        error={Boolean(errors?.brandName)}
                                        onChange={handleChange} label="Brand Name" name="brandName" value={formData.brandName}>
                                        {
                                            brandsData?.brands?.map((brandsData => {
                                                return <MenuItem key={brandsData.brandName} value={brandsData.brandName}>{brandsData?.brandName}</MenuItem>
                                            }))
                                        }
                                    </Select>
                                    {Boolean(errors?.brandName) && <FormHelperText error>{errors?.brandName}</FormHelperText>}

                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <TextField
                                    label="Created By"
                                    className="form-input created-by"
                                    name="createdBy"
                                    fullWidth
                                    disabled
                                    value={formData?.createdBy || ""}
                                    onChange={handleChange}
                                    error={Boolean(errors?.createdBy)}
                                    helperText={errors?.createdBy}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AccountCircle fontSize='medium' color='secondary' />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <TextField
                                    fullWidth
                                    label="Tags"
                                    name="tags"
                                    className="form-input"
                                    value={formData?.tags?.join(',') || ""}
                                    onChange={handleChange}
                                    error={Boolean(errors?.tags)}
                                    helperText={errors?.tags}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <FormControl fullWidth>
                                    <InputLabel>Strain Type</InputLabel>
                                    <Select
                                        className="form-select"
                                        error={Boolean(errors?.strainType)}
                                        onChange={handleChange} label="Strain Type" name="strainType" value={formData.strainType}>
                                        {
                                            infoEffectData?.map((infoEffect => {
                                                return <MenuItem key={infoEffect.strainType} value={infoEffect.strainType}>{infoEffect?.strainType}</MenuItem>
                                            }))
                                        }
                                    </Select>
                                    {Boolean(errors?.strainType) && <FormHelperText error>{errors?.strainType}</FormHelperText>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <FormControl fullWidth>
                                    <InputLabel>Product Weight (unit)</InputLabel>
                                    <Select
                                        className="form-select"
                                        error={Boolean(errors?.productWeightUnit)}
                                        value={formData?.productWeightUnit || ""} onChange={handleChange} label="Product Weight (unit)" name="productWeightUnit" >
                                        <MenuItem value='g'>Gram (g)</MenuItem>
                                        <MenuItem value='mg'>MilliGrams (mg)</MenuItem>
                                    </Select>
                                    {Boolean(errors?.productWeightUnit) && <FormHelperText error>{errors?.productWeightUnit}</FormHelperText>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <FormControl fullWidth>
                                    <TextField
                                        className="form-input"
                                        label="Product Weight (value)"
                                        name="productWeightValue"
                                        value={formData?.productWeightValue || ""}
                                        onChange={handleChange}
                                        type='number'
                                        error={Boolean(errors?.productWeightValue)}
                                        helperText={errors?.productWeightValue}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <FormControl fullWidth>
                                    <InputLabel>THC (unit)</InputLabel>
                                    <Select
                                        className="form-select"
                                        error={Boolean(errors?.thcUnit)}
                                        value={formData?.thcUnit || ""} onChange={handleChange} label="THC (unit)" name="thcUnit" >
                                        <MenuItem value="%">Percentage (%)</MenuItem>
                                        <MenuItem value='mg'>MilliGrams (mg)</MenuItem>
                                    </Select>
                                    {Boolean(errors?.thcUnit) && <FormHelperText error>{errors?.thcUnit}</FormHelperText>}

                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <TextField
                                    className="form-input"
                                    fullWidth
                                    label="THC (value)"
                                    name="thcValue"
                                    value={formData.thcValue || ""}
                                    onChange={handleChange}
                                    type='number'
                                    error={Boolean(errors?.thcValue)}
                                    helperText={errors?.thcValue}
                                />
                            </Grid>

                            <Grid item xs={12} md={6} lg={3}>
                                <TextField
                                    className="form-input"
                                    fullWidth
                                    label="SKU"
                                    name="sku"
                                    value={formData.sku}
                                    onChange={handleChange}
                                    error={Boolean(errors?.sku)}
                                    helperText={errors?.sku}
                                />
                            </Grid>
                        </Grid>
                        <Typography variant='h5' fontWeight="semibold" mb={2}>Other Info</Typography>
                        <Grid container spacing={2.5}>

                            <Grid item xs={12} md={6} lg={12}>
                                <FormGroup>
                                    <FormControlLabel control={<Switch checked={formData?.ingredientStatus} onChange={(e) => setFormData({ ...formData, ingredientStatus: e.target.checked })} />} label="Show Ingredients" />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12} md={6} lg={12}>
                                <CommonQuillEditor
                                    value={formData?.ingredients || ""}
                                    onChange={(value) => setFormData({ ...formData, ingredients: value })}
                                />
                                {Boolean(errors?.ingredients) && <FormHelperText error>{errors?.ingredients}</FormHelperText>}
                            </Grid>

                            {formData.categoryName &&
                                <Grid item xs={12} md={6} lg={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Switch checked={formData?.instructionStatus} onChange={(e) => setFormData({ ...formData, instructionStatus: e.target.checked })} />} label="Show Instructions" />
                                    </FormGroup>

                                </Grid>
                            }
                            {formData.categoryName &&
                                <Grid item xs={12}>
                                    <TextField
                                        label="Instructions"
                                        value={convertHtmlToText(formData.instructions)}
                                        fullWidth
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        name='metaDescription'
                                        className="form-input"
                                        disabled
                                    />
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <Typography variant="body2" fontWeight="medium" color={grey[600]} mb={1}>Product Description</Typography>
                                <CommonQuillEditor
                                    value={formData?.productDescription || ""}
                                    onChange={(value) => setFormData({ ...formData, productDescription: value })}
                                />
                                {Boolean(errors?.productDescription) && <FormHelperText error>{errors?.productDescription}</FormHelperText>}
                            </Grid>
                        </Grid>

                        <Stack direction={{ xs: "column", sm: "row" }} spacing={1.25} mt={4} mb={2}>
                            <MDButton
                                sx={{ minWidth: "140px" }}
                                type='submit'
                                variant='contained'
                                color='primary'
                                loading={isUpdating}
                                disabled={isUpdating}
                            >
                                {productId ? 'Update' : 'Save'}
                            </MDButton>

                            {
                                productId && <MDButton
                                    sx={{ minWidth: "140px", backgroundColor: "#F1F1F1", color: "#FD4438" }}
                                    type='button'
                                    variant='contained'
                                    color='error'
                                    onClick={() => {
                                        dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: productId }, modal: 'DELETE_MODAL' }))
                                    }}
                                >
                                    Delete
                                </MDButton>
                            }

                            <MDButton
                                sx={{ minWidth: "140px" }}
                                type='button'
                                variant='outlined'
                                color='dark'
                                onClick={() => navigate('/products')}
                            >
                                Cancel
                            </MDButton>
                        </Stack>
                    </Card>
                </form>
            </TabPanel>

            <TabPanel value='inventory' sx={{ p: 0 }}>
                <InventroyTable productId={productId} batchHandler={batchHandler} totalProduct={singleProduct?.productDetails
                    ?.totalQuantity} />
            </TabPanel>

            <TabPanel value="batches" sx={{ p: 0 }}>
                {
                    isBatchAddOpen ? <>
                        <Card sx={{ p: 3, mb: 4, borderRadius: 2.5, boxShadow: "none" }}>
                            <Typography variant='h4' fontWeight="semibold" color="secondary" mb={3.5}>{editBatch?.productBatchId ? 'Update' : "Add"} Batch</Typography>

                            <form className={classes.form} onSubmit={handleBatchProductSubmit}>
                                <Grid container columnSpacing={3} rowSpacing={5} mb={4.5}>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <FormControl fullWidth>
                                            <DatePicker
                                                label="Purchase Date"
                                                error={Boolean(errors?.purchaseDate)}
                                                name="purchaseDate"
                                                onChange={(value) => {
                                                    setFormData({ ...formData, purchaseDate: value })
                                                }}
                                                value={formData?.purchaseDate || null}
                                                slots={{
                                                    openPickerIcon: CalendarIcon
                                                }}
                                                slotProps={{
                                                    openPickerButton: {
                                                        color: 'secondary',
                                                    },
                                                }}
                                                format='MM/DD/YYYY'
                                            />
                                            {Boolean(errors?.purchaseDate) && <FormHelperText error>{errors?.purchaseDate}</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <FormControl fullWidth>
                                            <DatePicker
                                                label="Expiration Date"
                                                name="expirationDate"
                                                onChange={(value) => {
                                                    setFormData({ ...formData, expirationDate: value })
                                                }}
                                                value={formData?.expirationDate || null}
                                                slots={{
                                                    openPickerIcon: CalendarIcon
                                                }}
                                                slotProps={{
                                                    openPickerButton: {
                                                        color: 'secondary',
                                                    },
                                                }}
                                                minDate={formData?.purchaseDate ? formData?.purchaseDate : ''}
                                                format='MM/DD/YYYY'
                                            />
                                            {Boolean(errors?.expirationDate) && <FormHelperText error>{errors?.expirationDate}</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <TextField className="form-input" value={formData?.uniqueNumber || ""} label="Unique Number" onChange={handleChange} name="uniqueNumber" fullWidth
                                            error={Boolean(errors?.uniqueNumber)}
                                            helperText={errors?.uniqueNumber}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <FormControl fullWidth>
                                            <InputLabel>Status</InputLabel>
                                            <Select error={Boolean(errors?.batchStatus)} label="Status" className="form-select" name="batchStatus" value={formData.batchStatus?.toLowerCase() || ""} onChange={handleChange}>
                                                <MenuItem value="active">Active</MenuItem>
                                                <MenuItem value="inactive">Inactive</MenuItem>
                                            </Select>
                                            {Boolean(errors?.batchStatus) && <FormHelperText error>{errors?.batchStatus}</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <TextField className="form-input" type='text' value={formData?.purchaseQuantity || ""} label="Purchase Quantity " onChange={handleChange} name="purchaseQuantity" fullWidth
                                            error={Boolean(errors?.purchaseQuantity)}
                                            helperText={errors?.purchaseQuantity}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <TextField className="form-input" type='text' label="Current Quantity" value={formData?.currentQuantity || ""} onChange={handleChange} name="currentQuantity" fullWidth
                                            error={Boolean(errors?.currentQuantity)}
                                            helperText={errors?.currentQuantity}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <TextField className="form-input" type='number' label="Unit Cost" value={formData?.unitCost || ""} name="unitCost" fullWidth onChange={handleChange}
                                            error={Boolean(errors?.unitCost)}
                                            helperText={errors?.unitCost}
                                        />
                                    </Grid>
                                </Grid>

                                <Stack direction="row" alignItems="center" spacing={1.25} mt={7} mb={2}>
                                    <MDButton
                                        sx={{ minWidth: "140px" }}
                                        type='submit'
                                        variant='contained'
                                        color='primary'
                                        loading={loading || isUpdating}
                                        disabled={isUpdating || loading}
                                    >
                                        {
                                            editBatch?.productBatchId ? "Update" : "Save"
                                        }
                                    </MDButton>
                                    {
                                        editBatch?.productBatchId && <MDButton
                                            sx={{ minWidth: "140px", backgroundColor: "#F1F1F1", color: "#FD4438" }}
                                            type='button'
                                            variant='contained'
                                            color='error'
                                            onClick={() => {
                                                dispatch(openCustomModalDialog({ modalData: { deleteHandler: deleteBatchHandler, deleteId: { productId: productId, productBatchId: editBatch?.productBatchId } }, modal: 'DELETE_MODAL' }))
                                            }}
                                        >
                                            Delete
                                        </MDButton>
                                    }

                                    <MDButton
                                        sx={{ minWidth: "140px" }}
                                        type='button'
                                        variant='outlined'
                                        color='dark'
                                        onClick={batchHandler}
                                    >
                                        Cancel
                                    </MDButton>
                                </Stack>
                            </form>
                        </Card>
                    </> : <BatchTable productId={productId} batchHandler={batchHandler} editBatchHandler={editBatchHandler} />
                }
            </TabPanel>

            <TabPanel value='terpenoids' sx={{ p: 0 }}>
                {
                    isTerpeneAddOpen ? <AddProductTerpenoid productId={productId} terpenoidHandler={terpenoidHandler} editTerpenoidHandler={editTerpenoidHandler} editTerpenoid={editTerpenoid} resetEditTerpenoid={() => {
                        setEditTerpenoid({})
                        setIsTerpeneAddOpen(false)
                    }} /> : <ProductTerpenoids terpenoidHandler={terpenoidHandler} editTerpenoidHandler={editTerpenoidHandler} />
                }
            </TabPanel>
            <TabPanel value='activity_logs' sx={{ p: 0 }}>
                <ProductActivityLogs />
            </TabPanel>
        </TabContext>
    );
};

export default AddProductForm;