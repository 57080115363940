import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import NoDataFound from 'common/component/NoDataFound';

const filterParams = { values: [] };

export const sortAndFilter = (allOfTheData, sortModel, filterModel) => {
  return sortData(sortModel, filterData(filterModel, allOfTheData));
};

const sortData = (sortModel, data) => {
  const sortPresent = sortModel && sortModel.length > 0;
  if (!sortPresent) {
    return data;
  }
  // do an in memory sort of the data, across all the fields
  const resultOfSort = data.slice();
  resultOfSort.sort(function (a, b) {
    for (let k = 0; k < sortModel.length; k++) {
      const sortColModel = sortModel[k];
      const valueA = a[sortColModel.colId];
      const valueB = b[sortColModel.colId];
      // this filter didn't find a difference, move onto the next one
      if (valueA == valueB) {
        continue;
      }
      const sortDirection = sortColModel.sort === 'asc' ? 1 : -1;
      if (valueA > valueB) {
        return sortDirection;
      } else {
        return sortDirection * -1;
      }
    }
    // no filters found a difference
    return 0;
  });
  return resultOfSort;
};

const filterData = (filterModel, data) => {
  const filterPresent = filterModel && Object.keys(filterModel).length > 0;
  if (!filterPresent) {
    return data;
  }
  const resultOfFilter = [];
  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    if (filterModel.age) {
      const age = item.age;
      const allowedAge = parseInt(filterModel.age.filter);
        if (filterModel.age.type == 'equals') {
        if (age !== allowedAge) {
          continue;
        }
      } else if (filterModel.age.type == 'lessThan') {
        if (age >= allowedAge) {
          continue;
        }
      } else {
        if (age <= allowedAge) {
          continue;
        }
      }
    }
    if (filterModel.year) {
      if (filterModel.year.values.indexOf(item.year.toString()) < 0) {
        // year didn't match, so skip this record
        continue;
      }
    }
    if (filterModel.country) {
      if (filterModel.country.values.indexOf(item.country) < 0) {
        continue;
      }
    }
    resultOfFilter.push(item);
  }
  return resultOfFilter;
};

export const AgGridTable = ({ col = [], getRows = () => { }, pageRef = null, mainGridApiRef = null, onRowClicked = () => { }, gridOptions = {}, isLoading = false, style = {}, onSelectionChanged = () => { }, maxLimit = 20, rowClassRules = {} }) => {
  const containerStyle = useMemo(() => ({ width: '100%', height: '70vh' }), []);
  const gridStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
  const gridApi = useRef()
  const gridRef = useRef();
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 150,
      floatingFilter: false,

    };
  }, []);
  const getRowId = useCallback(function (params) {
    return params.data.id;
  }, []);


  const onGridReady = useCallback(async (params) => {
    gridApi.current = params
    if (mainGridApiRef) {
      mainGridApiRef.current = params.api;
    }
    const dataSource = {
      rowCount: undefined,
      getRows
    }
    params.api.setGridOption('datasource', dataSource);
  }, []);

  useEffect(() => {
    if (isLoading && gridApi.current?.api) {
      gridApi.current?.api.showLoadingOverlay()
    }

  }, [isLoading, gridApi.current])

  return (
    <div style={{ ...containerStyle, ...style }}>
      <div
        style={gridStyle}
        className={
          "ag-theme-quartz"
        }
      >
        <AgGridReact
          className='ag-grid--table'
          ref={gridRef}
          columnDefs={col}
          defaultColDef={{
            ...defaultColDef,
            lockPinned: true,
            lockPosition: true,
            resizable: false
          }}
          rowSelection={'multiple'}
          rowModelType={'infinite'}
          cacheBlockSize={maxLimit}
          getRowId={getRowId}
          onGridReady={onGridReady}
          onSortChanged={() => {
            pageRef.current = 0
          }}
          rowBuffer={0}
          cacheOverflowSize={2}
          maxConcurrentDatasourceRequests={1}
          infiniteInitialRowCount={1000}
          maxBlocksInCache={10}
          overlayLoadingTemplate={'<span class="loader"></span>'}
          overlayNoRowsTemplate='<span style="color: #9A9AAF;">No Data Found</span>'
          suppressRowClickSelection={true}
          noRowsOverlayComponent={() => <NoDataFound />}
          gridOptions={{
            onCellClicked: (params) => {
              console.log("params", params)
            },
            noRowsOverlayComponent: 'customOverlayComponent',
            headerHeight: 38,
            rowHeight: 64,
            ...gridOptions,
          }}
          onCellClicked={onRowClicked}
          onCellValueChanged={() => {
          }}
          rowClassRules={rowClassRules}
          onSelectionChanged={
            onSelectionChanged

          }
        />
      </div>
    </div>
  );
};