import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Grid, IconButton, InputAdornment, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { AgGridTable } from 'components/tables/AgGridTable'
import { Link, useNavigate } from 'react-router-dom'
import MDButton from 'components/MDButton'
import { closeCustomModalDialog, openCustomModalDialog, setModalLoader } from '../../../redux/slices/modalSlice'
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useDebounce } from 'hooks/useDebounce'
import EditIcon from 'common/customIcons/edit'
import DeleteIcon from 'common/customIcons/delete'
import { openSnackbar } from '../../../redux/slices/common'
import { CloseRounded } from '@mui/icons-material'
import StatusText from 'common/component/StatusText'
import SearchIcon from 'common/customIcons/search'
import { deleteCannabinoid, getCannabinoidsList } from '../../../redux/slices/cannabinoids'
import GridViewIcon from '@mui/icons-material/GridView';
import ViewListIcon from '@mui/icons-material/ViewList';
import Cannabinoids from '../../cannabinoids/index'

export default function CannabinoidsList() {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const pageRef = useRef(0)
  const searchTermRef = useRef("")
  const mainGridApiRef = useRef('')
  const gridRefApi = useRef(null)
  const [searchTerm, setSearchTerm] = useState("")
  const { isLoading: loading = false, cannabinoidList = {} } = useSelector(state => state.cannabinoids)
  const [cannabinoidListView, setCannbinoidListView] = useState('list')

  const deleteHandler = (cannabinoidId) => {
    dispatch(setModalLoader(true))
    dispatch(deleteCannabinoid(cannabinoidId)).unwrap().then((res) => {
      showResponseMessage(res?.message, 'success')
      dispatch(closeCustomModalDialog())
      pageRef.current = 0
      const dataSource = {
        getRows
      }
      mainGridApiRef.current.ensureIndexVisible(0, null);
      mainGridApiRef.current.setGridOption('datasource', dataSource);
    }).catch((err) => {
      dispatch(setModalLoader(false))
      showResponseMessage(err?.message, 'error')
    })
  }

  const showResponseMessage = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
    }
  }

  const [columnDefs, setColumnDefs] = useState([

    {
      headerName: 'Cannabinoid Name',
      field: 'cannabinoidName',
      suppressMenu: false,
      minWidth: 100,
      flex: 1,
      cellStyle: { whiteSpace: "normal", lineHeight: "1.5" }
    },
    {
      headerName: 'Cannabinoid URL',
      field: 'canonical',
      suppressMenu: false,
      minWidth: 200,
      flex: 2,
      cellRenderer: (props) => {
        return props?.value ? <div className='text-truncate'>
          <Link target='_blank' to={props?.value} style={{ color: "#181d1f" }}>{props?.value}</Link>
        </div> : '-'
      }
    },
    {
      headerName: 'Meta Title',
      field: 'metaTitle',
      suppressMenu: false,
      minWidth: 120,
      flex: 1,
      cellRenderer: (props) => {
        return props?.value ? <span className='text-truncate'>{props?.value?.replace(/<\/?[^>]+(>|$)/g, "")}</span> : "-"
      }
    },
    {
      headerName: 'Meta Description',
      field: 'metaDescription',
      suppressMenu: false,
      minWidth: 120,
      flex: 1.5,
      cellRenderer: (props) => {
        return props?.value ? <span className='text-truncate'>{props?.value?.replace(/<\/?[^>]+(>|$)/g, "")}</span> : "-"
      }
    },
    {
      headerName: 'Status',
      field: 'status',
      suppressMenu: false,
      cellRenderer: (props) => {
        if (!props?.data?._id) return;
        return <StatusText status={props?.data?.status} />
      },
      flex: 1,
      minWidth: 80
    },
    {
      headerName: 'Actions',
      cellRenderer: (props) => {
        if (!props?.data?._id) return;
        return <Box display="inline-flex" alignItems="center">
          <IconButton onClick={(e) => {
            e.stopPropagation()
            onEditClicked(props)
          }} color='primary' size='small' variant="primary">
            <EditIcon width='16px' height='16px' />
          </IconButton>
          <IconButton onClick={(e) => {
            e.stopPropagation()
            dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: props.data?._id, deleteType: 'promotion' }, modal: 'DELETE_MODAL' }))
          }} color='error' size='small' variant="primary" sx={{ marginLeft: 1 }}>
            <DeleteIcon width='16px' height='16px' />
          </IconButton>
        </Box>
      },
      cellStyle: { textAlign: 'right', justifyContent: 'end' },
      headerClass: 'right-aligned-cell-header',
      minWidth: 100,
      maxWidth: 100,
      flex: 1,
      pinned: 'right'
    },

  ]);

  function getSortInfo(data) {
    const result = {};
    data.forEach(item => {
      if (item?.colId === 'memberType') {
        result[item.colId] = item.sort === "asc" ? 'AdultUse' : "MedicinalUser";
      } else {
        result[item.colId] = item.sort === "asc";
      }

    });

    return result;
  }

  const getRows = async (params) => {
    gridRefApi.current = params;
    let filter = {}
    if (params?.sortModel.length) {
      filter = { ...getSortInfo(params?.sortModel) }
    }
    dispatch(getCannabinoidsList({ skip: pageRef.current, limit: 20, searchTerm: searchTermRef.current, ...filter })).unwrap().then(res => {
      const { cannabinoids = [], total } = res
      const cannabinoidsArray = cannabinoids?.map((item => {
        return { ...item, status: item?.status ? "Active" : "Inactive" }
      }))
      params.successCallback([...cannabinoidsArray], total);
      pageRef.current = pageRef.current + 20
    }).catch(() => {
      params.successCallback([], 0);
    })
  }

  const searchFn = () => {
    const dataSource = {
      getRows
    }
    mainGridApiRef.current.ensureIndexVisible(0, null);
    mainGridApiRef.current.setGridOption('datasource', dataSource);
  }

  const onEditClicked = (row) => {
    navigate(`/cannabinoids/${row?.data?.cannabinoidSlug}`)
  }

  useEffect(() => {
    if (mainGridApiRef?.current) {
      if (loading) {
        mainGridApiRef.current.showLoadingOverlay()
      } else if (cannabinoidList?.total == 0) {
        mainGridApiRef.current.showNoRowsOverlay()
      }
      else {
        mainGridApiRef.current.hideOverlay()
      }
    }

  }, [cannabinoidList, loading])

  const handleSearch = useDebounce((term) => {
    pageRef.current = 0
    searchFn()
  }, 500);

  const cannabinoidViewHandler = (viewType = '') => {
    setCannbinoidListView(viewType)
    if (viewType === 'list') {
      pageRef.current = 0
      const dataSource = {
        getRows
      }
      mainGridApiRef.current.ensureIndexVisible(0, null);
      mainGridApiRef.current.setGridOption('datasource', dataSource);
    }
  }

  const searchHandler = (searchValue) => {
    setSearchTerm(searchValue);
    searchTermRef.current = searchValue
    handleSearch(searchValue);
  };
  
  return (
    <Box p={{ xs: 2, sm: 3, lg: 4 }}>
      {
        cannabinoidListView === 'list' && <>
          <Grid container alignItems="center" rowSpacing={2} columnSpacing={2} mb={3}>
            <Grid item xs={12} sm={4}>
              <Stack direction='row' gap={1} alignItems='center'>
                <Typography variant='h4'>Cannabinoids</Typography>
                <Stack direction='row' gap={1} className='grid-list-view' ml={2}>
                  <Tooltip title='Grid View'>
                    <div className='gl-view'>
                      <GridViewIcon onClick={() => {
                        setSearchTerm('')
                        searchTermRef.current = ''
                        cannabinoidViewHandler('grid')
                      }} color={cannabinoidListView === 'grid' ? 'primary' : ''} />
                    </div>
                  </Tooltip>
                  <Tooltip title='list View'>
                    <div className='gl-view'>
                      <ViewListIcon onClick={() => {
                        setSearchTerm('')
                        searchTermRef.current = ''
                        cannabinoidViewHandler('list')
                      }} color={cannabinoidListView === 'list' ? 'primary' : ''} />
                    </div>
                  </Tooltip>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Stack direction="row" justifyContent={{ xs: "start", sm: "end" }} flexWrap="wrap" alignItems="stretch" gap={2}>
                <TextField className="custom-search-field" placeholder="Search" label="" value={searchTerm} onChange={(e) => searchHandler(e.target.value)}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">
                      <IconButton size='small'>
                        <SearchIcon width='18px' height='18px' />
                      </IconButton>
                    </InputAdornment>,
                    endAdornment: searchTerm?.length > 0 ? <InputAdornment>
                      <IconButton onClick={() => searchHandler('')} size='small'>
                        <CloseRounded />
                      </IconButton>
                    </InputAdornment> : null
                  }}
                />
                <MDButton
                  startIcon={<AddRoundedIcon fontSize='large' />}
                  variant="contained" color="primary"
                  onClick={() => navigate('/cannabinoids/add')} >
                  Add Cannabinoid
                </MDButton>
              </Stack>
            </Grid>
          </Grid>
          <AgGridTable col={columnDefs} getRows={getRows} mainGridApiRef={mainGridApiRef} pageRef={pageRef} isLoading={loading} style={{ height: "70vh" }} />
        </>
      }
      {
        cannabinoidListView === 'grid' && <Cannabinoids isGridView cannabinoidViewHandler={cannabinoidViewHandler} cannabinoidListView={cannabinoidListView} />
      }

    </Box>
  )
}
