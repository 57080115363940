import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'
import { createProductBatch } from '../../../redux/slices/products';
import { batchValidation } from '../../../validations/batch';
import { openSnackbar } from '../../../redux/slices/common';
import { DatePicker } from '@mui/x-date-pickers';
import { Card, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import MDButton from 'components/MDButton';
import { useDispatch, useSelector } from 'react-redux';
import CalendarIcon from 'common/customIcons/calendar';
import { useParams } from 'react-router-dom';
import { isNumber } from 'utilities/common';

export default function AddBatch({ batchHandler,editBatch={} }) {
    const [errors, setErrors] = useState({})
    const {user} = useSelector(state=>state.auth)
    const {loading=false ,isUpdating=false} = useSelector(state=>state.products)

    const {productId} = useParams()
    const dispatch = useDispatch()

    const [formData, setFormData] = useState({
        productId: '', // You might generate this automatically
        productName: '',
        productImages: [],
        status: 'active',
        isSalePrice: false,
        unitPrice: 0,
        salePrice: 0,
        categoryName: '',
        brandName: '',
        createdBy: user?.name || "",
        tags: [],
        strainSlug: '',
        strainType: '',
        infoEffects: [],
        categoryId: '',
        sku: '',
        brandDescription: '',
        productDescription: '',
        purchaseDate: undefined,
        uniqueNumber: '',
        expirationDate: null,
        trackingSystem: '',
        purchaseQuantity: '',
        currentQuantity: '',
        unitCost: '',
        batchStatus: 'active',

    });

       const handleChange = (e) => {
      
        const { name, value, type, checked } = e.target;
        if ((name === 'currentQuantity' && value && !isNumber(value)) || (name === 'purchaseQuantity' && value && !isNumber(value))) return;
        if ((name === 'thcValue' && Math.sign(value) == -1) || (name === 'productWeightValue' && Math.sign(value) == -1) || (name === 'salePrice' && Math.sign(value) == -1) || (name === 'unitPrice' && Math.sign(value) == -1) || (name === 'unitCost' && Math.sign(value) == -1)) return;

        if (name === 'tags') {
            if (value.includes(',')) {
                const separatedArray = value.split(',');
                setFormData({ ...formData, tags: separatedArray })
            } else {
                setFormData({ ...formData, tags: [value] })
            }
            return
        }
       
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));

    };

    const handleBatchProductSubmit = (e) => {
        e.preventDefault();
        const { purchaseDate, uniqueNumber, expirationDate, batchStatus, trackingSystem, purchaseQuantity, currentQuantity, unitCost } = formData;

        const { isValid = false, errors = {} } = batchValidation({ purchaseDate, uniqueNumber, expirationDate, batchStatus, trackingSystem, purchaseQuantity, currentQuantity, unitCost })

        if (!isValid) {
            setErrors(errors)
            return;
        }
        // Prepare payload
        const payload = {
            purchaseDate: dayjs(purchaseDate).valueOf(),
            uniqueNumber,
            expirationDate: dayjs(expirationDate).valueOf(),
            status: batchStatus,
            trackingSystem,
            purchaseQuantity: parseInt(purchaseQuantity),
            currentQuantity: parseInt(currentQuantity),
            unitCost: parseInt(unitCost),
            updatedBy:user?.name
        };
        dispatch(createProductBatch({ ...payload, productId }))
            .unwrap()
            .then((res) => {
                showError(res?.message, 'success')
                batchHandler();
            }).catch((err) => {
                showError(err?.message, 'error')
            })
    };

    const showError = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
    }

    useEffect(()=>{
        setFormData({...formData,createdBy:user?.name})
    },[user])

    return (
        <>
            {
                <>
                    <Card sx={{ p: 3, mb: 4, borderRadius: 2.5, boxShadow: "none" }}>
                        <Typography variant='h4' fontWeight="semibold" color="secondary" mb={3.5}>{ "Add"} Batch</Typography>

                        <form  onSubmit={handleBatchProductSubmit}>
                            <Grid container columnSpacing={3} rowSpacing={5} mb={4.5}>
                                <Grid item xs={12} md={6} lg={3}>
                                                               <FormControl fullWidth>
                                        <DatePicker
                                            label="Purchase Date*"
                                            error={Boolean(errors?.purchaseDate)}
                                            name="purchaseDate"
                                            onChange={(value) => {
                                                setFormData({ ...formData, purchaseDate: value })
                                            }}
                                            value={formData?.purchaseDate || null}
                                            slots={{
                                                openPickerIcon: CalendarIcon
                                            }}
                                            slotProps={{
                                                openPickerButton: {
                                                    color: 'secondary',
                                                },
                                            }}
                                            format='MM/DD/YYYY'
                                        />
                                        {Boolean(errors?.purchaseDate) && <FormHelperText error>{errors?.purchaseDate}</FormHelperText>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <FormControl fullWidth>
                                        <DatePicker
                                            label="Expiration Date"
                                            error={Boolean(errors?.expirationDate)}
                                            name="expirationDate"
                                            onChange={(value) => setFormData({ ...formData, expirationDate: value })}
                                            value={formData?.expirationDate || null}
                                            slots={{
                                                openPickerIcon: CalendarIcon
                                            }}
                                            slotProps={{
                                                openPickerButton: {
                                                    color: 'secondary',
                                                },
                                            }}
                                            minDate={formData?.purchaseDate ? formData?.purchaseDate : ''}
                                            format='MM/DD/YYYY'
                                            disablePast
                                        />
                                        {Boolean(errors?.expirationDate) && <FormHelperText error>{errors?.expirationDate}</FormHelperText>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <TextField className="form-input" value={formData?.uniqueNumber || ""} label="Unique Number" onChange={handleChange} name="uniqueNumber" fullWidth
                                        error={Boolean(errors?.uniqueNumber)}
                                        helperText={errors?.uniqueNumber}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <FormControl fullWidth>
                                        <InputLabel>Status</InputLabel>
                                        <Select error={Boolean(errors?.batchStatus)} label="Status" className="form-select" name="batchStatus" value={formData.batchStatus?.toLowerCase() || ""} onChange={handleChange}>
                                            <MenuItem value="active">Active</MenuItem>
                                            <MenuItem value="inactive">Inactive</MenuItem>
                                        </Select>
                                        {Boolean(errors?.batchStatus) && <FormHelperText error>{errors?.batchStatus}</FormHelperText>}
                                    </FormControl>
                                </Grid>
                                                    <Grid item xs={12} md={6} lg={3}>
                                    <TextField className="form-input" type='number' value={formData?.purchaseQuantity || ""} label="Purchase Quantity " onChange={handleChange} name="purchaseQuantity" fullWidth
                                        error={Boolean(errors?.purchaseQuantity)}
                                        helperText={errors?.purchaseQuantity}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <TextField className="form-input" type='number' label="Current Quantity" value={formData?.currentQuantity || ""} onChange={handleChange} name="currentQuantity" fullWidth
                                        error={Boolean(errors?.currentQuantity)}
                                        helperText={errors?.currentQuantity}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <TextField className="form-input" type='number' label="Unit Cost" value={formData?.unitCost || ""} name="unitCost" fullWidth onChange={handleChange}
                                        error={Boolean(errors?.unitCost)}
                                        helperText={errors?.unitCost}
                                    />
                                </Grid>
                            </Grid>

                            <Stack direction="row" alignItems="center" spacing={1.25} mt={7} mb={2}>
                                <MDButton
                                    sx={{ minWidth: "140px" }}
                                    type='submit'
                                    variant='contained'
                                    color='primary'
                                    loading={loading}
                                    disabled={isUpdating || loading}
                                >
                                    Save
                                </MDButton>

                                <MDButton
                                    sx={{ minWidth: "140px" }}
                                    type='button'
                                    variant='outlined'
                                    color='dark'
                                    onClick={batchHandler}
                                >
                                    Cancel
                                </MDButton>
                            </Stack>
                        </form>
                    </Card>
                </>
            }
        </>
    )
}