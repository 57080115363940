/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";



function StaticsOverview({ color, title, count, percentage, icon, iconShadow = '' }) {
  return (
    <MDBox px={1} py={{xs: 1, md: 0.5, lg: 0.25}} display="flex" alignItems="start">
        <MDBox
          bgColor={color}
          color={color === "light" ? "dark" : "white"}
          coloredShadow={iconShadow}
          borderRadius="10px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="36px"
          height="36px"
          sx={{flex: "none", mt: .25}}
        >
          <Icon fontSize="small" color="inherit">
            {icon}
          </Icon>
        </MDBox>
        <MDBox textAlign="left" ml={1.5}>
          <MDTypography variant="body2" fontSize="13px" lineHeight={1.25} color="secondary" my={.25}>
            {title}
          </MDTypography>
          <MDTypography variant="h6" fontSize="14px" lineHeight={1.25} my={.25}>{count}</MDTypography>
        </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of StaticsOverview
StaticsOverview.defaultProps = {
  color: "info",
  percentage: {
    color: "success",
    text: "",
    label: "",
  },
};

// Typechecking props for the StaticsOverview
StaticsOverview.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  icon: PropTypes.node.isRequired,
};

export default StaticsOverview;
