import CommonLayout from "layouts/DashboardLayout";
import Home from "./Home";

function HyperDriveHomePage() {

  return (
    <CommonLayout>
      <Home />
    </CommonLayout>
  );
}

export default HyperDriveHomePage;
