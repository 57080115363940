import { RemoveCircle } from '@mui/icons-material';
import { Box, Button, Card, Grid, IconButton, Stack, Typography } from '@mui/material'
import { grey } from '@mui/material/colors';
import CommonQuillEditor from 'common/editor';
import CommonLayout from 'layouts/DashboardLayout'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { openSnackbar } from '../../redux/slices/common';
import faq, { updateMainFAQ } from '../../redux/slices/faq';
import MDButton from 'components/MDButton';
import { useDispatch } from 'react-redux';

export default function MainFAQForm() {
    const [formData, setFormData] = useState({ faq: [] });
    const { faqs = [], loading } = useSelector(state => state.mainFAQ)
    const dispatch = useDispatch()

    const handleChangeFAQ = (value, fieldType, idx) => {
        let FAQArray = [...formData?.faq]
        let faqItem = FAQArray?.find(((faq, _idx) => _idx === idx))
        if (faqItem) {
            const faqItemIdx = FAQArray.indexOf(faqItem)
            faqItem = {
                ...faqItem,
                [fieldType]: value
            }
            FAQArray[faqItemIdx] = faqItem;
            setFormData({ ...formData, faq: FAQArray })
        }
    }

    const addFAQHandler = () => {
        let newFAQ = [...formData?.faq]
        let faqItem = {
            question: '',
            answer: ""
        }
        newFAQ = [...newFAQ, faqItem]
        setFormData({ ...formData, faq: newFAQ })
    }

    const handleRemoveFAQ = (faQIdx) => {
        let FAQArray = [...formData?.faq]
        FAQArray = FAQArray?.filter(((faq, _idx) => _idx !== faQIdx))
        setFormData({ ...formData, faq: FAQArray })
    }

    const updateFAQHandler = () => {
        dispatch(updateMainFAQ({ faq: formData.faq })).unwrap().then((res) => {
            showResponseMessage(res?.message, 'success')
        }).catch((err) => {
            showResponseMessage(err?.message, 'error')
        })
    }

    const showResponseMessage = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
    }


    useEffect(() => {
        if (faqs?.length > 0) {
            setFormData({ ...formData, faq: faqs })
        }
    }, [faqs])

    return (
        <CommonLayout>
            <Box p={{ xs: 2, sm: 3, lg: 4 }}>
                <Card sx={{ p: { xs: 2, md: 3 }, mb: 4, borderRadius: 2.5, boxShadow: "none" }}>
                    <Stack gap={2} mb={4.5}>
                        <Box display='flex' justifyContent='space-between' alignItems={'center'}>
                            <Typography variant='h4' fontWeight="semibold" color="secondary">Homepage FAQ</Typography>
                            {
                                formData?.faq?.length > 0 && <Button
                                    sx={{ minWidth: "140px" }}
                                    type='button'
                                    onClick={addFAQHandler}
                                    variant='contained'
                                    color='primary'
                                >Add More</Button>
                            }
                        </Box>
                        <Grid container>
                            {
                                formData?.faq?.length > 0 ? <>
                                    {
                                        formData?.faq?.map(((faq, idx) => {
                                            return <Grid key={idx} item xs={12} sm={12} lg={12} my={2}>
                                                <Grid container alignItems='flex-start'>
                                                    <Grid item xs={12} lg={6} md={6}>
                                                        <Box display='flex' justifyContent='center' alignItems={'flex-start'}>
                                                            <Box width={'100%'}>
                                                                <Typography variant="subtitle2" fontWeight="medium" color={grey[600]} mb={1}>Question</Typography>
                                                                <CommonQuillEditor
                                                                    allowImage={false}
                                                                    value={faq?.question || ""}
                                                                    onChange={(value) => handleChangeFAQ(value, 'question', idx)}
                                                                />
                                                            </Box>
                                                            <Box>
                                                                <IconButton onClick={() => handleRemoveFAQ(idx)}>
                                                                    <RemoveCircle color='error' />
                                                                </IconButton>
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <Grid container alignItems='flex-start' mt={2}>
                                                    <Grid item xs={12} lg={6} md={6}>
                                                        <Typography variant="subtitle2" fontWeight="medium" color={grey[600]} mb={1}>Answer</Typography>
                                                        <CommonQuillEditor
                                                            allowImage={false}
                                                            value={faq?.answer || ""}
                                                            onChange={(value) => handleChangeFAQ(value, 'answer', idx)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }))
                                    }
                                </> : <Box display='flex' justifyContent='space-between' alignItems={'center'} my={3}>
                                    <Button
                                        sx={{ minWidth: "140px" }}
                                        type='button'
                                        onClick={addFAQHandler}
                                        variant='contained'
                                        color='primary'
                                    >Add FAQ</Button>
                                </Box>
                            }
                        </Grid>
                        {
                            faqs?.length > 0  &&  <MDButton
                            sx={{ width: "140px" }}
                            type='button'
                            onClick={updateFAQHandler}
                            variant='contained'
                            disabled={loading}
                            loading={loading}
                            color='primary'
                        >Save</MDButton>
                        }
                      
                    </Stack>

                </Card>

            </Box>
        </CommonLayout>
    )
}