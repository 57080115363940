import CommonLayout from 'layouts/DashboardLayout'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Badge, Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Slide, Stack, Tooltip, Typography } from '@mui/material'
import { AgGridTable } from 'components/tables/AgGridTable'
import { useNavigate } from 'react-router-dom'
import MDButton from 'components/MDButton'
import { closeCustomModalDialog, openCustomModalDialog, setModalLoader } from '../../../redux/slices/modalSlice'
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Search from 'examples/Search'
import { useDebounce } from 'hooks/useDebounce'
import EditIcon from 'common/customIcons/edit'
import DeleteIcon from 'common/customIcons/delete'
import { openSnackbar } from '../../../redux/slices/common'
import { formatUsPhoneNumber } from 'utilities/common'
import CloseIcon from '@mui/icons-material/Close';
import { grey } from '@mui/material/colors'
import Zoom from 'react-medium-image-zoom'
import { formatTimestampTo12HourTime } from 'utilities/common'
import { getImageExtension } from 'utilities/common'
import { deleteEmployee, getAllEmployee, setEmployeeInformation } from '../../../redux/slices/hyperwolf/employee'
import { formatTimestampToDateView } from 'utilities/common'

const uploadThumbnailImage = {
    borderRadius: "20px",
    width: "100px",
    height: "100px",
    objectFit: "cover",
    boxShadow: "0 0 1px rgb(0 0 0 / 20%)",
    marginRight: '10px'
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});
export default function ManageEmployees() {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const pageRef = useRef(0)
    const searchTermRef = useRef("")
    const mainGridApiRef = useRef('')
    const gridRefApi = useRef(null)
    const [searchTerm, setSearchTerm] = useState("")
    const [notesData, setNotesData] = useState("")

    const handleSearch = useDebounce((term) => {
        pageRef.current = 0
        searchFn()
    }, 500);

    const { employees = {}, loading = false } = useSelector(state => state.employee)


    const deleteHandler = (employeeId) => {
        dispatch(setModalLoader(true))
        dispatch(deleteEmployee(employeeId)).unwrap().then((res) => {
            showError(res?.message, 'success')
            dispatch(closeCustomModalDialog())
            pageRef.current = 0
            searchFn()
        }).catch((err) => {
            dispatch(setModalLoader(false))
            showError(err?.message, 'error')
        })
    }

    const showError = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
    }

    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: 'Name',
            field: 'name',
            key: "name",
            suppressMenu: false,
            cellRenderer: (props) => {
                return <Typography variant="caption" display="block" fontWeight="regular" sx={{ cursor: "pointer", whiteSpace: "normal", textTransform: 'capitalize' }}>{props?.data?.name}</Typography>
            },
            minWidth: 240,
        },
        {
            headerName: 'Email',
            field: 'email',
            suppressMenu: false,
            minWidth: 340,
        },
        {
            headerName: 'Phone',
            field: 'phone',
            suppressMenu: false,
            minWidth: 130,
            cellRenderer: (props) => {
                return props?.value ? formatUsPhoneNumber(props?.value) : "-"
            },
        },
        {
            headerName: 'Created Date',
            field: 'createdDate',
            suppressMenu: false,
            minWidth: 80,
            cellRenderer: (props) => {
                return props?.value ? formatTimestampToDateView(props?.value) : "-"
            },
        },

        {
            headerName: 'Actions',
            cellRenderer: (props) => {
                return <Box display="inline-flex" alignItems="center">
                    <IconButton onClick={(e) => {
                        e.stopPropagation()
                        onEditClicked(props)
                    }} color='primary' size='small' variant="primary">
                        <EditIcon width='16px' height='16px' />
                    </IconButton>
                    <IconButton onClick={(e) => {
                        e.stopPropagation()
                        dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: props.data?._id, deleteType: 'emoloyee' }, modal: 'DELETE_MODAL' }))
                    }} color='error' size='small' variant="primary" sx={{ marginLeft: 1 }}>
                        <DeleteIcon width='16px' height='16px' />
                    </IconButton>
                </Box>
            },
            cellStyle: { textAlign: 'right', justifyContent: 'end' },
            headerClass: 'right-aligned-cell-header',
            minWidth: 120,
            maxWidth: 100,
            pinned: 'right'
        },

    ]);

    function getSortInfo(data) {
        const result = {};
        data.forEach(item => {
            if (item?.colId === 'memberType') {
                result[item.colId] = item.sort === "asc" ? 'AdultUse' : "MedicinalUser";
            } else {
                result[item.colId] = item.sort === "asc";
            }

        });

        return result;
    }

    const getRows = async (params) => {
        gridRefApi.current = params;
        let filter = {}
        if (params?.sortModel.length) {
            filter = { ...getSortInfo(params?.sortModel) }
        }
        dispatch(getAllEmployee({ skip: pageRef.current, searchTerm: searchTermRef.current, ...filter })).unwrap().then(res => {
            const { employees = [], total } = res
            params.successCallback([...employees], total);
            pageRef.current = pageRef.current + 10
        })
    }

    const searchFn = () => {
        const dataSource = {
            getRows
        }
        mainGridApiRef.current.ensureIndexVisible(0, null);
        mainGridApiRef.current.setGridOption('datasource', dataSource);
    }

    const onEditClicked = (row) => {
        dispatch(setEmployeeInformation(row?.data))
        navigate('/hyperwolf/manage-employess/add')
    }

    const searchHandler = (event) => {
        const { value } = event.target;
        setSearchTerm(value);
        searchTermRef.current = value
        handleSearch(value);
    };

    useEffect(() => {
        if (mainGridApiRef?.current) {
            if (loading) {
                mainGridApiRef.current.showLoadingOverlay()
            } else if (employees?.total == 0) {
                mainGridApiRef.current.showNoRowsOverlay()
            }
            else {
                mainGridApiRef.current.hideOverlay()
            }
        }

    }, [employees, loading])

    return (
        <CommonLayout>
            <Box p={{ xs: 2, sm: 3, lg: 4 }}>
                <Grid container alignItems="center" rowSpacing={2} columnSpacing={2} mt={1} mb={3}>
                    <Grid item xs={12} sm={4}>
                        <Typography variant='h4'>Employees</Typography>
                    </Grid>

                    <Grid item xs={12} sm={8}>
                        <Stack direction="row" justifyContent={{ xs: "start", sm: "end" }} flexWrap="wrap" alignItems="stretch" gap={2}>
                            <Search placeholder="Search" label="" value={searchTerm} onChange={searchHandler} />
                            <MDButton
                                startIcon={<AddRoundedIcon fontSize='large' />}
                                variant="contained" color="primary"
                                onClick={() => navigate('/hyperwolf/manage-employess/add')} >
                                Add Employee
                            </MDButton>
                        </Stack>
                    </Grid>
                </Grid>

                <AgGridTable col={columnDefs} maxLimit={10} getRows={getRows} mainGridApiRef={mainGridApiRef} pageRef={pageRef} isLoading={loading} style={{ height: "58vh" }} />
            </Box>
            <Dialog open={Boolean(notesData)} TransitionComponent={Transition} keepMounted maxWidth='xs' fullWidth className="notes-modal"
                onClose={() => setNotesData('')}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="draggable-dialog-title" display="flex" alignItems="center" justifyContent="space-between" mb={0.5}>
                    Ban Member
                    <IconButton onClick={() => setNotesData('')} edge='end' size='small'>
                        <CloseIcon fontSize='medium' />
                    </IconButton>
                </DialogTitle>

                <DialogContent className="notes-modal-body">
                    <Typography color={grey[600]} sx={{ wordBreak: 'break-word' }} fontSize='14px'>{notesData?.memberNotes || '-'}</Typography>
                    <Box mt={2}>
                        <Typography variant='h6' fontWeight="semibold" mb={1.5}>Attachments</Typography>
                        {notesData?.notesAttachment?.length > 0 ?
                            <>
                                {
                                    notesData?.notesAttachment?.filter((noteImage => getImageExtension(noteImage) !== 'pdf'))?.length > 0 && <>
                                        <Typography sx={{ wordBreak: 'break-word' }} fontSize='14px' fontWeight='semibold' mb={0.5}>Image</Typography>
                                        <Badge
                                            overlap="circular"
                                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                        >
                                            {notesData?.notesAttachment && notesData?.notesAttachment?.filter((noteImage => getImageExtension(noteImage) !== 'pdf'))?.map(((image, index) => <Zoom key={index} classDialog='custom-zoom'><img key={index} style={uploadThumbnailImage} src={image} alt="notesAttachment" /></Zoom>))}

                                        </Badge>
                                    </>
                                }
                                {
                                    notesData?.notesAttachment && notesData?.notesAttachment?.filter((noteImage => getImageExtension(noteImage) === 'pdf'))?.length > 0 && <Box mt={2}>
                                        <Typography sx={{ wordBreak: 'break-word' }} fontSize='14px' fontWeight='semibold'>PDF Links</Typography>
                                        {
                                            notesData?.notesAttachment && notesData?.notesAttachment?.filter((noteImage => getImageExtension(noteImage) === 'pdf'))?.map((pdfUrl => {
                                                return <Tooltip key={pdfUrl} title={pdfUrl} arrow placement="top"><Box mr={2} className='text-truncate'>{pdfUrl}</Box></Tooltip>
                                            }))
                                        }
                                    </Box>
                                }

                            </>
                            : '-'
                        }
                    </Box>
                    <Stack direction='row' justifyContent='space-between' gap={4} alignItems='center' mt={2}>
                        <Box>
                            <Typography fontSize='14px' fontWeight="semibold" >Time of Ban</Typography>
                            <Typography fontWeight='semibold' fontSize='18px' color='#656575'>{notesData?.notesTime ? formatTimestampTo12HourTime(notesData?.notesTime) : ""}</Typography>
                        </Box>
                        <Box>
                            <Typography fontSize='14px' fontWeight="semibold" >Employee Name</Typography>
                            <Typography fontWeight='semibold' fontSize='18px' color='#656575'>{notesData?.memberNotesEmployee}</Typography>
                        </Box>
                    </Stack>

                </DialogContent>
            </Dialog>
        </CommonLayout>
    )
}