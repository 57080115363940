import {  FilterAltOutlined, RemoveRedEyeRounded } from '@mui/icons-material'
import {  Box,  Chip,  Divider, FormControl, Grid, IconButton,  InputLabel, List, ListItem,  MenuItem, Paper, Select, Stack,  Tooltip, Typography } from '@mui/material'
import MDButton from 'components/MDButton'
import { AgGridTable } from 'components/tables/AgGridTable'
import Search from 'examples/Search'
import CommonLayout from 'layouts/DashboardLayout'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { closeCustomModalDialog, openCustomModalDialog, setModalLoader } from '../../redux/slices/modalSlice'
import { deleteOrder, fechWarrantyOrders,  fetchOrders, warrantyStatusUpdate } from '../../redux/slices/orders'
import { formatAddress } from 'utilities/common'
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useDebounce } from 'hooks/useDebounce'
import DeleteIcon from 'common/customIcons/delete'
import EditIcon from 'common/customIcons/edit'
import StaticsOverview from 'common/StaticsOverview'
import RibbonBadgeIcon from 'common/customIcons/ribbon_badge'
import RevenueIcon from 'common/customIcons/revenue'
import ActiveIcon from 'common/customIcons/active'
import InProgressIcon from 'common/customIcons/inprogress'
import { openSnackbar } from '../../redux/slices/common'
import MDBox from 'components/MDBox'
import colors from "assets/theme/base/colors";
import InActiveIcon from 'common/customIcons/inactive'
import { formatTimestampToDateView } from 'utilities/common'
import { sortsBy } from 'utilities/common'
import ShippedIcon from 'common/customIcons/shipped'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import { formatUsPhoneNumber } from 'utilities/common'
import { addCommasWithToFixed } from 'utilities/common'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const statusObj = {
    delivered: <Box color="#24CA49" display="flex" alignItems="center">
        <ActiveIcon width="18px" height="18px" flex="none" />
        <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">Delivered</Typography>
    </Box>,
    shipped: <Box color="#24CA49" display="flex" alignItems="center">
        <ActiveIcon width="18px" height="18px" flex="none" />
        <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">Shipped</Typography>
    </Box>,
    cancelled: <Box color="#FD4438" display="flex" alignItems="center">
        <InActiveIcon width="18px" height="18px" flex="none" />
        <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">Cancelled</Typography>
    </Box>,
    awaiting_shipment: <Box color="#8E0CF5" display="flex" alignItems="center">
        <InProgressIcon width="18px" height="18px" flex="none" />
        <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">In Progress</Typography>
    </Box>,
    on_hold: <Box color="#FF7A2F" display="flex" alignItems="center">
        <InProgressIcon width="18px" height="18px" flex="none" />
        <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">On Hold</Typography>
    </Box>
}

const warrantyStatus = {
    rejected: <Box color="#FD4438" display="flex" alignItems="center">
        <InActiveIcon width="18px" height="18px" flex="none" />
        <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">Rejected</Typography>
    </Box>,
    approved: <Box color="#24CA49" display="flex" alignItems="center">
        <ActiveIcon width="18px" height="18px" flex="none" />
        <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">Approved</Typography>
    </Box>,
}

export default function MemberOrders() {
    const { grey, secondary } = colors;
    const dispatch = useDispatch()
    const { memberId } = useParams()
    const navigate = useNavigate();
    const { search } = useLocation()
    const { isLoading = false, orders = {}, isUpdatingStatus = false } = useSelector(state => state.orders)
    const { canceledCount = 0, deliveredCount = 0, pendingCount = 0, totalRevenue = 0, total = 0, shippedCount = 0, dashboardCount = 0 } = orders
    const pageRef = useRef(0)
    const searchTermRef = useRef("")
    const sortOrderRef = useRef("All Time")
    const orderStatusFilterRef = useRef("")
    const mainGridApiRef = useRef('')
    const gridRefApi = useRef(null)
    const [searchTerm, setSearchTerm] = useState("")
    const [sortOrder, setSortOrder] = useState('All Time')
    const [currentTab, setCurrentTab] = useState("")
    const [notesData, setNotesData] = useState("")
    const [mount, setMount] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: 'Order #',
            field: 'orderId',
            key: "fullName",
            suppressMenu: false,
            cellRenderer: (props) => {
                return <Typography variant="caption" display="block" fontWeight="regular" sx={{ cursor: "pointer" }}>{props?.data?.orderId}</Typography>
            },
            minWidth: 100,
        },
        {
            headerName: 'Consumer',
            field: 'fullName',
            key: "",
            suppressMenu: false,
            cellRenderer: (props) => {
                return <Typography variant="caption" display="block" fontWeight="regular" sx={{ whiteSpace: "normal" }}>{props?.data?.userData?.fullName || props?.data?.userData?.name}</Typography>
            },
            minWidth: 160,
        },
        {
            headerName: 'Email/Phone',
            field: 'email_phone',
            key: "",
            suppressMenu: false,
            cellRenderer: (props) => {
                return <MDBox textAlign="left">
                    <Typography variant="caption" display="block" fontWeight="regular">{props?.data?.userData?.email}</Typography>
                    <Typography variant="caption" display="block" color="secondary" mt={.5}>{props?.data?.userData?.phone ? formatUsPhoneNumber(props?.data?.userData?.phone) : '-'}</Typography>
                </MDBox>
            },
            minWidth: 300,
        },
        {
            headerName: 'Address',
            field: 'deliveryAddress',
            suppressMenu: false,
            cellRenderer: (props) => {
                return <Tooltip title={props?.value} arrow placement="top">
                    <Typography className="truncate line-2" variant="caption" display="block" fontWeight="regular" sx={{ whiteSpace: "normal" }}>{props?.value}</Typography>
                </Tooltip>
            },
            minWidth: 220,
        },
        {
            headerName: 'Created Date',
            field: 'createdDate',
            suppressMenu: false,
        },
        {
            headerName: 'Delivered Date',
            field: 'deliveredDate',
            suppressMenu: false,
        },
        {
            headerName: 'Status',
            field: 'emailStatus',
            suppressMenu: false,
            cellRenderer: (props) => {
                return props?.data?.orderId ? <>{statusObj[props?.value || "awaiting_shipment"]}</> : null
            },
            minWidth: 100,
        },
        {
            headerName: 'Warranty',
            field: 'warrantyStatus',
            suppressMenu: false,
            cellRenderer: (props) => {
                return props?.data?.orderId ? <>{warrantyStatus[props?.value] || '-'}</> : null
            },
            minWidth: 100,
        },
        {
            headerName: 'Notes',
            field: 'notes',
            suppressMenu: false,
            cellRenderer: (props) => {
                return props?.data?.orderId ? <MDButton onClick={() => {
                    setNotesData(props?.data)
                }} size="small" startIcon={<RemoveRedEyeRounded fontSize='small' />} variant="contained" color="primary" className="btn-primary-light">
                    View Notes
                </MDButton> : null
            },
            minWidth: 140,
        },
        {
            headerName: 'Actions',
            cellRenderer: (props) => {
                return props?.data?.orderId ? <Box display="inline-flex" alignItems="center">
                    {<IconButton disabled={props?.data?.emailStatus === 'delivered'} onClick={() => navigate(`/orders/${props.data.orderId}`)} color='primary' size='small' variant="primary">
                        <EditIcon width='16px' height='16px' />
                    </IconButton>}
                    <IconButton onClick={() => dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: props.data?.orderId }, modal: 'DELETE_MODAL' }))} color='error' size='small' variant="primary" sx={{ marginLeft: 1 }}>
                        <DeleteIcon width='16px' height='16px' />
                    </IconButton>
                </Box> : null
            },
            cellStyle: { textAlign: 'right' },
            headerClass: 'right-aligned-cell-header',
            minWidth: 120,
            maxWidth: 100,
            pinned: 'right'
        },

    ]);


    const handleSearch = useDebounce((term) => {
        // Perform search operation with the debounced term
        setSearchParams({ orderFilter: orderStatusFilterRef?.current || '', sortby: sortOrderRef.current || '', searchTerm: term || '' })
        pageRef.current = 0
        pageRef.current = 0
        getRowsData()
    }, 500);


    const getRowsData = () => {
        const dataSource = {
            getRows
        }
        mainGridApiRef.current.setGridOption('datasource', dataSource);
    }

    const searchHandler = (event) => {
        const { value } = event.target;
        setSearchTerm(value);
        searchTermRef.current = value
        handleSearch(value);
        // Debounce the search callback
    };

    const getRows = async (params) => {
        gridRefApi.current = params
        const sortModel = params?.sortModel
        let sortedColumnName = '';
        let sortDirection = ''
        if (sortModel.length > 0) {
            const sortedColumn = sortModel[0];
            sortedColumnName = sortedColumn.colId;
            sortDirection = sortedColumn.sort === 'asc' ? 'low' : 'high'
        }
        const selectedTab = searchParams?.get('orderFilter') || "";
        if (selectedTab === 'warranty') {
            dispatch(fechWarrantyOrders({ skip: pageRef.current, limit: 20, userType: 'admin', searchTerm: searchTermRef.current, dashboardQuery: sortOrderRef?.current === 'All Time' ? "" : sortOrderRef?.current, status: orderStatusFilterRef.current, [sortedColumnName]: sortDirection, memberId })).unwrap().then(res => {
                const { orders, total } = res
                const orderArray = orders?.map((item => {
                    return { ...item, id: item._id, deliveryAddress: formatAddress(item?.deliveryAddress), createdDate: formatTimestampToDateView(item?.createdDate), deliveredDate: item?.deliveredDate ? formatTimestampToDateView(item?.deliveredDate) : '-' }
                }))
                params.successCallback(orderArray, total);
                pageRef.current = pageRef.current + 20
            })
        } else {
            dispatch(fetchOrders({ skip: pageRef.current, limit: 20, userType: 'admin', searchTerm: searchTermRef.current, dashboardQuery: sortOrderRef?.current === 'All Time' ? "" : sortOrderRef?.current, status: orderStatusFilterRef.current, [sortedColumnName]: sortDirection, memberId })).unwrap().then(res => {
                const { orders, total } = res
                const orderArray = orders?.map((item => {
                    return { ...item, id: item._id, deliveryAddress: formatAddress(item?.deliveryAddress), createdDate: formatTimestampToDateView(item?.createdDate), deliveredDate: item?.deliveredDate ? formatTimestampToDateView(item?.deliveredDate) : '-' }
                }))
                params.successCallback(orderArray, total);
                pageRef.current = pageRef.current + 20
            })
        }
    }

    const onRowClicked = (row) => {
        if (row?.colDef?.headerName !== 'Actions' && row?.colDef?.headerName !== 'Notes' && row?.colDef?.headerName !== 'Warranty') {
            navigate(`/orders/details/${row?.data.orderId}`)
        }
    }

    const deleteHandler = (orderId) => {
        dispatch(setModalLoader(true))
        dispatch(deleteOrder(orderId)).unwrap().then((res) => {
            showResponseMessage(res?.message, 'success')
            dispatch(closeCustomModalDialog())
            pageRef.current = 0
            getRows(gridRefApi.current)
        }).catch((err) => {
            dispatch(setModalLoader(false))
            showResponseMessage(err?.message, 'error')
        })
    }

    const showResponseMessage = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
    }
    useEffect(() => {
        if (search) {
            // const selectedTab =searchParams?.get('orderFilter')|| "";
            if (searchParams?.get('orderFilter') !== currentTab && mainGridApiRef.current) {
                setSearchParams({ orderFilter: searchParams?.get('orderFilter') || '', sortby: searchParams?.get('sortby') || '' })
                setCurrentTab(searchParams?.get('orderFilter'))
                setSortOrder(searchParams?.get('sortby'))
                setSearchTerm(searchParams?.get('searchTerm') || '')
                searchTermRef.current = searchParams?.get('searchTerm') || ''
                sortOrderRef.current = searchParams?.get('sortby')
                orderStatusFilterRef.current = searchParams?.get('orderFilter');
                pageRef.current = 0
                getRowsData()
            } else {
                setCurrentTab(searchParams?.get('orderFilter'))
                setSortOrder(searchParams?.get('sortby'))
                setSearchTerm(searchParams?.get('searchTerm') || '')
                searchTermRef.current = searchParams?.get('searchTerm') || ''
                sortOrderRef.current = searchParams?.get('sortby') || ''
                orderStatusFilterRef.current = searchParams?.get('orderFilter') || '';
            }
        }
        else {
            navigate('/orders?orderFilter=')
            pageRef.current = 0
            setCurrentTab('')
        }
    }, [search, orderStatusFilterRef.current])

    useEffect(() => {
        if (mainGridApiRef?.current) {
            if (isLoading) {
                mainGridApiRef.current.showLoadingOverlay()
            } else if (orders?.total == 0) {
                mainGridApiRef.current.showNoRowsOverlay()
            }
            else {
                mainGridApiRef.current.hideOverlay()
            }
        }
    }, [orders, isLoading])

    const warrantyStatusHanlder = (status, orderId) => {
        dispatch(warrantyStatusUpdate({ returnApprovedStatus: status, orderId })).unwrap().then((res) => {
            showResponseMessage(res?.message, 'success')
            pageRef.current = 0
            getRowsData()
        }).catch((err) => {
            showResponseMessage(err?.message, 'error')
        })
    }

    const warrantyDialogHandler = (status, orderId) => {
        dispatch(openCustomModalDialog({ modal: 'WARRANTY_REASON', modalData: { status, orderId, warrantyStatusHanlder } }))
    }


    useEffect(() => {
        if (!mount) return;
        const selectedTab = searchParams?.get('orderFilter') || "";
        if (selectedTab === "warranty") {
            setColumnDefs([
                {
                    headerName: 'Order #',
                    field: 'orderId',
                    key: "fullName",
                    suppressMenu: false,
                    cellRenderer: (props) => {
                        return <Typography variant="caption" display="block" fontWeight="regular" sx={{ cursor: "pointer" }}>{props?.data?.orderId}</Typography>
                    },
                    minWidth: 100,
                },
                {
                    headerName: 'Consumer',
                    field: 'fullName',
                    key: "",
                    suppressMenu: false,
                    cellRenderer: (props) => {
                        return <Typography variant="caption" display="block" fontWeight="regular" sx={{ whiteSpace: "normal" }}>{props?.data?.userData?.fullName || props?.data?.userData?.name}</Typography>
                    },
                    minWidth: 160,
                },
                {
                    headerName: 'Email/Phone',
                    field: 'email_phone',
                    key: "",
                    suppressMenu: false,
                    cellRenderer: (props) => {
                        return <MDBox textAlign="left">
                            <Typography variant="caption" display="block" fontWeight="regular">{props?.data?.userData?.email}</Typography>
                            <Typography variant="caption" display="block" color="secondary" mt={.5}>{props?.data?.userData?.phone ? formatUsPhoneNumber(props?.data?.userData?.phone) : '-'}</Typography>
                        </MDBox>
                    },
                    minWidth: 300,
                },
                {
                    headerName: 'Address',
                    field: 'deliveryAddress',
                    suppressMenu: false,
                    cellRenderer: (props) => {
                        return <Tooltip title={props?.value} arrow placement="top">
                            <Typography className="truncate line-2" variant="caption" display="block" fontWeight="regular" sx={{ whiteSpace: "normal" }}>{props?.value}</Typography>
                        </Tooltip>
                    },
                    minWidth: 220,
                },

                {
                    headerName: 'Created Date',
                    field: 'createdDate',
                    suppressMenu: false,
                },
                {
                    headerName: 'Delivered Date',
                    field: 'deliveredDate',
                    suppressMenu: false,
                },
                {
                    headerName: 'Notes',
                    field: 'notes',
                    suppressMenu: false,
                    cellRenderer: (props) => {
                        return props?.data?.orderId ? <MDButton onClick={() => {
                            setNotesData(props?.data)
                        }} size="small" startIcon={<RemoveRedEyeRounded fontSize='small' />} variant="contained" color="primary" className="btn-primary-light">
                            View Notes
                        </MDButton> : null
                    },
                    minWidth: 140,
                },
                {
                    headerName: 'Warranty',
                    cellRenderer: (props) => {
                        return props?.data?.orderId ? <Box display="inline-flex" alignItems="center">
                            {
                                props?.data?.warrantyStatus === 'approved' || props?.data?.warrantyStatus === 'rejected' ? warrantyStatus[props?.data?.warrantyStatus] : <Box display="inline-flex" gap={1} className="invite-action">
                                    <Chip onClick={() => warrantyDialogHandler(true, props?.data?.orderId)} disabled={isUpdatingStatus || props?.data?.warrantyStatus === 'approved'} label='Approve' size='small' color='success' />
                                    <Chip onClick={() => warrantyDialogHandler(false, props?.data?.orderId)} disabled={isUpdatingStatus || props?.data?.warrantyStatus === 'approved'} label='Reject' size='small' color='error' />
                                </Box>
                            }

                        </Box> : null
                    },
                    minWidth: 180,
                    maxWidth: 180,
                    cellStyle: { textAlign: 'right' },
                    headerClass: 'right-aligned-cell-header',
                    pinned: 'right'
                },

            ])
        } else {
            setColumnDefs(
                [
                    {
                        headerName: 'Order #',
                        field: 'orderId',
                        key: "fullName",
                        suppressMenu: false,
                        cellRenderer: (props) => {
                            return <Typography variant="caption" display="block" fontWeight="regular" sx={{ cursor: "pointer" }}>{props?.data?.orderId}</Typography>
                        },
                        minWidth: 100,
                    },
                    {
                        headerName: 'Consumer',
                        field: 'fullName',
                        key: "",
                        suppressMenu: false,
                        cellRenderer: (props) => {
                            return <Typography variant="caption" display="block" fontWeight="regular" sx={{ whiteSpace: "normal" }}>{props?.data?.userData?.fullName || props?.data?.userData?.name}</Typography>
                        },
                        minWidth: 160,
                    },
                    {
                        headerName: 'Email/Phone',
                        field: 'email_phone',
                        key: "",
                        suppressMenu: false,
                        cellRenderer: (props) => {
                            return <MDBox textAlign="left">
                                <Typography variant="caption" display="block" fontWeight="regular">{props?.data?.userData?.email}</Typography>
                                <Typography variant="caption" display="block" color="secondary" mt={.5}>{props?.data?.userData?.phone ? formatUsPhoneNumber(props?.data?.userData?.phone) : '-'}</Typography>
                            </MDBox>
                        },
                        minWidth: 300,
                    },
                    {
                        headerName: 'Address',
                        field: 'deliveryAddress',
                        suppressMenu: false,
                        cellRenderer: (props) => {
                            return <Tooltip title={props?.value} arrow placement="top">
                                <Typography className="truncate line-2" variant="caption" display="block" fontWeight="regular" sx={{ whiteSpace: "normal" }}>{props?.value}</Typography>
                            </Tooltip>
                        },
                        minWidth: 220,
                    },

                    {
                        headerName: 'Created Date',
                        field: 'createdDate',
                        suppressMenu: false,
                    },
                    {
                        headerName: 'Delivered Date',
                        field: 'deliveredDate',
                        suppressMenu: false,
                    },
                    {
                        headerName: 'Status',
                        field: 'emailStatus',
                        suppressMenu: false,
                        cellRenderer: (props) => {
                            return props?.data?.orderId ? <>{statusObj[props?.value || "awaiting_shipment"]}</> : null
                        },
                        minWidth: 100,
                    },
                    {
                        headerName: 'Warranty',
                        field: 'warrantyStatus',
                        suppressMenu: false,
                        cellRenderer: (props) => {
                            return props?.data?.orderId ? <>{warrantyStatus[props?.value] || '-'}</> : null
                        },
                        minWidth: 100,
                    },
                    {
                        headerName: 'Notes',
                        field: 'notes',
                        suppressMenu: false,
                        cellRenderer: (props) => {
                            return props?.data?.orderId ? <MDButton onClick={() => {
                                setNotesData(props?.data)
                            }} size="small" startIcon={<RemoveRedEyeRounded fontSize='small' />} variant="contained" color="primary" className="btn-primary-light">
                                View Notes
                            </MDButton> : null
                        },
                        minWidth: 140,
                    },
                    {
                        headerName: 'Actions',
                        cellRenderer: (props) => {
                            return props?.data?.orderId ? <Box display="inline-flex" alignItems="center">
                                {<IconButton disabled={props?.data?.emailStatus === 'delivered'} onClick={() => navigate(`/orders/${props.data.orderId}`)} color='primary' size='small' variant="primary">
                                    <EditIcon width='16px' height='16px' />
                                </IconButton>}
                                <IconButton onClick={() => dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: props.data?.orderId }, modal: 'DELETE_MODAL' }))} color='error' size='small' variant="primary" sx={{ marginLeft: 1 }}>
                                    <DeleteIcon width='16px' height='16px' />
                                </IconButton>
                            </Box> : null
                        },
                        cellStyle: { textAlign: 'right' },
                        headerClass: 'right-aligned-cell-header',
                        minWidth: 120,
                        maxWidth: 100,
                        pinned: 'right'
                    },

                ])
        }
    }, [search, mount])

    return (
        <CommonLayout>
            <Box p={{ xs: 2, sm: 3, lg: 4 }}>
                <Paper elevation={0} style={{ borderRadius: "12px" }}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="start" justifyContent="space-between" bgcolor="white" p={{ xs: 1, md: 2 }}>
                        <StaticsOverview
                            color="warning"
                            iconShadow="iconShadowWarning"
                            icon={<RibbonBadgeIcon />}
                            title="Total Orders"
                            count={dashboardCount}
                        />
                        <StaticsOverview
                            color="info"
                            iconShadow="iconShadowInfo"
                            icon={<RevenueIcon />}
                            title="Total Revenue"
                            count={`$${totalRevenue ? addCommasWithToFixed(totalRevenue.toFixed(2)) : 0}`}
                        />
                        <StaticsOverview
                            color="primary"
                            iconShadow="iconShadowInfo"
                            icon={<InProgressIcon />}
                            title="In Progress"
                            count={pendingCount}
                        />
                        <StaticsOverview
                            color="success"
                            iconShadow="iconShadowSuccess"
                            icon={<ShippedIcon />}
                            title="Shipped"
                            count={shippedCount}
                        />
                        <StaticsOverview
                            color="success"
                            iconShadow="iconShadowSuccess"
                            icon={<ActiveIcon />}
                            title="Completed"
                            count={deliveredCount}
                        />
                        <StaticsOverview
                            color="error"
                            iconShadow="iconShadowDanger"
                            icon={<InActiveIcon />}
                            title="Cancelled"
                            count={canceledCount}
                        />

                    </Stack>
                </Paper>
                <Grid container alignItems="center" rowSpacing={2} columnSpacing={2} mt={1} mb={3}>
                    <Grid item xs={12} md={4}>
                        <Typography variant='h4'>All Orders</Typography>
                    </Grid>

                    <Grid item xs={12} md={8}>
                        <Stack direction="row" justifyContent={{ xs: "start", md: "end" }} flexWrap="wrap" alignItems="stretch" gap={2}>
                            <Search placeholder="Search" label="" value={searchTerm} onChange={searchHandler} />
                            <MDButton
                                startIcon={<AddRoundedIcon fontSize='large' />}
                                variant="contained" color="primary"
                                onClick={() => navigate('/orders/add')} >
                                Create Order
                            </MDButton>

                            <FormControl className="form-filter-select" fullWidth sx={{ maxWidth: 130 }}>
                                <InputLabel>Sort By</InputLabel>
                                <Select
                                    value={sortOrder}
                                    label='Sort By'
                                    endAdornment={<FilterAltOutlined className='filter-icon' fontSize='medium' color='secondary' />}
                                >
                                    {
                                        sortsBy?.map((sort => {
                                            return <MenuItem value={sort?.value || ""} onClick={() => {
                                                setSortOrder(sort?.value)
                                                setSearchParams({ orderFilter: searchParams?.get('orderFilter') || '', sortby: sort?.value || '', searchTerm: searchParams?.get('searchTerm') })
                                                sortOrderRef.current = sort?.value;
                                                handleSearch()
                                            }} key={sort?.key} variant="contained" color={sort.value === sortOrder ? "primary" : "white"} size="small" className={sort.value === sortOrder ? 'tab-active' : ""}>{sort?.key}</MenuItem>
                                        }))
                                    }
                                </Select>
                            </FormControl>
                        </Stack>
                    </Grid>
                </Grid>

                <AgGridTable col={columnDefs} mainGridApiRef={mainGridApiRef} getRows={getRows} pageRef={pageRef} isLoading={isLoading} onRowClicked={onRowClicked} style={{ height: "58vh" }} />
            </Box>

            <Dialog open={Boolean(notesData)} TransitionComponent={Transition} keepMounted maxWidth='xs' fullWidth className="notes-modal"
                onClose={() => setNotesData('')}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="draggable-dialog-title" display="flex" alignItems="center" justifyContent="space-between" mb={0.5}>
                    {"Notes"}
                    <IconButton onClick={() => setNotesData('')} edge='end' size='small'>
                        <CloseIcon fontSize='medium' />
                    </IconButton>
                </DialogTitle>

                <DialogContent className="notes-modal-body">
                    <DialogContentText id="alert-dialog-slide-description">
                        <List>
                            <ListItem>
                                <Typography variant="body2" fontWeight="medium"><span style={{ color: grey[600] }}>Payment Option:</span></Typography>
                                <Chip size='small' label="Credit" />
                            </ListItem>
                            <Divider component="li" />
                            <ListItem>
                                <Typography variant="body2" fontWeight="medium"><span style={{ color: grey[600] }}>Total Amount:</span></Typography>
                                <Chip size="small" label={notesData?.total && `$${addCommasWithToFixed(notesData?.total) || 0}`} />
                            </ListItem>
                            <Divider component="li" />
                            <ListItem sx={{ flexDirection: "column", alignItems: "start" }}>
                                <Typography variant="body2" fontWeight="medium" mb={0.5} color={grey[600]}>Instructions:</Typography>
                                <Typography variant="body2" fontWeight="medium">{notesData?.memo}</Typography>
                            </ListItem>
                        </List>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </CommonLayout>
    )

}