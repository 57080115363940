import React from 'react'

export default function LateOrderIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="11" viewBox="0 0 9 11" fill="none">
      <g clipPath="url(#clip0_11642_21814)">
        <path d="M8.4015 1.07253C8.30386 0.99928 8.19042 0.949456 8.0701 0.926963C7.94977 0.904469 7.82582 0.909919 7.70797 0.942883L6.53886 1.27288C6.28961 1.34254 6.0252 1.33796 5.77856 1.25973C5.53191 1.18149 5.31394 1.03304 5.15179 0.832883C4.84831 0.459072 4.42515 0.199214 3.95237 0.0963355C3.47958 -0.00654319 2.98553 0.0537278 2.55202 0.267169L1.40669 0.828955C1.34133 0.861601 1.28637 0.91155 1.2479 0.973265C1.20943 1.03498 1.18894 1.10605 1.18872 1.1786V5.50003C1.18893 5.56699 1.2064 5.6328 1.23947 5.69119C1.27255 5.74958 1.32014 5.79862 1.37771 5.83366C1.43529 5.86869 1.50094 5.88855 1.56844 5.89135C1.63594 5.89415 1.70304 5.87981 1.76337 5.84967L2.8968 5.29181C3.1676 5.15768 3.47659 5.11961 3.77226 5.18395C4.06794 5.24829 4.33244 5.41115 4.52166 5.64538C4.72009 5.89077 4.97165 6.08879 5.2577 6.22479C5.54376 6.36079 5.857 6.4313 6.17426 6.4311C6.37122 6.43232 6.56736 6.40587 6.75683 6.35253L8.14391 5.95967C8.30928 5.91278 8.45478 5.81383 8.55844 5.67776C8.66211 5.5417 8.7183 5.37589 8.71855 5.20538V1.7011C8.71855 1.57912 8.6899 1.45882 8.63487 1.34972C8.57984 1.24061 8.49994 1.14571 8.4015 1.07253Z" fill="#FD4438"/>
        <path d="M2.77415 10.2143H1.98153V0.392857C1.98153 0.288665 1.93978 0.18874 1.86546 0.115065C1.79114 0.0413902 1.69033 0 1.58523 0C1.48012 0 1.37932 0.0413902 1.305 0.115065C1.23067 0.18874 1.18892 0.288665 1.18892 0.392857V10.2143H0.396307C0.2912 10.2143 0.190398 10.2557 0.116076 10.3294C0.0417537 10.403 0 10.503 0 10.6071C0 10.7113 0.0417537 10.8113 0.116076 10.8849C0.190398 10.9586 0.2912 11 0.396307 11H2.77415C2.87926 11 2.98006 10.9586 3.05438 10.8849C3.1287 10.8113 3.17045 10.7113 3.17045 10.6071C3.17045 10.503 3.1287 10.403 3.05438 10.3294C2.98006 10.2557 2.87926 10.2143 2.77415 10.2143Z" fill="#FD4438"/>
      </g>
      <defs>
        <clipPath id="clip0_11642_21814">
          <rect width="9" height="11" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}