import React, { useEffect, useRef, useState } from 'react';
import {
    TextField,
    Grid,
    FormControl,
    InputLabel,
    MenuItem,
    Card,
    Select,
    Typography,
    Box,
    FormHelperText,
    Stack,
    InputAdornment,
    Tooltip,
    IconButton,
    FormControlLabel,
    RadioGroup,
    Radio,
    Switch,
} from '@mui/material';
import InputMask from 'react-input-mask';
import MDButton from 'components/MDButton';
import { addRetailer, deleteRetailer, updateRetailer } from '../../redux/slices/retailerSlice';
import { useDispatch, useSelector } from 'react-redux';
import { openSnackbar, uploadImage } from '../../redux/slices/common';
import MapWithPolygon from 'components/googleMaps';
import { useNavigate, useParams } from 'react-router-dom';
import { closeCustomModalDialog, openCustomModalDialog, setModalLoader } from '../../redux/slices/modalSlice';
import GoogleSuggestion from 'components/googleMaps/GoogleSuggestion';
import { UploadFile } from '@mui/icons-material';
import { isEmptyObject } from 'utilities/common';
import { retailerValidation } from 'validations/retailer';
import { isValidFileExtension } from 'utilities/common';
import dayjs from 'dayjs';
import DateRange from 'components/dateRangePicker/DateRange';
import 'daterangepicker';
import 'daterangepicker/daterangepicker.css';


const AddRetailerForm = () => {
    const { retailerDetails, loading = false } = useSelector(state => state.retailers)
    const { isUploading = false } = useSelector(state => state.common)
    const [uploadedKML, setUploadedKML] = useState("")
    const kmlRef = useRef()
    const addressError = useRef(false)
    const { retailerId } = useParams()
    const [disabled, setDisabled] = useState(true);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        retailerName: '',
        email: '',
        phone: '',
        subscription: '',
        range: '',
        valiDateFrom: null,
        valiDateTo: null,
        isLifeTime: false,
        address: '',
        isViewUser: true,
        status: "inactive",
        send: false,
        kml: '',
    });
    const [errors, setErrors] = useState({})
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [isToggleOn, setIsToggleOn] = useState(false)

    const uploadKMLHandler = async (e) => {
        if (e.target.files.length) {
            if (!isValidFileExtension(e.target.files[0], '.kml')) {
                showMessage('Upload only KML file', 'error')
                return;
            }
            const file = e.target.files[0];
            const body = new FormData()
            body.append("filePath", file);
            dispatch(uploadImage(body)).unwrap().then((res => {
                let errorsObj = { ...errors }
                delete errorsObj.uploadedKML
                setErrors(errorsObj)
                setUploadedKML(res?.fileUrl)
            })).catch(() => { })
            e.target = ""

        }
    }

    const setDateRange = (dates) => {
        if (dates && dates.length === 2) {
            setFormData({
                ...formData,
                valiDateFrom: dates[0],
                valiDateTo: dates[1],
                isLifeTime: false
            })
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (['city', 'state', 'zipcode'].includes(name)) {
            setFormData(prevFormData => ({
                ...prevFormData,
                address: {
                    ...prevFormData.address,
                    [name]: value
                }
            }))
        }
        else {
            const newValue = name === 'isViewUser' ? value === 'true' : value;
            setFormData({ ...formData, [name]: newValue });
        }
    };

    const handleSwitch = () => {
        setIsToggleOn(!isToggleOn)
        setFormData({ ...formData, isLifeTime: !formData.isLifeTime, valiDateFrom: null, valiDateTo: null })
    }

    const deleteHandler = (retailerId) => {
        dispatch(setModalLoader(true))
        dispatch(deleteRetailer(retailerId)).unwrap().then((res) => {
            showMessage(res?.message, 'success')
            dispatch(closeCustomModalDialog())
            navigate('/retailers')
        }).catch((err) => {
            dispatch(setModalLoader(false))
            showMessage(err?.message, 'error')
        })
    }

    const googleAddressErrorHandler = (status = false) => {
        addressError.current = status
        if (status) {
            setErrors({ ...errors, address: 'Address is required' })
        }
        else {
            setErrors(prevErrors => ({ ...prevErrors, address: '' }));
        }
    }

    const handleSubmit = (e, send = false) => {
        e.preventDefault();

        const { errors, isValid } = retailerValidation({ ...formData, uploadedKML })
        if (!isValid) {

            setErrors(errors)
            return;
        }
        setErrors({})
        const payload = {
            retailerName: formData.retailerName,
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            phone: formData?.phone?.replace(/[^\d]/g, ''),
            subscription: formData.subscription,
            valiDateFrom: dayjs(formData.valiDateFrom).valueOf(),
            valiDateTo: dayjs(formData.valiDateTo).valueOf(),
            isLifeTime: formData.isLifeTime,
            address: formData?.address,
            isViewUser: formData.isViewUser,
            status: formData.status,
            kml: uploadedKML || formData?.kml,
            branchId: retailerDetails.branchId
        };
        if (send) {
            payload.send = send;
        }

        if (retailerId) {
            dispatch(updateRetailer({ data: payload, retailerId })).unwrap().then((res) => {
                showMessage(res?.message, 'success')
                navigate('/retailers')
            }).catch((err) => {
                showMessage(err?.message, 'error')
            })
        } else {
            dispatch(addRetailer(payload)).unwrap().then((res) => {
                showMessage(res?.message, 'success')
                navigate('/retailers')
            }).catch((err) => {
                showMessage(err?.message, 'error')
            })
        }
    }

    const showMessage = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
    }

    useEffect(() => {
        if (!isEmptyObject(retailerDetails)) {
            let payload = {
                ...retailerDetails, validUpto: retailerDetails?.validUpto ? dayjs(retailerDetails?.validUpto) : ''
            }
            if (retailerDetails?.kml) {
                setUploadedKML(retailerDetails?.kml)
            }
            setFormData({ ...payload })
        }
    }, [retailerDetails])

    return (
        <Box p={{ xs: 2, sm: 3, lg: 4 }}>
            <Card sx={{ p: { xs: 2, md: 3 }, borderRadius: 2.5, boxShadow: "none" }}>
                <Grid>
                    <Stack direction={{ xs: "column", sm: "row" }} alignItems={{ sm: "center" }} justifyContent="space-between" gap={2} mb={4.5}>
                        <Typography variant='h4' fontWeight="semibold" color="secondary">{retailerId ? "Update" : 'Add'} Retailer</Typography>
                        <Box display="flex" alignItems="center" gap={1.25}>

                            {retailerId &&

                                <>
                                    <Typography variant='h5' fontWeight="semibold" color="secondary">Status</Typography>
                                    <Stack direction="row" alignItems="center" gap={.75} lineHeight={1} p={.75} borderRadius={2} bgcolor="#F2F3F7">
                                        <MDButton
                                            sx={{ minWidth: "92px", borderRadius: 1.75, minHeight: "28px", p: .25, fontWeight: 500 }}
                                            type='button'
                                            variant={formData?.status === "active" ? 'contained' : 'text'}
                                            color={formData?.status === "active" ? 'success' : 'dark'}
                                            size="small"
                                            onClick={() => retailerId ? (formData?.kml || uploadedKML) && setFormData({ ...formData, status: "active" }) : uploadedKML && setFormData({ ...formData, status: "active" })}
                                        >
                                            Active
                                        </MDButton>
                                        <MDButton
                                            sx={{ minWidth: "92px", borderRadius: 1.75, minHeight: "28px", p: .25, fontWeight: 500 }}
                                            type='button'
                                            variant={formData?.status !== "active" ? 'contained' : 'text'}
                                            color={formData?.status !== "active" ? 'error' : 'dark'}
                                            size="small"
                                            onClick={() => setFormData({ ...formData, status: "inactive" })}
                                        >
                                            Inactive
                                        </MDButton>
                                    </Stack>
                                </>
                            }
                        </Box>
                    </Stack>

                    <Grid container spacing={2.5} mb={4.5}>
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField className="form-input" error={errors?.retailerName} helperText={errors.retailerName} fullWidth label="Retailer Name*" name="retailerName" value={formData?.retailerName} onChange={handleChange} />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <TextField className="form-input" error={errors?.firstName} helperText={errors.firstName} fullWidth label="First Name*" name="firstName" value={formData?.firstName || ""} onChange={handleChange} />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <TextField className="form-input" error={errors?.lastName} helperText={errors.lastName} fullWidth label="Last Name*" name="lastName" value={formData?.lastName} onChange={handleChange} />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            {retailerId ?
                                <TextField className="form-input" error={errors?.email} helperText={errors.email} fullWidth label="Email*" name="email" value={formData?.email} onChange={handleChange} disabled />
                                :
                                <TextField className="form-input" error={errors?.email} helperText={errors.email} fullWidth label="Email*" name="email" value={formData?.email} onChange={handleChange} />
                            }
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <InputMask
                                className={`form-input ${retailerId ? 'disabled' : ''}`}
                                fullWidth
                                error={errors?.phone}
                                helperText={errors.phone}
                                label="Phone*"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                mask='(999) 999-9999'
                                maskChar=''   >
                                {(inputProps) => <TextField {...inputProps} disableUnderline
                                    InputProps={{
                                        ...inputProps.InputProps, readOnly: retailerId ? disabled : '',
                                        style: { opacity: retailerId && disabled ? 1 : 1 }
                                    }} />}
                            </InputMask>
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <FormControl error={Boolean(errors?.userType)} fullWidth>
                                <InputLabel id="user-type-label">Subscription*</InputLabel>
                                <Select
                                    className="form-select"
                                    labelId="user-type-label"
                                    id="user-type-select"
                                    value={formData?.subscription}
                                    label="Subscription*"
                                    name='subscription'
                                    onChange={handleChange}
                                >
                                    <MenuItem value={'Paid'}>Paid</MenuItem>
                                    <MenuItem value={'Unpaid'}>Unpaid</MenuItem>
                                </Select>
                                {errors?.subscription && <FormHelperText error>{errors?.subscription}</FormHelperText>}
                            </FormControl>
                        </Grid>

                        {formData.subscription === "Paid" &&

                            (<>
                                <Grid item xs={12} md={6} lg={3}>
                                    <FormControl fullWidth className={`${formData?.isLifeTime ? 'disabled' : ''}`}>
                                        <DateRange setDateRange={setDateRange} name='range' dateRange={[formData.valiDateFrom, formData.valiDateTo]}
                                            disabled={formData?.isLifeTime ? disabled : false}
                                        />
                                        {Boolean(errors?.validUpto) && <FormHelperText error>{errors?.validUpto}</FormHelperText>}
                                    </FormControl>
                                    <Stack direction={{ xs: "column", sm: "row" }}>
                                        <Switch checked={formData.isLifeTime} name='validity' onChange={handleSwitch} />
                                        <Typography variant='h6' fontWeight="semibold" color="secondary" mt={1}>Lifetime</Typography>
                                    </Stack>

                                </Grid>

                            </>
                            )
                        }
                    </Grid>
                    <Typography variant='h5' fontWeight="semibold" mb={2}>Address</Typography>
                    <Grid container spacing={2.5} mb={4.5}>
                        <Grid item xs={12} className="address-field">
                            <GoogleSuggestion addressValue={formData?.address.address} googleAddressErrorHandler={googleAddressErrorHandler}
                                onClearAddress={(address) => setFormData({ ...formData, address })}
                                addressChangeHandler={(address) => setFormData({ ...formData, address })} />
                            {errors?.address && <FormHelperText error>{errors?.address}</FormHelperText>}
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField className="form-input" fullWidth label="City*" name="city" value={formData?.address.city || ""} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField className="form-input" fullWidth label="State*" name="state" value={formData?.address.state || ""} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField className="form-input" fullWidth label="Zipcode*" name="zipcode" value={formData?.address.zipcode || ""} onChange={handleChange} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2.5} mb={4.5}>

                        <Grid item xs={12} md={6} lg={6}>
                            <Typography variant='h5' fontWeight="semibold" mb={2}>Upload KML File</Typography>

                            <Box display='flex' justifyContent='space-between'>
                                <Tooltip title={uploadedKML} sx={{
                                    whiteSpace: 'pre-wrap',
                                }} arrow placement="bottom">
                                    <TextField
                                        fullWidth
                                        label="Upload KML*"
                                        disabled={isUploading}
                                        className="form-input-upload"
                                        value={retailerId && retailerDetails.kml !== '' ? formData.kml : uploadedKML}
                                        error={Boolean(errors.uploadedKML)}
                                        helperText={errors.uploadedKML}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton color='secondary' onClick={
                                                        (e) => {
                                                            e.stopPropagation()
                                                            kmlRef.current.click()
                                                        }
                                                    }>
                                                        <UploadFile />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Tooltip>

                                <input
                                    id="product-image-input"
                                    type="file"
                                    name="productImageFile"
                                    onChange={uploadKMLHandler}
                                    ref={kmlRef}
                                    accept='.kml'
                                    style={{ display: 'none' }}
                                />
                            </Box>

                            {
                                uploadedKML && <Grid item xs={12} sm={4} lg={4} md={4}>
                                    <Box sx={{
                                        borderRadius: 4, overflow: "hidden", height: 230, m: 2
                                        , display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center', justifyContent: 'center'
                                    }}>
                                        <MapWithPolygon kmlUrl={uploadedKML} />
                                    </Box>
                                </Grid>
                            }

                            {retailerId && !uploadedKML ?

                                <Grid item xs={12} lg={4} md={2}>

                                    {uploadedKML || formData.kml &&
                                        <Box sx={{
                                            borderRadius: 4, overflow: "hidden", height: 230, m: 2
                                            , display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center', justifyContent: 'center'
                                        }}>
                                            <MapWithPolygon kmlUrl={uploadedKML ? uploadedKML : formData?.kml} />
                                        </Box>
                                    }
                                </Grid> : ''
                            }
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Typography variant='h5' fontWeight="semibold" mb={2}>Permission to view user details</Typography>

                            <FormControl className='d-flex custom-checkbox-ui '>
                                <RadioGroup
                                    value={formData.isViewUser}
                                    name='isViewUser'
                                    label="Yes or No"
                                    onChange={handleChange}
                                    color="secondary"
                                >
                                    <FormControlLabel value={true} control={<Radio color="secondary" />} label="Yes" />
                                    <FormControlLabel value={false} control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Stack direction={{ xs: "column", sm: "row" }} spacing={1.25} mt={4} mb={2}>
                        <MDButton
                            sx={{ minWidth: "140px" }}
                            type='button'
                            variant='contained'
                            color='primary'
                            onClick={(e) => handleSubmit(e, true)}
                            disabled={isUploading || loading}
                            loading={loading}

                        >
                            {retailerId ? 'Update' : 'Save & Send'}
                        </MDButton>

                        {
                            retailerId && <MDButton
                                sx={{ minWidth: "140px", backgroundColor: "#F1F1F1", color: "#FD4438" }}
                                type='button'
                                variant='contained'
                                color='error'
                                onClick={() => dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: retailerId, deleteType: 'retailer' }, modal: 'DELETE_MODAL' }))}
                            >
                                Delete
                            </MDButton>
                        }

                        <MDButton
                            sx={{ minWidth: "140px" }}
                            type='button'
                            variant='outlined'
                            color='dark'
                            onClick={() => navigate('/retailers')}

                        >
                            Cancel
                        </MDButton>
                    </Stack>
                </Grid>
            </Card>
        </Box >
    )
}

export default AddRetailerForm;