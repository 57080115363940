import React, { useEffect, useRef, useState } from 'react';
import { TextField, Grid, Card, Typography, FormHelperText, Box, Stack, InputAdornment, CircularProgress, createSvgIcon, Badge, FormControlLabel, FormGroup, Switch } from '@mui/material';
import MDButton from 'components/MDButton';
import { useDispatch, useSelector } from 'react-redux';
import { createBrand, deleteBrand, updateBrand } from '../../redux/slices/brand';
import { useNavigate, useParams } from 'react-router-dom';
import { openSnackbar, uploadImage } from '../../redux/slices/common';
import { isEmptyObject } from 'utilities/common';
import { closeCustomModalDialog, openCustomModalDialog, setModalLoader } from '../../redux/slices/modalSlice';
import { grey } from '@mui/material/colors';
import CommonQuillEditor from 'common/editor';
import { AccountCircle } from '@mui/icons-material';

const PlusIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
    <path d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" />
  </svg>,
  'Plus',
);
const uploadThumbnailImage = {
  borderRadius: "20px",
  width: "160px",
  height: "160px",
  objectFit: "cover",
  boxShadow: "0 0 1px rgb(0 0 0 / 20%)"
};
const circularProgressIcon = {
  backgroundColor: "#fff",
  borderRadius: 10,
  p: .5,
  position: "absolute"
};
const thumbnailCloseIcon = {
  backgroundColor: "#FD4438",
  borderRadius: 10,
  p: .25,
  mt: -4,
  mr: -4,
  cursor: "pointer"
};

const AddBrandForm = () => {
  const { brandId } = useParams()
  const { user } = useSelector(state => state.auth)
  const [formState, setFormState] = useState({
    brandName: '',
    description: '',
    createdBy: user?.name || '',
    status: 'active',
    isBestBrand: false,
    isPopularBrand: false,
    metaDescription: "",
    metaTitle: "",
    description_left_side: "",
    description_right_side: '',
    brand_left_side: [],
    brand_right_side: []
  });

  const [errors, setErrors] = useState({});
  const { singleBrand = {}, isUpdating = false, isLoading = false } = useSelector(state => state.brands)
  const { isUploading = false } = useSelector(state => state.common)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const brandLeftRightLoader = useRef('')

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevFormState) => ({
      ...prevFormState,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '', // Clear the error when the field is updated
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    // Example validation rules (you can customize these as needed)
    if (!formState.brandName.trim()) {
      newErrors.brandName = 'Brand Name is required';
    }

    if (!formState.description.trim()) {
      newErrors.description = 'Description is required';
    }

    if (!formState.createdBy.trim()) {
      newErrors.createdBy = 'Created By is required';
    }

    if (!formState.status.trim()) {
      newErrors.status = 'Status is required';
    }
    if (!formState.description.trim()) {
      newErrors.description = 'Description is required';
    }

    if (!formState.metaDescription.trim()) {
      newErrors.metaDescription = 'Meta Description is required';
    }
    if (!formState.metaTitle.trim()) {
      newErrors.metaTitle = 'Meta Title is required';
    }

    if (!formState.image) {
      newErrors.image = 'Brand Image is required';
    }

    setErrors(newErrors);

    // Return true if there are no errors, indicating the form is valid
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (validateForm()) {
      // Add your form submission logic here
      if (brandId) {
        dispatch(updateBrand({ data: formState, brandId })).unwrap().then((res) => {
          navigate('/brands?orderFilter=brands')
          showError(res?.message, 'success')
        }).catch(err => {
          showError(err?.message, 'error')
        })
      } else {
        dispatch(createBrand(formState)).unwrap().then((res) => {
          showError(res?.message, 'success')
          navigate('/brands?orderFilter=brands')
        }).catch(err => {
          showError('Something went wrong', 'error')
        })
      }
    }
  };

  const showError = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
    }
  }

  const deleteHandler = () => {
    dispatch(setModalLoader(true))
    dispatch(deleteBrand(brandId)).unwrap().then((res) => {
      showError(res?.message, 'success')
      dispatch(closeCustomModalDialog())
      navigate('/brands?orderFilter=brands')
    }).catch((err) => {
      dispatch(setModalLoader(false))
      showError(err?.message, 'error')
    })
  }

  const brandImageUploadHandler = async (e) => {
    brandLeftRightLoader.current = null
    if (e.target.files.length) {
      const file = e.target.files[0];
      const body = new FormData()
      body.append("filePath", file);
      dispatch(uploadImage(body)).unwrap().then((res => {
        setFormState({ ...formState, image: res.fileUrl })
      })).catch((err) => {
        showError(err?.message, 'error')
      })
    }

  }

  const brandLeftRightImageUploadHandler = async (e, type) => {
    brandLeftRightLoader.current = type;
    if (e.target.files.length) {
      const file = e.target.files[0];
      const body = new FormData()
      body.append("filePath", file);
      dispatch(uploadImage(body)).unwrap().then((res => {
        setFormState({ ...formState, [type]: [...formState[type], res.fileUrl] })
      })).catch((err) => {
        showError(err?.message, 'error')
      })
    }

  }

  const removeImageHandler = (brandImageIdx = '', type) => {
    let brandImages = [...formState[type]];
    let newbrandImages = brandImages?.filter(((product, _brandImageIdx) => _brandImageIdx !== brandImageIdx))
    setFormState({ ...formState, [type]: newbrandImages })
  }

  useEffect(() => {
    if (!isEmptyObject(singleBrand)) {
      setFormState({ ...formState, ...singleBrand })
    }
  }, [singleBrand])

  useEffect(() => {
    setFormState({ ...formState, createdBy: user?.name })
  }, [user])

  return (
    <Box p={{ xs: 2, sm: 3, lg: 4 }}>
      <Card sx={{ p: { xs: 2, md: 3 }, mb: 4, borderRadius: 2.5, boxShadow: "none" }}>
        <Stack direction={{ xs: "column", sm: "row" }} alignItems={{ sm: "center" }} justifyContent="space-between" gap={2} mb={4.5}>
          <Typography variant='h4' fontWeight="semibold" color="secondary">{brandId ? 'Update' : "Add"} Brand</Typography>
          <Box display="flex" alignItems="center" gap={1.25}>
            <Typography variant='h5' fontWeight="semibold" color="secondary">Status</Typography>
            <Stack direction="row" alignItems="center" gap={.75} lineHeight={1} p={.75} borderRadius={2} bgcolor="#F2F3F7">
              <MDButton
                sx={{ minWidth: "92px", borderRadius: 1.75, minHeight: "28px", p: .25, fontWeight: 500 }}
                type='button'
                onClick={() => setFormState({ ...formState, status: 'active' })}
                variant={formState?.status === 'active' ? 'contained' : 'text'}
                color={formState?.status === 'active' ? 'success' : 'dark'}
                size="small"
              >
                Active
              </MDButton>
              <MDButton
                sx={{ minWidth: "92px", borderRadius: 1.75, minHeight: "28px", p: .25, fontWeight: 500 }}
                type='button'
                variant={formState?.status !== 'active' ? 'contained' : 'text'}
                color={formState?.status !== 'active' ? 'error' : 'dark'}
                size="small"
                onClick={() => setFormState({ ...formState, status: 'inactive' })}
              >
                Inactive
              </MDButton>
            </Stack>
          </Box>
        </Stack>

        <Typography variant='h5' fontWeight="semibold" mb={2}>Brand Image*</Typography>
        <Stack direction="row" flexWrap="wrap" gap={1.25} mb={4.5}>
          {
            formState?.image && <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <img src={formState?.image} style={uploadThumbnailImage} />
            </Badge>
          }
          <label htmlFor='product-image-input'>
            <Box bgcolor="#F1F1F1" display="flex" alignItems="center" justifyContent="center" flexDirection="column" minWidth="160px" minHeight="160px" borderRadius="20px" p={2} sx={{ cursor: 'pointer' }}>
              {
                isUploading && !brandLeftRightLoader.current ? <CircularProgress size={32} color='primary' sx={circularProgressIcon} /> : <Typography variant='h5' fontWeight="medium" textAlign="center">+<br /> {formState?.image ? 'Update' : "Add"}  </Typography>
              }
            </Box>
          </label>
          <TextField
            id="product-image-input"
            type='file'
            name='brandImageFile'
            onChange={brandImageUploadHandler}
            sx={{ display: 'none' }}
          />

          {Boolean(errors?.image) &&
            <Box display="inline-flex" width="100%">
              <FormHelperText error>{errors?.image}</FormHelperText>
            </Box>
          }
        </Stack>

        <Grid container spacing={2.5} mb={4.5}>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              fullWidth
              label="Brand Name*"
              name="brandName"
              className="form-input"
              value={formState.brandName}
              onChange={handleChange}
              error={Boolean(errors.brandName)}
              helperText={errors.brandName}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <TextField
              fullWidth
              label="Created By"
              disabled
              name="createdBy"
              className="form-input created-by"
              value={formState.createdBy}
              onChange={handleChange}
              error={Boolean(errors.createdBy)}
              helperText={errors.createdBy}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle fontSize='medium' color='secondary' />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle2" fontWeight="medium" color={grey[600]} mb={1}>Description*</Typography>
            <CommonQuillEditor
              value={formState?.description || ""}
              onChange={(value) => setFormState({ ...formState, description: value })}
            />
            {Boolean(errors?.description) && <FormHelperText error>{errors?.description}</FormHelperText>}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" fontWeight="medium" color={grey[600]} mb={1}>Description Left Side</Typography>
            <CommonQuillEditor
              value={formState?.description_left_side || ""}
              onChange={(value) => setFormState({ ...formState, description_left_side: value })}
            />
            {Boolean(errors?.description_left_side) && <FormHelperText error>{errors?.description_left_side}</FormHelperText>}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" fontWeight="medium" color={grey[600]} mb={1}>Description Right Side</Typography>
            <CommonQuillEditor
              value={formState?.description_right_side || ""}
              onChange={(value) => setFormState({ ...formState, description_right_side: value })}
            />
            {Boolean(errors?.description_right_side) && <FormHelperText error>{errors?.description_right_side}</FormHelperText>}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" fontWeight="medium" color={grey[600]} mb={1}>Brand Left Image</Typography>
            <Stack direction="row" flexWrap="wrap" gap={1.25} mb={4.5}>
              {
                formState?.brand_left_side?.length > 0 &&
                formState?.brand_left_side?.map(((brandLeftImage, idx) => {
                  return <Badge
                    key={idx}
                    overlap="circular"
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    badgeContent={
                      <PlusIcon onClick={() => removeImageHandler(idx, 'brand_left_side')} color='white' fontSize='small' sx={thumbnailCloseIcon} />
                    }
                  >
                    <img src={brandLeftImage} style={uploadThumbnailImage} />
                  </Badge>
                }))
              }
              <label htmlFor='product-left-image-input'>
                <Box bgcolor="#F1F1F1" display="flex" alignItems="center" justifyContent="center" flexDirection="column" minWidth="160px" minHeight="160px" borderRadius="20px" p={2} sx={{ cursor: 'pointer' }}>
                  {
                    isUploading && brandLeftRightLoader.current === 'brand_left_side' ? <CircularProgress size={32} color='primary' sx={circularProgressIcon} /> : <Typography variant='h5' fontWeight="medium" textAlign="center">+<br /> {formState?.brand_left_side?.length ? 'Update' : "Add"}  </Typography>
                  }
                </Box>
              </label>
              <TextField
                id="product-left-image-input"
                type='file'
                name='brandLeftImageFile'
                onChange={(e) => brandLeftRightImageUploadHandler(e, 'brand_left_side')}
                sx={{ display: 'none' }}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" fontWeight="medium" color={grey[600]} mb={1}>Brand Right Image</Typography>
            <Stack direction="row" flexWrap="wrap" gap={1.25} mb={4.5}>
              {
                formState?.brand_right_side?.length > 0 &&
                formState?.brand_right_side?.map(((brandRightImage, idx) => {
                  return <Badge
                    key={idx}
                    overlap="circular"
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    badgeContent={
                      <PlusIcon onClick={() => removeImageHandler(idx, 'brand_right_side')} color='white' fontSize='small' sx={thumbnailCloseIcon} />
                    }
                  >
                    <img src={brandRightImage} style={uploadThumbnailImage} />
                  </Badge>
                }))
              }
              <label htmlFor='product-right-image-input'>
                <Box bgcolor="#F1F1F1" display="flex" alignItems="center" justifyContent="center" flexDirection="column" minWidth="160px" minHeight="160px" borderRadius="20px" p={2} sx={{ cursor: 'pointer' }}>
                  {
                    isUploading && brandLeftRightLoader.current === 'brand_right_side' ? <CircularProgress size={32} color='primary' sx={circularProgressIcon} /> : <Typography variant='h5' fontWeight="medium" textAlign="center">+<br /> {formState?.brand_right_side?.length ? 'Update' : "Add"}  </Typography>
                  }
                </Box>
              </label>
              <TextField
                id="product-right-image-input"
                type='file'
                name='brandRightImageFile'
                onChange={(e) => brandLeftRightImageUploadHandler(e, 'brand_right_side')}
                sx={{ display: 'none' }}
              />
            </Stack>

          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <FormGroup>
              <FormControlLabel control={<Switch checked={formState?.isBestBrand} onChange={(e) => setFormState({ ...formState, isBestBrand: e.target.checked })} />} label="Best Brand" />
            </FormGroup>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <FormGroup>
              <FormControlLabel control={<Switch checked={formState?.isPopularBrand} onChange={(e) => setFormState({ ...formState, isPopularBrand: e.target.checked })} />} label="Popular  Brand" />
            </FormGroup>
          </Grid>

          <Grid item xs={12} lg={12}>
            <Typography variant='h5' fontWeight="semibold" mt={2}>Other Info</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" fontWeight="medium" color={grey[600]} mb={1}>Meta Title*</Typography>
            <CommonQuillEditor
              value={formState?.metaTitle || ""}
              onChange={(value) => setFormState({ ...formState, metaTitle: value })}
            />
            {Boolean(errors?.metaTitle) && <FormHelperText error>{errors?.metaTitle}</FormHelperText>}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" fontWeight="medium" color={grey[600]} mb={1}>Meta Description*</Typography>
            <CommonQuillEditor
              value={formState?.metaDescription || ""}
              onChange={(value) => setFormState({ ...formState, metaDescription: value })}
            />
            {Boolean(errors?.metaDescription) && <FormHelperText error>{errors?.metaDescription}</FormHelperText>}
          </Grid>
        </Grid>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={1.25} mt={4} mb={2}>
          <MDButton
            sx={{ minWidth: "140px" }}
            type='button'
            variant='contained'
            color='primary'
            onClick={handleSubmit}
            loading={isUpdating || isLoading}
            disabled={isLoading || isUpdating}

          >
            {
              brandId ? "Update" : "Add"
            }
          </MDButton>
          {
            brandId && <MDButton
              sx={{ minWidth: "140px", backgroundColor: "#F1F1F1", color: "#FD4438" }}
              type='button'
              variant='contained'
              color='error'
              onClick={() => dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: brandId }, modal: 'DELETE_MODAL' }))}
            >
              Delete
            </MDButton>
          }
          <MDButton
            sx={{ minWidth: "140px" }}
            type='button'
            variant='outlined'
            color='dark'
            onClick={() => navigate('/brands')}
          >
            Cancel
          </MDButton>
        </Stack>
      </Card>
    </Box>
  );
};

export default AddBrandForm;