import { Box, Chip, Divider, DialogTitle, Grid, IconButton, List, ListItem, Stack, Tooltip, Typography, colors, Slide, Paper, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import MDButton from 'components/MDButton'
import { AgGridTable } from 'components/tables/AgGridTable'
import Search from 'examples/Search'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useDebounce } from 'hooks/useDebounce'
import { formatAddress } from 'utilities/common'
import ActiveIcon from 'common/customIcons/active'
import InActiveIcon from 'common/customIcons/inactive'
import InProgressIcon from 'common/customIcons/inprogress'
import MDBox from 'components/MDBox'
import { FilterAltOutlined, RemoveRedEyeRounded } from '@mui/icons-material'
import { fetchOrders } from '../../../redux/slices/orders'
import { formatUsPhoneNumber } from 'utilities/common'
import { formatTimestampToDateView } from 'utilities/common'
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { addCommasWithToFixed } from 'utilities/common'
import { capitalizeText } from 'utilities/common'
import StaticsOverview from 'common/StaticsOverview'
import OrdersIcon from 'common/customIcons/orders'
import StoreIcon from 'common/customIcons/store'
import FilterOrders from 'common/CommonDrawer/orders/FilterOrders'
import { sortsBy } from 'utilities/common'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const statusObj = {
    delivered: <Box color="#24CA49" display="flex" alignItems="center">
        <ActiveIcon width="18px" height="18px" flex="none" />
        <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">Delivered</Typography>
    </Box>,
    shipped: <Box color="#24CA49" display="flex" alignItems="center">
        <ActiveIcon width="18px" height="18px" flex="none" />
        <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">Shipped</Typography>
    </Box>,
    cancelled: <Box color="#FD4438" display="flex" alignItems="center">
        <InActiveIcon width="18px" height="18px" flex="none" />
        <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">Cancelled</Typography>
    </Box>,
    awaiting_shipment: <Box color="#8E0CF5" display="flex" alignItems="center">
        <InProgressIcon width="18px" height="18px" flex="none" />
        <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">In Progress</Typography>
    </Box>,
    on_hold: <Box color="#FF7A2F" display="flex" alignItems="center">
        <InProgressIcon width="18px" height="18px" flex="none" />
        <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">On Hold</Typography>
    </Box>,

}

export default function RetailerOrders({ retailerId = "", }) {
    const { grey, secondary } = colors;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { search } = useLocation()
    const { isLoading = false, orders = {} } = useSelector(state => state.orders)
    const { ecommerceCount = 0, storeCount = 0, deliveredCount = 0 } = orders || {}
    const ordersFiltersPayload = useRef({})

    const pageRef = useRef(0);
    const searchTermRef = useRef("")
    const sortOrderRef = useRef('All Time')
    const orderStatusFilterRef = useRef("")
    const platformRef = useRef("ecommerce")
    const [searchParams, setSearchParams] = useSearchParams();
    const mainGridApiRef = useRef('')
    const gridRefApi = useRef(null)
    const [retailerOrdersList, setRetailerOrdersList] = useState([])
    const [searchTerm, setSearchTerm] = useState("")
    const [sortOrder, setSortOrder] = useState('All Time')
    const [currentTab, setCurrentTab] = useState("retailerOrders")
    const [mount, setMount] = useState(false)
    const [platform, setPlatform] = useState('')
    const [notesData, setNotesData] = useState("")
    const queryParams = new URLSearchParams(location.search)
    const filterParam = queryParams.get('platForm')
    const [selectedTab, setSelectedTab] = useState('all')


    useEffect(() => {
        setSelectedTab(filterParam || '')
    }, [filterParam])

    const applyOrderFilter = (filterPayload) => {
        pageRef.current = 0
        ordersFiltersPayload.current = filterPayload
        const dataSource = {
            getRows: (params) => {
                const page = params.endRow / 20;
                dispatch(fetchOrders({
                    skip: 0, limit: 20, userType: 'admin', searchTerm: searchTermRef.current, dashboardQuery: sortOrderRef?.current === 'All Time' ? ""
                        :
                        sortOrderRef?.current, status: orderStatusFilterRef.current, retailerId: retailerId, ...filterPayload?.queryPayload
                }))
                    .unwrap().then(res => {
                        const { orders, total } = res;
                        const orderArray = orders?.map((item => {
                            return {
                                ...item, status: item?.status ? "Active" : "Inactive", deliveryAddress: formatAddress(item?.deliveryAddress), createdDate: formatTimestampToDateView(item?.createdDate)
                            }
                        }))
                        setRetailerOrdersList(orderArray)
                        params.successCallback(orderArray, total);
                        pageRef.current = pageRef.current + 20
                    })
            }
        }
        if (mainGridApiRef?.current) {
            mainGridApiRef.current.ensureIndexVisible(0, null);
            mainGridApiRef.current.setGridOption('datasource', dataSource);
        }
    }


    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: 'Order #',
            field: 'orderId',
            key: "fullName",
            suppressMenu: false,
            cellRenderer: (props) => {
                return <Typography variant="caption" display="block" fontWeight="regular" sx={{ cursor: "pointer" }}>{props?.data?.orderId}</Typography>
            },
            minWidth: 100,
        },
        {
            headerName: 'Consumer',
            field: 'fullName',
            key: "",
            suppressMenu: false,
            cellRenderer: (props) => {
                return <Link to={`/retailers/detail/${retailerId}/members/${props?.data?.userData?.memberId}`}>
                    <Typography variant="caption" display="block" fontWeight="regular" sx={{ whiteSpace: "normal" }}>{props?.data?.userData?.fullName || props?.data?.userData?.name}</Typography>
                </Link>

            },
            minWidth: 160,
        },
        {
            headerName: 'Email/Phone',
            field: 'email_phone',
            key: "",
            suppressMenu: false,
            cellRenderer: (props) => {
                return <MDBox textAlign="left">
                    <Typography variant="caption" display="block" fontWeight="regular">{props?.data?.userData?.email}</Typography>
                    <Typography variant="caption" display="block" color="secondary" mt={.5}>{props?.data?.userData?.phone ? formatUsPhoneNumber(props?.data?.userData?.phone) : '-'}</Typography>
                </MDBox>
            },
            minWidth: 200,
        },

        {
            headerName: 'Created Date',
            field: 'createdDate',
            suppressMenu: false,
        },
        {
            headerName: 'Address',
            field: 'deliveryAddress',
            suppressMenu: false,
            minWidth: 400,
            cellRenderer: (props) => {
                return <Tooltip title={props?.value} arrow placement="top">
                    <Typography className="truncate line-2" variant="caption" display="block" fontWeight="regular" sx={{ whiteSpace: "normal" }}>{props?.value}</Typography>
                </Tooltip>
            },
        },
        {
            headerName: 'Available',
            field: 'orderPlatform',
            suppressMenu: false,
            minWidth: 100,
            cellRenderer: (props) => {
                if (Array.isArray(props.value)) {
                    return props.value?.map(item => capitalizeText(item)).join(', ');
                }
                return capitalizeText(props.value);
            }
        },
        {
            headerName: 'Status',
            field: 'emailStatus',
            suppressMenu: false,
            cellRenderer: (props) => {
                return props?.data?.orderId ? <>{statusObj[props?.value || "awaiting_shipment"]}</> : null
            },
            minWidth: 100,
        },
        {
            headerName: 'Notes',
            field: 'notes',
            suppressMenu: false,
            cellRenderer: (props) => {
                return props?.data?.orderId ? <MDButton onClick={() => {
                    setNotesData(props?.data)
                }} size="small" startIcon={<RemoveRedEyeRounded fontSize='small' />} variant="contained" color="primary" className="btn-primary-light">
                    View Notes
                </MDButton> : null
            },
            minWidth: 140,
        },
    ])

    const searchHandler = (event) => {
        const { value } = event.target;
        setSearchTerm(value);
        searchTermRef.current = value
        handleSearch(value)
    };

    const searchFn = () => {
        const dataSource = {
            getRows
        }
        mainGridApiRef.current.ensureIndexVisible(0, null);
        mainGridApiRef.current.setGridOption('datasource', dataSource);
    }

    useEffect(() => {
        platformRef.current = platform;
    }, [platform]);

    const handleSearch = useDebounce((term = '') => {
        setSearchParams({ filter: currentTab, orderFilter: orderStatusFilterRef?.current || '', sortby: sortOrderRef.current || '', searchTerm: term || '' });
        pageRef.current = 0;
        searchFn();
    }, 500);

    const getRows = async (params) => {
        gridRefApi.current = params
        dispatch(fetchOrders({
            skip: pageRef.current, limit: 20, userType: 'admin', searchTerm: searchTermRef.current, dashboardQuery: sortOrderRef?.current === 'All Time' ? ""
                :
                sortOrderRef?.current, status: orderStatusFilterRef.current, orderPlatform: platformRef.current, retailerId: retailerId
        }
        )).unwrap().then(res => {
            const { orders, total } = res;
            const orderArray = orders?.map((item => {
                return {
                    ...item, status: item?.status ? "Active" : "Inactive", deliveryAddress: formatAddress(item?.deliveryAddress), createdDate: formatTimestampToDateView(item?.createdDate)
                }
            }))
            setRetailerOrdersList(orderArray)
            params.successCallback(orderArray, total);
            pageRef.current = pageRef.current + 20
        })
    }

    const onRowClicked = (row) => {
        if (row?.colDef?.headerName !== 'Notes') {
            navigate(`/retailers/detail/${retailerId}/orders/${row?.data?.orderId}?filter=retailerOrders`)
        }
    }

    useEffect(() => {
        setMount(true)
        return () => {
            setMount(false)
        }
    }, [])

    useEffect(() => {
        if (mainGridApiRef?.current) {
            if (isLoading) {
                mainGridApiRef.current.showLoadingOverlay()
            } else if (orders?.total == 0) {
                mainGridApiRef.current.showNoRowsOverlay()
            }
            else {
                mainGridApiRef.current.hideOverlay()
            }
        }

    }, [orders, isLoading])

    return (<>
        <Paper elevation={0} style={{ borderRadius: "12px" }}>
            <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="start" justifyContent="space-between" bgcolor="white" p={{ xs: 1, md: 2 }}>
                <StaticsOverview
                    color="success"
                    iconShadow="iconShadowSuccess"
                    icon={<OrdersIcon />}
                    title="E-commerce Orders"
                    count={ecommerceCount}
                />
                <StaticsOverview
                    color="error"
                    iconShadow="iconShadowDanger"
                    icon={<StoreIcon />}
                    title="Store Orders"
                    count={storeCount}
                />
                <StaticsOverview
                    color="success"
                    iconShadow="iconShadowSuccess"
                    icon={<ActiveIcon />}
                    title="Delivered"
                    count={deliveredCount}
                />
            </Stack>
        </Paper>

        <Paper elevation={0} style={{ borderRadius: "12px" }}>

        </Paper>
        <Grid container alignItems="center" rowSpacing={2} columnSpacing={2} mt={1} mb={3}>
            <Grid item xs={12} md={8}>
                <Typography variant='h4'>Orders</Typography>
            </Grid>

            <Grid item xs={12} md={4} display="flex-end">
                <Stack direction="row" justifyContent={{ xs: "start", md: "end" }} flexWrap="wrap" alignItems="stretch" gap={2}>
                    <Search placeholder="Search" label="" value={searchTerm} onChange={searchHandler} />
                    <FormControl className="form-filter-select" fullWidth sx={{ maxWidth: 130 }}>
                        <InputLabel>Sort By</InputLabel>
                        <Select
                            value={sortOrder}
                            label='Sort By'
                            endAdornment={<FilterAltOutlined className='filter-icon' fontSize='medium' color='secondary' />}
                        >
                            {
                                sortsBy?.map((sort => {
                                    return <MenuItem value={sort?.value || ""} onClick={() => {
                                        setSortOrder(sort?.value)
                                        setSearchParams({ orderFilter: searchParams?.get('orderFilter') || '', sortby: sort?.value || 'today', searchTerm: searchParams?.get('searchTerm') || '' })
                                        sortOrderRef.current = sort?.value;
                                        handleSearch()
                                    }} key={sort?.key} variant="contained" color={sort.value === sortOrder ? "primary" : "white"} size="small" className={sort.value === sortOrder ? 'tab-active' : ""}>{sort?.key}</MenuItem>
                                }))
                            }
                        </Select>
                    </FormControl>
                </Stack>
            </Grid>
        </Grid>

        <FilterOrders applyOrderFilter={applyOrderFilter} ordersFiltersPayload={ordersFiltersPayload} />
        <AgGridTable col={columnDefs} mainGridApiRef={mainGridApiRef} getRows={getRows} pageRef={pageRef} onRowClicked={onRowClicked} style={{ height: "60vh" }} />


        <Dialog open={Boolean(notesData)} TransitionComponent={Transition} keepMounted maxWidth='xs' fullWidth className="notes-modal"
            onClose={() => setNotesData('')}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="draggable-dialog-title" display="flex" alignItems="center" justifyContent="space-between" mb={0.5}>
                {"Notes"}
                <IconButton onClick={() => setNotesData('')} edge='end' size='small'>
                    <CloseIcon fontSize='medium' />
                </IconButton>
            </DialogTitle>

            <DialogContent className="notes-modal-body">
                <DialogContentText id="alert-dialog-slide-description">
                    <List>
                        <ListItem>
                            <Typography variant="body2" fontWeight="medium"><span style={{ color: grey[600] }}>Payment Option:</span></Typography>
                            <Chip size='small' label={(notesData?.walletPointsUsed > 0 && ((notesData?.subTotal - (notesData?.walletPointsUsed || 0)) > 0 ? (notesData?.subTotal - (notesData?.walletPointsUsed || 0))?.toFixed(2) : 0) === 0) ? 'Wallet' : (notesData?.walletPointsUsed > 0 && (notesData?.subTotal - notesData?.walletPointsUsed) > 0) ? 'Credit/Wallet' : "Cash"} />
                        </ListItem>
                        <Divider component="li" />
                        <ListItem>
                            <Typography variant="body2" fontWeight="medium"><span style={{ color: grey[600] }}>Subtotal:</span></Typography>
                            <Typography variant="body2" fontWeight="semibold" >${addCommasWithToFixed(notesData?.subTotal?.toFixed(2))}</Typography>
                        </ListItem>

                        <Divider component="li" />
                        <ListItem>
                            <Typography variant="body2" fontWeight="medium"><span style={{ color: grey[600] }}>Shipping Type:</span></Typography>
                            <Typography variant="body2" fontWeight="semibold" >{notesData?.shippingType ? capitalizeText(notesData?.shippingType) : "-"}</Typography>
                        </ListItem>
                        <Divider component="li" />
                        <ListItem>
                            <Typography variant="body2" fontWeight="medium"><span style={{ color: grey[600] }}>Shipping:</span></Typography>
                            {
                                !notesData?.shippingCharges ? <Typography variant="body2" fontWeight="semibold" color="success.main">Free</Typography> : <Typography variant="body2" fontWeight="semibold" >${notesData?.shippingCharges}</Typography>
                            }
                        </ListItem>

                        {
                            notesData?.walletPointsUsed > 0 &&
                            <>
                                <Divider component="li" />
                                <ListItem>
                                    <Typography variant="body2" fontWeight="medium"><span style={{ color: grey[600] }}>Wallet deducted amount:</span></Typography>
                                    <Typography variant="body2" fontWeight="semibold" >-${notesData?.walletPointsUsed?.toFixed(2)}</Typography>
                                </ListItem>
                            </>
                        }
                        <Divider component="li" />
                        <ListItem>
                            <Typography variant="body2" fontWeight="medium"><span style={{ color: grey[600] }}>Total Amount:</span></Typography>
                            <Typography variant="body2" fontWeight="semibold">${addCommasWithToFixed(notesData?.total) || 0}</Typography>
                        </ListItem>
                        {
                            notesData?.memo && <>
                                <Divider component="li" />
                                <ListItem sx={{ flexDirection: "column", alignItems: "start" }}>
                                    <Typography variant="body2" fontWeight="medium" mb={0.5} color={grey[600]}>Instructions:</Typography>
                                    <Typography variant="body2" fontWeight="medium">{notesData?.memo}</Typography>
                                </ListItem>
                            </>
                        }

                        {
                            notesData?.returnComments &&
                            <>
                                <Divider component="li" />
                                <ListItem sx={{ flexDirection: "row", alignItems: "start" }}>
                                    <Typography variant="body2" fontWeight="medium" mb={0.5} color={grey[600]}>Return reason:</Typography>
                                    {notesData?.returnComments}
                                </ListItem>
                            </>
                        }
                        {
                            notesData?.emailStatus === 'cancelled' && notesData?.cancellationReason?.length > 0 &&
                            <>
                                <Divider component="li" />
                                <ListItem sx={{ flexDirection: "row", alignItems: "start" }}>
                                    <Typography variant="body2" fontWeight="medium" mb={0.5} color={grey[600]}>Cancel reason:</Typography>
                                    {
                                        notesData?.cancellationReason?.length > 50 ? <>
                                            <Tooltip title={notesData?.cancellationReason}>
                                                <Typography variant="body2" fontWeight="medium" mb={0.5} color={red[600]}>  {notesData?.cancellationReason?.slice(0, 50) + '...'}</Typography>
                                            </Tooltip>

                                        </> : <Typography variant="body2" fontWeight="medium" mb={0.5} color={red[600]}>  {notesData?.cancellationReason}</Typography>
                                    }


                                </ListItem>
                            </>
                        }
                    </List>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    </>
    )

}