import React, { useEffect, useMemo, useState } from 'react';
import { Button,  Grid, Box, Paper } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import DraggableList from './DraggableList';
import { deleteCategory, getAllBlazeCategory, getAllCategory,  setCategoryInformation, updateCategories } from '../../../redux/slices/hyperwolf/products'
import CommonLayout from 'layouts/DashboardLayout';
import NoDataFound from 'common/component/NoDataFound';
import { openSnackbar } from '../../../redux/slices/common';
import CommonLoading from 'components/common/CommonLoading';
import { useNavigate } from 'react-router-dom';
import { closeCustomModalDialog, openCustomModalDialog, setModalLoader } from '../../../redux/slices/modalSlice';

export default function ManageHyperwolfCategories() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [category, setCategory] = useState([])

  const { categories: allCategoryData = {}, loading: isLoading, blazeCategories: allBlazeCategoryData = {}, categoryImages = [] } = useSelector(state => state.hyperwolfProducts)
  const { values: categoryValues = [] } = allCategoryData || {}

  const { values: blazeCategoryArray = [] } = allBlazeCategoryData || {}

  useEffect(() => {
    dispatch(getAllCategory())
    dispatch(getAllBlazeCategory())

  }, [])

  useEffect(() => {
    if (categoryValues) {
      setCategory(categoryValues)
    }
  }, [(JSON.stringify(categoryValues)).length])

  const handleChangeSubCategory = (e, val, index) => {
    const updatedCategory = [...category];
    const catObj = { ...updatedCategory[index] };

    if (catObj.categoryName.includes('Deals')) {

      const newValue = {
        _id: e.target.id,
        name: e.target.value
      };
      catObj.blazeCategories = [newValue];
    } else {

      let newVal = val && val.length && val.map(v => ({ id: v.id, name: v.name })) || [];
      catObj.blazeCategories = [...newVal];
    }

    updatedCategory[index] = catObj;
    setCategory(updatedCategory);
  };

  const showResponseMessage = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
    }
  }

  const deleteHandler = (userId) => {
    dispatch(setModalLoader(true))
    dispatch(deleteCategory(userId)).unwrap().then((res) => {
      showError(res?.message, 'success')
      dispatch(closeCustomModalDialog())
      dispatch(getAllCategory())
    }).catch((err) => {
      dispatch(setModalLoader(false))
      showError(err?.message, 'error')
    })
  }

  const showError = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
    }
  }

  const handleConfirmation = (id) => {
    dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: id, deleteType: 'category' }, modal: 'DELETE_MODAL' }))

  }

  const handleSubmit = () => {
    let newOrderedCat = [...category].map((v, ind) => {
      return { ...v, order: ind + 1 };
    });

    newOrderedCat = newOrderedCat.map((cat) => {
      if (cat.categoryName === 'Deals') {
        let newData = cat.blazeCategories.map((subCat) => {
          let subCatName = subCat.name;
          let subCatId = subCat._id || uuidv4();

          if (subCatName.includes(',')) {
            let subCatNames = subCatName.split(',').map((name) => name.trim());
            return subCatNames.map((name) => ({ _id: uuidv4(), name }));
          } else {
            return { _id: subCatId, name: subCatName };
          }
        });

        newData = newData.flat();

        return { ...cat, blazeCategories: newData };
      }

      return cat;
    });

    dispatch(updateCategories(newOrderedCat)).unwrap().then(res => {
      showResponseMessage(res?.message, 'success')
      dispatch(getAllCategory())

    }).catch(err => {
      showResponseMessage(err?.message, 'error')
    });
  };


  const onUpdateCategoryOrder = (updatedCategory) => {
    setCategory(updatedCategory)
  }

  const handleEditCategory = (item) => {
    dispatch(setCategoryInformation(item))
    navigate('/hyperwolf/manage-categories/add')
  }

  const updatedOptionsValues = useMemo(() => {
    let updatedOptions = [...(blazeCategoryArray || [])]
    let selectedBlazeCategory = []
    category && category.length && category.map((v) => selectedBlazeCategory.push(v.blazeCategories))
    selectedBlazeCategory = selectedBlazeCategory.flat(1)

    selectedBlazeCategory = selectedBlazeCategory.flat(1)
    var array3 = updatedOptions.filter((obj) => {
      return selectedBlazeCategory.findIndex((fnd) => {
        return (fnd.id == obj.id)
      }) == -1;
    });

    let updatedOptionsValues = [...array3]
    return updatedOptionsValues
  }, [blazeCategoryArray, category])

  return (
    <CommonLayout>
      <Box p={{ xs: 2, sm: 3, lg: 4 }}>
        <Grid container component="main" className={` manage-category-page-container`}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} className="add-category-btn">
                <Box textAlign='end' my={2}>
                  <Button
                    onClick={() => { navigate('/hyperwolf/manage-categories/add') }}
                    variant="contained"
                    color="primary"
                  >
                    Add Category
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {Array.isArray(category) && category.length ?
            <Grid item xs={12} sm={12} md={12} lg={12} className="m-a">
              <Paper>
                <Box p={4}>
                  <DraggableList
                    category={category}
                    values={updatedOptionsValues}
                    onUpdateCategoryOrder={onUpdateCategoryOrder}
                    handleEditCategory={handleEditCategory}
                    handleDeleteCategory={handleConfirmation}
                    handleChangeSubCategory={handleChangeSubCategory}
                  />
                  <div className="save-button-div">
                    <Button
                      onClick={() => { handleSubmit() }}
                      variant="contained"
                      color="primary"
                      disabled={isLoading}
                    >Save</Button>
                  </div>
                </Box>
              </Paper>
            </Grid>
            :
            <Grid item xs={12}>
              {isLoading ? <CommonLoading isLoading={isLoading} /> : <NoDataFound />}
            </Grid>}
        </Grid>
      </Box>

    </CommonLayout>
  )
}