import React, { useEffect,  useState } from 'react'
import { Grid, TextField, Card, Typography,  Box, Stack,  Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import MDButton from 'components/MDButton';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { isEmptyObject } from 'utilities/common';
import CommonLayout from 'layouts/DashboardLayout';
import { openSnackbar } from '../../../redux/slices/common';
import validateCannabinoids from 'validations/cannabinoids';
import { createCannabinoid, getCannabinoidsList, updateCannabinoid } from '../../../redux/slices/cannabinoids';

export default function AddProductCannabinoid() {
  const { singleCannabinoid = {}, isLoading = false, cannabinoidList = {} } = useSelector(state => state.cannabinoids)
  const { cannabinoidId = "" } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    "status": true,
    "createdBy": "admin",
    "cannabinoidName": "CBDX",
    "listImage": "https://hemp-website-assets.s3.amazonaws.com/assets/commonFiles/1368198-17242239007851725.jpeg",
    "detailImage": "https://hemp-website-assets.s3.amazonaws.com/assets/commonFiles/wallpaperflare.com_wallpaper%20%284%29-17242239130181739.jpg",
    "_id": "66bdd2fbc8527264427d199a",
    "tags": [
      "hemp",
      "wellness"
    ],
    "cannabinoidId": "1723716347002233",
    "description": "Cannabidiol (CBD) is a chemical compound from the cannabis plant.",
    "image": "https://hemp-website-assets.s3.amazonaws.com/assets/commonFiles/hemp4.jpg",
    "metaTitle": "<p>asdaddadadadsadadadad</p>",
    "metaDescription": "<p>adadad</p>",
    "createdDate": 1723716347008,
    "canonical": "https://hyperwolf.thcs.in/shop/cannabinoid/cbdx",
    "updatedDate": 1723723671498,
    "title": "<p>fsfdgfddgsfhgdhsfdsgfhgfdgfdgfd</p>",
    "description1": "<p>dfggfddfggfdfdgsgdfgdfsdgfgfds</p>",
    "description2": "<p>dgsfgfdsgfdgdfgdfgdfsgfdsgdfsgfds</p>"
  })

  const handleSubmit = (e) => {
    e.preventDefault();
    const { isValid, errors } = validateCannabinoids(formData)
    if (!isValid) {
      return;
    }
    const payload = { ...formData }
    delete formData?._id
    delete formData?.cannabinoidId
    delete formData?.image
    delete formData?.createdDate
    delete formData?.updatedDate
    if (cannabinoidId) {
      dispatch(updateCannabinoid({ data: formData, id: cannabinoidId })).unwrap().then((res) => {
        showResponseMessage(res?.message, 'success')
        navigate('/manage-cannabinoids?cannabinoidTab=cannabinoids')
      }).catch((err) => {
        showResponseMessage(err?.message, 'error')
      })
    } else {
      dispatch(createCannabinoid(formData)).unwrap().then((res) => {
        showResponseMessage(res?.message, 'success')
        navigate('/manage-cannabinoids?cannabinoidTab=cannabinoids')
      }).catch((err) => {
        showResponseMessage(err?.message, 'error')
      })
    }

  };

  const showResponseMessage = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
    }
  }

  useEffect(() => {
    dispatch(getCannabinoidsList({ limit: 1000, skip: 0 }))
  }, [])

  useEffect(() => {
    if (!isEmptyObject(singleCannabinoid)) {
      setFormData({ ...formData, ...singleCannabinoid })
    }
  }, [singleCannabinoid])

  return (
    <CommonLayout>
      <Box p={{ xs: 2, sm: 3, lg: 4 }}>
        <Card sx={{ p: { xs: 2, md: 3 }, mb: 2, borderRadius: 2.5, boxShadow: "none" }}>
          <Stack direction={{ xs: "column", sm: "row" }} alignItems={{ sm: "center" }} justifyContent="space-between" gap={2} mb={4.5}>
            <Typography variant='h4' fontWeight="semibold" color="secondary">
              {cannabinoidId ? 'Edit' : "Add"} Cannabinoids
            </Typography>

            <Box display="flex" alignItems="center" gap={1.25}>
              <Typography variant='h5' fontWeight="semibold" color="secondary">Status</Typography>
              <Stack direction="row" alignItems="center" gap={.75} lineHeight={1} p={.75} borderRadius={2} bgcolor="#F2F3F7">
                <MDButton
                  sx={{ minWidth: "92px", borderRadius: 1.75, minHeight: "28px", p: .25, fontWeight: 500 }}
                  type='button'
                  variant={formData?.status ? 'contained' : 'text'}
                  color={formData?.status ? 'success' : 'dark'}
                  size="small"
                  onClick={() => setFormData({ ...formData, status: true })}
                >
                  Active
                </MDButton>
                <MDButton
                  sx={{ minWidth: "92px", borderRadius: 1.75, minHeight: "28px", p: .25, fontWeight: 500 }}
                  type='button'
                  variant={!formData?.status ? 'contained' : 'text'}
                  color={!formData?.status ? 'error' : 'dark'}
                  size="small"
                  onClick={() => setFormData({ ...formData, status: false })}
                >
                  Inactive
                </MDButton>
              </Stack>
            </Box>
          </Stack>

          <Grid container spacing={2.5} mb={4}>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth>
                <InputLabel>Cannabinoids Name</InputLabel>
                <Select label='Cannabinoids Name' variant='outlined' onChange={(e) => {
                  setFormData({ ...formData, cannabinoidName: e.target.value, ...(cannabinoidList?.cannabinoids?.find((cannabinoid => cannabinoid?.cannabinoidName === e.target.value))) || {} })
                }} value={formData?.cannabinoidName || ''} name='cannabinoidName' fullWidth className='select-arrow'>
                  {
                    cannabinoidList?.cannabinoids?.map(((cannabinoid, cannabinoidIdx) => {
                      return <MenuItem key={cannabinoidIdx} value={cannabinoid?.cannabinoidName}>{cannabinoid?.cannabinoidName}</MenuItem>
                    }))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Created by"
                fullWidth
                variant="outlined"
                name="createdBy"
                disabled
                value={formData?.createdBy}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3.5}>
          </Grid>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={1.25} mt={4} mb={2}>
            <MDButton
              sx={{ minWidth: "140px" }}
              type='submit'
              variant='contained'
              color='primary'
              onClick={handleSubmit}
              loading={isLoading}
            >
              Save
            </MDButton>
            <MDButton
              sx={{ minWidth: "140px", backgroundColor: "#F1F1F1", color: "#FD4438" }}
              type='button'
              variant='contained'
              color='error'
            >
              Delete
            </MDButton>
            <MDButton
              sx={{ minWidth: "140px" }}
              type='button'
              variant='outlined'
              color='dark'
            >
              Cancel
            </MDButton>
          </Stack>

        </Card>
      </Box>
    </CommonLayout>
  )
}