import { CloseRounded } from '@mui/icons-material';
import { Box, Button, Grid, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import SearchIcon from 'common/customIcons/search';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDebounce } from 'hooks/useDebounce';
import { AgGridTable } from 'components/tables/AgGridTable';
import { useDispatch } from 'react-redux';
import { getDriverAnalyticsInfo } from '../../../../redux/slices/hyperdrive/driverAnalytics';
import { useSelector } from 'react-redux';
import { formatTimestampToMapOld } from 'utilities/common';
import VisibilityIcon from '@mui/icons-material/Visibility';
import HyperDriveMapDate from 'components/dateRangePicker/hyperDriveMapDate';
import MDButton from 'components/MDButton';
import { openCustomDrawer } from '../../../../redux/slices/modalSlice';
import moment from 'moment';

export default function CheckInOut({ driverId }) {
  const today = new Date();
  const next = moment(today).add(1, 'day').toDate()
  const [dateRange, setDateRange] = useState([today, next]);
  const [startDate, endDate] = dateRange;
  const dispatch = useDispatch()
  const pageRef = useRef(0)
  const searchTermRef = useRef("")
  const mainGridApiRef = useRef('')
  const gridRefApi = useRef(null)
  const [searchTerm, setSearchTerm] = useState("")
  const { driverDetails, loading: loading = false } = useSelector(state => state?.driverAnalyticsHyperDrive)
  const [filterShow, setFilterShow] = useState(false)
  const fromDateRef = useRef('');
  const toDateRef = useRef('');
  fromDateRef.current = startDate ? formatTimestampToMapOld(startDate) : "";
  toDateRef.current = endDate ? formatTimestampToMapOld(endDate) : "";
  
  const columnDefs = useMemo(() => [
    {
      headerName: 'Date',
      field: 'date',
      suppressMenu: false,
      minWidth: 80,
      cellRenderer: (props) => {
        return props?.value ? props?.value : "-";
      },
      flex: 1,
      sortable: false
    },
    {
      headerName: 'Check In',
      field: 'checkIn',
      suppressMenu: false,
      minWidth: 80,
      cellRenderer: (props) => {
        return props?.value ? props?.value : "-";
      },
      flex: 1,
      sortable: false
    },
    {
      headerName: 'Check Out',
      field: 'checkOut',
      suppressMenu: false,
      minWidth: 80,
      cellRenderer: (props) => {
        return props?.value ? props?.value : "-";
      },
      flex: 1,
      sortable: false
    },
    {
      headerName: 'Total Hours',
      field: 'totalHours',
      suppressMenu: false,
      minWidth: 80,
      flex: 1,
      cellRenderer: (props) => {
        return props?.value ? props?.value : "-";
      },
      sortable: false
    },
    {
      headerName: 'Drawer Starting Cash',
      field: 'drawerStartingCash',
      suppressMenu: false,
      minWidth: 80,
      cellRenderer: (props) => {
        return props?.value ? `$${props?.value}` : "$0";
      },
      flex: 0.8,
      sortable: false
    },
    {
      headerName: 'Drawer Total Cash',
      field: 'drawerTotalCash',
      suppressMenu: false,
      minWidth: 80,
      cellRenderer: (props) => {
        return props?.value ? `$${props?.value}` : "$0";
      },
      flex: 0.8,
      sortable: false
    },
    {
      headerName: 'Actions',
      field: 'totalCash',
      suppressMenu: false,
      minWidth: 80,
      flex: 0.8,
      headerClass: "right-aligned-cell-header",
      cellRenderer: (props) => (
        <MDButton
          variant="contained"
          color="light"
          size="small"
          onClick={() => handleCreateRoute(props?.data?.date)}
          className="d-flex align-item-center gap-2 px-3 text-black-400"
          style={{ fontWeight: "normal", textTransform: "capitalize" }}
        >
          <VisibilityIcon />
          <span>View </span>
        </MDButton>
      ),
      cellStyle: { textAlign: "right", justifyContent: "end" },
      headerClass: "right-aligned-cell-header",
      flex: 1,
      sortable: false
    },
  ], []);

  const driverList = useMemo(() => {
    return driverDetails?.data?.checkList || [];
  }, [driverDetails?.data?.checkList]);

  const handleCreateRoute = (date) => {
    const data = { date: date, fleetId: driverId, type: "checkIn" }
    dispatch(openCustomDrawer({
      drawer: 'CHECKIN_DETAIL',
      drawerData: data,
    }))
  }
  const getRows = useCallback(async (params, apply) => {
    gridRefApi.current = params;
    try {
      const res = await dispatch(getDriverAnalyticsInfo({
        driverId,
        data: {
          analyticType: "checklist",
          skip: pageRef.current,
          limit: 20,
          search: searchTermRef.current,
          // from: (fromDateRef.current && toDateRef.current) && fromDateRef.current ? fromDateRef.current : "",
          // to: (fromDateRef.current && toDateRef.current) && toDateRef.current ? toDateRef.current : "",
        }
      })).unwrap();

      const { checkList, totalCheckList } = res?.data;

      const driverListArray = checkList?.map(item => ({
        ...item,
      }));

      params.successCallback(driverListArray, totalCheckList);
      pageRef.current += 20;
    } catch {
      params.successCallback([], 0);
    }
  }, [dispatch]);

  const searchFn = useCallback((apply) => {
    const dataSource = {
      getRows: (params) => getRows(params, apply)
    };
    if (mainGridApiRef.current) {
      mainGridApiRef.current.ensureIndexVisible(0, null);
      mainGridApiRef.current.setGridOption('datasource', dataSource);
    }
  }, [getRows]);

  const handleSearch = useDebounce((apply) => {
    pageRef.current = 0;
    searchFn(apply);
  }, 500);

  const searchHandler = useCallback((searchValue) => {
    setSearchTerm(searchValue);
    searchTermRef.current = searchValue;
    handleSearch("apply");
  }, [handleSearch]);

  const clearAllHandler = useCallback(() => {
    searchFn();
    pageRef.current = 0;
    toDateRef.current = "";
    fromDateRef.current = "";
    setDateRange([null, null])
  }, [searchFn]);

  useEffect(() => {
    if (mainGridApiRef.current) {
      if (loading) {
        mainGridApiRef.current.showLoadingOverlay();
      } else if (driverDetails?.data?.totalCheckList === 0) {
        mainGridApiRef.current.showNoRowsOverlay();
      } else {
        mainGridApiRef.current.hideOverlay();
      }
    }
  }, [driverList, loading]);

  const applyHandler = () => {
    pageRef.current = 0;
    if (toDateRef.current && fromDateRef.current) {
      searchFn("apply");
    }
  }

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between" my={2}>
        <Typography variant='h4' className='text-20 text-white-700'>Check In / Check Out</Typography>
        <Stack direction="row" justifyContent={{ xs: "start", sm: "end" }} flexWrap="wrap" alignItems="center" gap={1.5} className="hd-search-filter">
          <TextField autoComplete="off" className="custom-search-field dark-field" placeholder="Search" label=""
            value={searchTerm} onChange={(e) => searchHandler(e.target.value)}
            InputProps={{
              startAdornment: <InputAdornment position="start">
                <IconButton size='small'>
                  <SearchIcon width='18px' height='18px' />
                </IconButton>
              </InputAdornment>,
              endAdornment: searchTerm?.length > 0 ? <InputAdornment>
                <IconButton onClick={() => searchHandler('')} size='small'>
                  <CloseRounded />
                </IconButton>
              </InputAdornment> : null
            }}
          />
          {/* <Box className="filter-btns">
            <Stack direction='row' alignItems="center" gap={1} className='w-100'>
              <Button variant="outlined" color="primary"
                className={filterShow ? "hd-filter-dropdown d-flex align-item-center gap-2 active-filter" : "hd-filter-dropdown d-flex align-item-center gap-2"}
                onClick={() => setFilterShow(!filterShow)} >
                <span>Filter</span>
                <ArrowDropDownIcon />
              </Button>
            </Stack>
          </Box> */}
        </Stack>
      </Box>
      {
        filterShow &&
        <Box className="cus-filter-sec" mb={1}>
          <Grid className='filter-box' container alignItems="center" spacing={1.5}>
            <Grid item xs={12} sm={12} md={12} xxl={12}>
              <Box className="filter-price-unit">
                <Stack direction='row' justifyContent='flex-end' width='100%' gap={2} >
                  <Grid item xs={12} sm={12} md={12} xxl={12}>
                    <Box display="flex" alignItems="center" justifyContent='flex-end' gap={2} className="w-100">
                      <Box className="filter-pro-status hd-date-field dark-field dark-calendar">
                        <Stack direction='row' alignItems="center">
                          <HyperDriveMapDate setDateRange={setDateRange} dateRange={dateRange} showIcon={false} />
                        </Stack>
                      </Box>
                      <Box className="filter-btns">
                        <Stack direction='row' alignItems="center" gap={1} className='w-100'>
                          <Button className="filter-clear-all" onClick={clearAllHandler} size="small" variant="text" color="secondary" style={{ width: "100px" }}>
                            Clear All
                          </Button>
                          <Button className="filter-apply" onClick={() => applyHandler()} size="small" variant="contained" color="primary" style={{ width: "120px" }}>
                            Apply
                          </Button>
                        </Stack>
                      </Box>
                    </Box>
                  </Grid>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
      }
      <div className="ag-theme-quartz-dark">
        <AgGridTable
          col={columnDefs}
          mainGridApiRef={mainGridApiRef}
          getRows={getRows}
          pageRef={pageRef}
          isLoading={loading}
          style={{ height: "70vh" }}
          gridOptions={{ rowHeight: 64, }}
          maxLimit={20}
        />
      </div>
    </Box>
  )
}