import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Grid, Icon,  Paper, Stack, Tooltip, Typography } from '@mui/material'
import { AgGridTable } from 'components/tables/AgGridTable'
import {  useNavigate } from 'react-router-dom'
import { formatDate } from 'utilities/common'
import Search from 'examples/Search'
import { useDebounce } from 'hooks/useDebounce'
import StatusText from 'common/component/StatusText'
import StaticsOverview from 'common/StaticsOverview'
import InActiveIcon from 'common/customIcons/inactive'
import ActiveIcon from 'common/customIcons/active'
import { addSpaceBeforeCapital } from 'utilities/common'
import { formatUsPhoneNumber } from 'utilities/common'
import VerifyIcon from 'common/customIcons/verify'
import NonVerifyIcon from 'common/customIcons/non_verify'
import MembersIcon from 'common/customIcons/members'
import { fetchRetailerMembers } from '../../../redux/slices/retailer/member'
import FilterMembers from 'common/CommonDrawer/members/FilterMembers'
import { capitalizeText } from 'utilities/common'

export default function RetailerMembers({retailerId ="",}) {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const pageRef = useRef(0)
  const searchTermRef = useRef("")
  const memberPlatformRef = useRef("")

  const mainGridApiRef = useRef('')
  const gridRefApi = useRef(null)
  const [searchTerm, setSearchTerm] = useState("")
  const [selectedTab, setSelectedTab] = useState('all')
  const queryParams = new URLSearchParams(location.search);
  const filterParam = queryParams.get('memberFilter');
  const membersFiltersPayload = useRef({})

  useEffect(() => {
    setSelectedTab(filterParam || '')
  }, [filterParam])


  const handleSearch = useDebounce((term) => {
    // Perform search operation with the debounced term
    pageRef.current = 0
    searchFn()
  }, 500);

  const { members = {}, loading = false } = useSelector(state => state.retailerMembersSlice)
  const { activeMembersCount = 0, inactiveMembersCount = 0, totalMembers = 0 } = members || {}


  const applyMemberFilter = (filterPayload) => {
    pageRef.current = 0
    membersFiltersPayload.current = filterPayload
    const dataSource = {
      getRows: (params) => {
        const page = params.endRow / 20;
        dispatch(fetchRetailerMembers({ filters: { skip: 0, searchTerm: searchTermRef.current, ...filterPayload?.queryPayload }, retailerId }))
          .unwrap().then(res => {
            const { members, total } = res
            const membersArray = members?.map((item => {
              const date = item?.createdDate ? new Date(item?.createdDate) : ""
              return { ...item, status: item?.status ? "Active" : "Inactive", joinedDate: date ? formatDate(date) : "-", id: item.memberId, address: `${item?.deliveryAddress?.address} ${item?.deliveryAddress?.city} ${item?.deliveryAddress?.state} ${item?.deliveryAddress?.zipcode}` }
            }))

            params.successCallback([...membersArray], total);
            pageRef.current = pageRef.current + 20
          })
      }
    }
    if(mainGridApiRef?.current) {
      mainGridApiRef.current.ensureIndexVisible(0, null);
      mainGridApiRef.current.setGridOption('datasource', dataSource);
    }

  }

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: 'Full Name',
      field: 'fullName',
      key: "fullName",
      suppressMenu: false,
      cellRenderer: (props) => {
        return <Typography variant="caption" display="block" fontWeight="regular" sx={{ cursor: "pointer", whiteSpace: "normal" ,textTransform:'capitalize'}}>{props?.data?.fullName}</Typography>
      },
      minWidth: 240,
    },
    {
      headerName: 'Email',
      field: 'email',
      suppressMenu: false,
      minWidth: 240,
    },
    {
      headerName: 'Phone',
      field: 'phone',
      suppressMenu: false,
      minWidth: 130,
      cellRenderer: (props) => {
        return props?.value ? formatUsPhoneNumber(props?.value) : "-"
      },
    },
    {
      headerName: 'User Verified',
      field: 'isVerified',
      suppressMenu: false,
      minWidth: 110,
      cellRenderer: (props) => {
        if (!props?.data?.id) return;
        return props?.value ? <Box display="flex" justifyContent="center"><Icon sx={{ display: "inline-flex" }} fontSize="small" color='primary'>
          <VerifyIcon />
        </Icon></Box> : <Box display="flex" justifyContent="center"><Icon fontSize="small" color='error' sx={{ opacity: .75 }}>
          <NonVerifyIcon />
        </Icon></Box>
      },
      cellStyle: { textAlign: 'center', justifyContent: 'center' },
      headerClass: 'center-aligned-cell-header',
    },
    {
      headerName: 'Available',
      field: 'memberPlatform',
      suppressMenu: false,
      minWidth: 130,
      cellRenderer: (props) => {
        if (Array.isArray(props.value)) {
            return props.value?.map(item => capitalizeText(item)).join(', ');
        }
        return capitalizeText(props.value);
    }
    },
    {
      headerName: 'Address',
      field: 'address',
      suppressMenu: false,
      cellRenderer: (props) => {
        return <Tooltip title={props?.value} arrow placement="top">
            <Typography className="truncate line-2" variant="caption" display="block" fontWeight="regular" sx={{ whiteSpace: "normal" }}>{props?.value}</Typography>
        </Tooltip>
    },
      minWidth: 440,
    },
    {
      headerName: 'Gender',
      field: 'gender',
      suppressMenu: false,
      minWidth: 80,
      cellRenderer: (props) => {
        return props?.value ? <span style={{textTransform:'capitalize'}}>{props?.value?.toLowerCase()}</span> : "-"
      },
    },
    {
      headerName: 'Status',
      field: 'status',
      suppressMenu: false,
      cellRenderer: (props) => {
        return <StatusText status={props?.data?.status} />
      },
      minWidth: 100
    },
    {
      headerName: 'Member Type',
      field: 'memberType',
      suppressMenu: false,
      minWidth: 120,
      cellRenderer: (props) => {
        return props?.value ? addSpaceBeforeCapital(props?.value) : "-"
      },
      minWidth: 120
    },
    {
      headerName: 'Joined Date',
      field: 'joinedDate',
      suppressMenu: false,
      minWidth: 150
    },
    ]);

  function getSortInfo(data) {
    const result = {};
    data.forEach(item => {
      if(item?.colId ==='memberType' ){
        result[item.colId] = item.sort === "asc" ? 'AdultUse' :"MedicinalUser";
      }else{
        result[item.colId] = item.sort === "asc";
      }
    
    });

    return result;
  }

  const getRows = async (params) => {
    gridRefApi.current = params;
    let filter = {  }
    if (params?.sortModel.length) {
      filter = { ...getSortInfo(params?.sortModel) }
    }
    dispatch(fetchRetailerMembers( { filters: { skip: 0,searchTerm: searchTermRef.current ,memberPlatform:memberPlatformRef.current} , retailerId} )).unwrap().then(res => {
      const { members, total } = res
      const membersArray = members?.map((item => {
        const date = item?.createdDate ? new Date(item?.createdDate) : ""
        return { ...item, status: item?.status ? "Active" : "Inactive", joinedDate: date ? formatDate(date) : "-", id: item.memberId, address: `${item?.deliveryAddress?.address} ${item?.deliveryAddress?.city} ${item?.deliveryAddress?.state} ${item?.deliveryAddress?.zipcode}` }
      }))

      params.successCallback([...membersArray], total);
      pageRef.current = pageRef.current + 20
    })
  }

  const searchFn = () => {
    const dataSource = {
      getRows
    }
    mainGridApiRef.current.ensureIndexVisible(0, null);
    mainGridApiRef.current.setGridOption('datasource', dataSource);
  }

  const onRowClicked = (row) => {
    if (row?.colDef?.headerName !== 'Actions') {
      navigate(`/retailers/detail/${retailerId}/members/${row?.data.memberId}?filter=retailerMembers`)
    }
  }

  const searchHandler = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    searchTermRef.current = value
    handleSearch(value);
  };

  useEffect(() => {
    if (mainGridApiRef?.current) {
      if (loading) {
        mainGridApiRef.current.showLoadingOverlay()
      } else if (members?.total == 0) {
        mainGridApiRef.current.showNoRowsOverlay()
      }
      else {
        mainGridApiRef.current.hideOverlay()
      }
    }

  }, [members, loading])


  return (
    <> 
        <Paper elevation={0} style={{ borderRadius: "12px" }}>
          <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="start" justifyContent="space-between" bgcolor="white" p={{ xs: 1, md: 2 }}>
            <StaticsOverview
              color="warning"
              iconShadow="iconShadowWarning"
              icon={<MembersIcon />}
              title="Total Members"
              count={totalMembers}
            />
            <StaticsOverview
              color="success"
              iconShadow="iconShadowSuccess"
              icon={<ActiveIcon />}
              title="Active Members"
              count={activeMembersCount}
            />
            <StaticsOverview
              color="error"
              iconShadow="iconShadowDanger"
              icon={<InActiveIcon />}
              title="Inactive Members"
              count={inactiveMembersCount}
            />
          </Stack>
        </Paper>

        <Grid container alignItems="center" rowSpacing={2} columnSpacing={2} mt={1} mb={3}>
          <Grid item xs={12} sm={4}>
            <Typography variant='h4'>Members</Typography>
          </Grid>

          <Grid item xs={12} sm={8}>
            <Stack direction="row" justifyContent={{ xs: "start", sm: "end" }} flexWrap="wrap" alignItems="stretch" gap={2}>
              <Search placeholder="Search" label="" value={searchTerm} onChange={searchHandler} />
                      </Stack>
          </Grid>
        </Grid>

        <FilterMembers applyMemberFilter={applyMemberFilter}/>
        <AgGridTable col={columnDefs} getRows={getRows} mainGridApiRef={mainGridApiRef} pageRef={pageRef} isLoading={loading} onRowClicked={onRowClicked} style={{ height: "58vh" }} />
      </>
  )
}