import { Box, List, ListItem, Typography } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { getDriverAnalyticsInfo } from '../../../redux/slices/hyperdrive/driverAnalytics';
import { useDispatch } from 'react-redux';
import noDataFound from 'assets/images/nothing-found.svg';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { addCommas } from 'utilities/common';
import Loader from 'common/Loader';
import { formatTimestampToWithoutTimeToday } from 'utilities/common';

const DriverCheckInOut = ({ driverId }) => {
    const dispatch = useDispatch()
    const [posts, setPosts] = useState([]);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const observer = useRef();

    // Load more posts when needed
    const loadMorePosts = useCallback(async (driverId) => {
        // Set loading state to true when starting to load posts
        setLoading(true);
        try {
            const limit = 20;// Number of posts to fetch per request
            const skip = page * limit;// Calculate how many posts to skip based on current page
            // Dispatch action to fetch driver analytics info
            const res = await dispatch(getDriverAnalyticsInfo({
                driverId,
                data: {
                    analyticType: "checklist",// Specify the type of analytics
                    skip, // Number of posts to skip
                    limit: 20// Limit the number of posts fetched
                }
            })).unwrap();// Unwrap the response to handle fulfilled or rejected states
            let newPosts = res?.data?.checkList  // Extract new posts from the response
            // Check if new posts were returned
            if (newPosts?.length === 0) {
                setHasMore(false);// No more posts to load
            } else {
                setHasMore(false);
                setPosts((prevPosts) => [...prevPosts, ...newPosts]);// Update the posts state with the new posts
            }
        } catch (err) {
            // Handle errors by setting hasMore to false
            setHasMore(false);
            setLoading(false);
        } finally {
            // Ensure loading state is false after the operation
            setHasMore(false);
            setLoading(false);
        }
    }, [dispatch, page]);// Dependencies for the useCallback hook

    // Effect to load more posts when hasMore is true
    useEffect(() => {
        if (hasMore) {
            loadMorePosts(driverId);// Load posts if more are available
        }
    }, [loadMorePosts, hasMore]);// Dependencies for the useEffect hook

    // Effect to reset posts when the driverId changes
    useEffect(() => {
        setPage(0); // Reset page to the first page
        setPosts([]); // Clear current posts
        setHasMore(true); // Set hasMore to true to allow loading of new posts
    }, [driverId]); // Dependencies for the useEffect hook    

    // Ref callback for the last post element
    const lastPostElementRef = useCallback(
        (node) => {
            // Do nothing if currently loading or no more posts to load
            if (loading || !hasMore) return;
            // Disconnect previous observer if it exists
            if (observer.current) observer.current.disconnect();
            // Create a new Intersection Observer
            observer.current = new IntersectionObserver((entries) => {
                // Check if the last post is in view
                if (entries[0].isIntersecting) {
                    setPage((prevPage) => prevPage + 1);// Increment the page to load more posts
                }
            });
            // If the node exists, observe it for intersection
            if (node) observer.current.observe(node);
        },
        [loading, hasMore] // Dependencies for the useCallback hook
    );

    return (
        <List className="d-flex flex-column gap-3">
            {
                loading ? <Box className='loader-multi-drawer'>
                    <div className='d-flex align-item-center jc-center h-100'><Loader /></div></Box> :
                    <>
                        {posts?.length === 0 ?
                            <Box textAlign="center" className="w-100 d-flex align-item-center jc-center flex-column gap-2 no-data-found loader-multi-drawer">
                                <img style={{ maxWidth: "120px" }} src={noDataFound} alt="No Results Found" />
                                <p className='text-12'><strong>No Results Found</strong></p>
                            </Box>
                            :
                            <>   {
                                posts?.map((data, idx) => {
                                    return (
                                        <ListItem ref={posts?.length === idx + 1 ? lastPostElementRef : null}
                                            key={data?._id} className="d-flex flex-column p-3 bg-grey-900 border-radius-8 border-none gap-3 cur-pointer">
                                            <div className="w-100 d-flex flex-column ">
                                                <Typography variant='h4' className='text-white-700 text-16 f-wrap line-height-normal'>
                                                    {formatTimestampToWithoutTimeToday(data?.date)}
                                                </Typography>
                                                <div className="w-100 d-flex align-item-star my-2 gap-3">
                                                    <div className="w-100 d-flex align-item-star gap-2 border-radius-10 bg-black-800 pl-2 pr-3 py-2">
                                                        <AccessTimeIcon className="text-purple-100 text-24" />
                                                        <div className="w-100 d-flex flex-column align-item-center jc-center gap-1">
                                                            <div className="w-100 d-flex align-item-center jc-s-btwn gap-1">
                                                                <Typography className="text-black-200 text-12 lh-normal">Check In</Typography>
                                                                <Typography className="text-black-500 text-12 lh-normal">{data?.checkIn ? data?.checkIn : "-"}</Typography>
                                                            </div>
                                                            <div className="w-100 d-flex align-item-center jc-s-btwn gap-1">
                                                                <Typography className="text-black-200 text-12 lh-normal">Check Out</Typography>
                                                                <Typography className="text-black-500 text-12 lh-normal">{data?.checkOut ? data?.checkOut : "-"}</Typography>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="w-100 d-flex align-item-star gap-2 border-radius-10 bg-black-800 pl-2 pr-3 py-2">
                                                        <PaidOutlinedIcon className="text-yellow-100 text-24" />
                                                        <div className="w-100 d-flex flex-column align-item-center jc-center gap-1">
                                                            <div className="w-100 d-flex align-item-center jc-s-btwn gap-1">
                                                                <Typography className="text-black-200 text-12 lh-normal">Starting Cash</Typography>
                                                                <Typography className="text-black-500 text-12 lh-normal">${data?.drawerStartingCash ? addCommas(data?.drawerStartingCash) : 0}</Typography>
                                                            </div>
                                                            <div className="w-100 d-flex align-item-center jc-s-btwn gap-1">
                                                                <Typography className="text-black-200 text-12 lh-normal">Total Cash</Typography>
                                                                <Typography className="text-black-500 text-12 lh-normal">${data?.drawerTotalCash ? addCommas(data?.drawerTotalCash) : 0}</Typography>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Typography className="text-black-400 font-w-600 text-14">Total Mileage</Typography>
                                                <div className="w-100 d-flex gap-3">
                                                    <Typography className="text-black-400 text-14">
                                                        Miles Travel
                                                        <span className="text-white-900 font-w-600 text-16 ml-2">{data?.totalMiles ? data?.totalMiles : "-"}</span>
                                                    </Typography>
                                                    <Typography className="text-black-400 text-14">
                                                        Time Spent
                                                        <span className="text-white-900 font-w-600 text-16 ml-2">{data?.totalHours ? data?.totalHours : "-"}</span>
                                                    </Typography>
                                                </div>
                                            </div>
                                        </ListItem>
                                    )
                                })
                            }
                            </>
                        }
                    </>
            }
        </List>
    )
}

export default DriverCheckInOut