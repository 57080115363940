import React from 'react'

export default function ArchiveIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <g clipPath="url(#clip0_5674_67037)">
        <path fillRule="evenodd" clipRule="evenodd" d="M0 3.99109C0 3.67319 0.108192 3.36476 0.306782 3.11652L2.16949 0.788144C2.56801 0.289989 3.17136 0 3.80931 0H10.1907C10.8286 0 11.432 0.289989 11.8305 0.788137L13.6932 3.11652C13.8918 3.36476 14 3.67319 14 3.99109V11.9C14 13.0598 13.0598 14 11.9 14H2.1C0.940205 14 0 13.0598 0 11.9V3.99109ZM3.80931 1.4H10.1907C10.4033 1.4 10.6044 1.49666 10.7373 1.66271L11.9797 3.21568C12.0714 3.33026 11.9897 3.5 11.843 3.5H2.15699C2.01025 3.5 1.92867 3.33026 2.02033 3.21568L3.26271 1.66272C3.39555 1.49666 3.59666 1.4 3.80931 1.4ZM7.7014 6.29972C7.7014 5.91311 7.38794 5.59971 7.0014 5.59971C6.61479 5.59971 6.3014 5.91311 6.3014 6.29972V9.86013L5.74497 9.3037C5.47161 9.03035 5.02839 9.03035 4.75502 9.3037C4.48166 9.57712 4.48166 10.0203 4.75502 10.2937L6.01139 11.55C6.55809 12.0968 7.4445 12.0968 7.99127 11.55L9.24497 10.2964C9.51832 10.023 9.51832 9.57978 9.24497 9.30636C8.97162 9.03301 8.52838 9.03301 8.25503 9.30636L7.7014 9.86006V6.29972Z" fill="black"/>
      </g>
      <defs>
        <clipPath id="clip0_5674_67037">
          <rect width="14" height="14" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}