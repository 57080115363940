import { Box, CircularProgress, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material'
import MDButton from 'components/MDButton'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close';

export default function WarrantyReason({ closeModal }) {
    const { modalData } = useSelector(state => state.modal)
    const { isLoading: submittingOrder = false, isUpdatingStatus = false } = useSelector(state => state.orders)

    const [warrantyReason, setWarrantyReason] = useState("")
    const [errors, setErrors] = useState({})
    const { orderId, status, warrantyStatusHanlder, returnItems } = modalData || {}

    const submitHandler = () => {
        if (!warrantyReason) {
            setErrors({ warrantyReason: 'Reason is required' })
            return;
        }
        setErrors({})
        warrantyStatusHanlder(status, orderId, warrantyReason, returnItems)
     }
    return (
        <>
            <DialogTitle id="draggable-dialog-title" display="flex" alignItems="center" justifyContent="space-between">
                {status ? 'Approval' : 'Rejection'} Reason
                <IconButton onClick={closeModal} edge='end' size='small'>
                    <CloseIcon fontSize='medium' />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                <Box pt={1}>
                    <TextField fullWidth className="form-input" multiline rows={3}
                        error={Boolean(errors?.warrantyReason)}
                        helperText={errors?.warrantyReason}
                        onChange={(e) => setWarrantyReason(e.target.value)} label={`${status ? 'Approval' : 'Rejection'} Reason`} name="warrantyReason" value={warrantyReason}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <MDButton onClick={closeModal} size="small" color="secondary">
                    Cancel
                </MDButton>
                <MDButton disabled={submittingOrder || isUpdatingStatus} onClick={submitHandler} size="small" color='primary'>
                    {
                        submittingOrder || isUpdatingStatus ? <CircularProgress size={16} color='light' /> : "Save"
                    }

                </MDButton>
            </DialogActions>
        </>
    )
}