import React from 'react'

export default function OnTimeIcon() {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_13222_26504)">
    <path d="M5.00003 8.81916C2.89065 8.81916 1.18065 7.10924 1.18065 4.99986C1.18065 2.89049 2.89065 1.18049 5.00003 1.18049C7.10948 1.18049 8.81941 2.89049 8.81941 4.99986C8.81941 5.15416 8.94464 5.27932 9.09886 5.27932C9.25308 5.27932 9.37823 5.15408 9.37823 4.99986C9.37823 2.58189 7.418 0.621582 5.00003 0.621582C2.58206 0.621582 0.621826 2.58189 0.621826 4.99986C0.621826 7.41783 2.58206 9.37814 5.00003 9.37814C5.15433 9.37814 5.27948 9.25291 5.27948 9.09869C5.27948 8.94447 5.15433 8.81916 5.00003 8.81916Z" fill="#24CA49"/>
    <path d="M4.72047 2.01879V5.09277H3.13672C2.98258 5.09277 2.85742 5.21801 2.85742 5.37223C2.85742 5.52668 2.98266 5.65184 3.13672 5.65184H4.99992C5.15422 5.65184 5.27938 5.5266 5.27938 5.37223V2.01879C5.27938 1.86441 5.15414 1.73926 4.99992 1.73926C4.8457 1.73926 4.72047 1.86449 4.72047 2.01879Z" fill="#24CA49"/>
    <path d="M7.23584 5.09277C6.05248 5.09277 5.09326 6.05199 5.09326 7.23535C5.09326 8.41879 6.0524 9.37801 7.23584 9.37801C8.41912 9.37801 9.37834 8.41887 9.37834 7.23535C9.37834 6.05199 8.4192 5.09277 7.23584 5.09277ZM7.26178 8.18957C7.16873 8.31277 6.99326 8.33715 6.87021 8.2441L6.01568 7.59809C5.89279 7.5052 5.86834 7.3298 5.96154 7.2066C6.05451 7.08355 6.22998 7.05918 6.35287 7.15207L6.98451 7.62957L7.87498 6.45152C7.96803 6.3284 8.14342 6.30402 8.26646 6.39707C8.38951 6.49012 8.41396 6.66551 8.32084 6.78863L7.26178 8.18957Z" fill="#24CA49"/>
    </g>
    <defs>
    <clipPath id="clip0_13222_26504">
    <rect width="10" height="10" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  )
}