import { Box, Card, FormControl, FormHelperText, Grid, Icon,  InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import MDButton from 'components/MDButton'
import { AgGridTable } from 'components/tables/AgGridTable'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { assignInventory,  fetchProductInventories, fetchSingleProduct } from '../../../redux/slices/products'
import { sortAndFilter } from 'components/tables/AgGridTable'
import { openSnackbar } from '../../../redux/slices/common'
import MDBox from 'components/MDBox'
import StocksIcon from 'common/customIcons/stocks'
import AngleRightIcon from 'common/customIcons/angle_right'
import { validateProductInventory } from 'validations/inventory'

export default function InventroyTable({ productId = "",  totalProduct = 0 }) {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { pathname } = useLocation()
    const mainGridApiRef = useRef('')
    const pageRef = useRef(0)
    const gridRefApi = useRef()
    const [inventoriesList, setInventoriesList] = useState([])
    const [errors, setErrors] = useState({})
    const { loading = false, inventories = {} } = useSelector(state => state.products)
    const [inventoryAssignedData, setInventoryAssignedData] = useState({ fromInventory: "", toInventory: "", quantity: "" })
    const isDetailPage = useMemo(() => {
        return pathname.includes('details')
    }, [pathname])
    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: 'Inventory Name',
            field: 'inventoryName',
            key: "fullName",
            suppressMenu: false,
            cellRenderer: (props) => {
                return <Typography variant="caption" lineHeight={1.25} fontWeight="semibold" className="truncate line-1" sx={{ cursor: 'pointer' }}>{props?.data?.inventoryName}</Typography>
            },
            minWidth: 500
        },
        {
            headerName: 'Current Quantity',
            field: 'quantity',
            suppressMenu: false
        },
        {
            headerName: 'On Hold',
            field: 'holdQuantity',
            suppressMenu: false,
            cellRenderer: (props) => {
                return props?.data?.inventoryId === 'odkEgmqfW3MDJJedc3QJ' ? props?.value : '0'
            },
        },
    ]);

    const getRows = async (params) => {
        gridRefApi.current = params;
        dispatch(fetchProductInventories({ filters: { skip: pageRef.current, limit: 100 }, productId })).unwrap().then(res => {
            const { inventory = {}, total = 0, holdQuantity } = res
            const inventories = inventory?.map((item => {
                return { ...item, id: item._id, holdQuantity }
            }))
            setInventoriesList(inventories)
            const dataAfterSortingAndFiltering = sortAndFilter(
                inventories,
                params.sortModel,
                params.filterModel
            );
            const rowsThisPage = dataAfterSortingAndFiltering.slice(
                params.startRow,
                params.endRow
            );
            let lastRow = -1;
            if ((total <= params.endRow)) {
                lastRow = dataAfterSortingAndFiltering.length;
            }
            params.successCallback(dataAfterSortingAndFiltering, lastRow);
            pageRef.current = pageRef.current + 20
        }).catch(() => { })
    }

    const onRowClicked = (row) => {
        navigate(`/inventories/${row.data?.inventoryId}`)
    }

    const inventroyDataChangeHandler = (e) => {
        if (e.target.name === 'quantity' && e.target.value < 0) return;
        setInventoryAssignedData({ ...inventoryAssignedData, [e.target.name]: e.target.value })
    }

    const inventoryAssignandler = () => {
        const { error, isValid } = validateProductInventory(inventoryAssignedData)
        if (!isValid) {
            setErrors(error)
            return;
        }
        setErrors({})
        dispatch(assignInventory({ ...inventoryAssignedData, quantity: +inventoryAssignedData?.quantity, productId: productId })).unwrap().then(() => {
            setInventoryAssignedData({ fromInventory: "", toInventory: "", quantity: "" })
            getRows(gridRefApi.current)
        }).catch((err) => {
            showError(err?.message, 'error')
        })
    }

    const showError = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
    }

    useEffect(() => {
        if (productId) {
            dispatch(fetchSingleProduct(productId))
        }
    }, [productId])

    useEffect(() => {
        if (mainGridApiRef?.current) {
            if (loading) {
                mainGridApiRef.current.showLoadingOverlay()
            } else if (inventories?.total == 0) {
                mainGridApiRef.current.showNoRowsOverlay()
            }
            else {
                mainGridApiRef.current.hideOverlay()
            }
        }

    }, [inventories, loading])

    return (
        <>
            {
                !isDetailPage && <Link to='/products'> 
                <Box display="flex" alignItems="center" color='text.main' lineHeight={1} mb={3.5}>
                    <AngleRightIcon fontSize="14px" />
                    <Typography variant='h6' fontWeight="semibold" ml={.75}>Products</Typography>
                </Box>
                </Link>
            }

            <Grid container alignItems="center" spacing={2.5} mb={4}>
                <Grid item xs={12} md={4} xl={2.5}>
                    <Card variant='productCard' sx={{ p: 2.5 }}>
                        <Typography variant='body2' color="dark" mt={-0.5} mb={1.5}>Stock Remaining</Typography>
                        <Box color="#24CA49" display="flex" alignItems="center" lineHeight={1}>
                            <MDBox
                                bgColor="#0163FF1A"
                                color="#A162F7"
                                borderRadius="10px"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                width="44px"
                                height="44px"
                            >
                                <Icon fontSize="medium" color="inherit">
                                    <StocksIcon />
                                </Icon>
                            </MDBox>
                            <Typography variant='h4' fontWeight="medium" ml={1} lineHeight={1} textTransform="capitalize">{totalProduct}</Typography>
                        </Box>
                    </Card>
                </Grid>
              
                {
                    !isDetailPage &&
                    <Grid item xs={12} md={8} xl={9.5}>
                        <Card variant='productCard' sx={{ p: 2.5 }}>
                            <Typography variant='body2' color="dark" mt={-0.5} mb={1.5}>Inventory Transfer</Typography>
                            <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start' spacing={3}>
                                <FormControl fullWidth>
                                    <InputLabel>From</InputLabel>
                                    <Select
                                        label="From"
                                        className="form-select"
                                        fullWidth
                                        error={Boolean(errors?.fromInventory)}
                                        onChange={inventroyDataChangeHandler}
                                        name='fromInventory'
                                        value={inventoryAssignedData?.fromInventory}
                                    >
                                        {
                                            inventoriesList?.filter((inventory=>inventory?.status==='Active'))?.map((inventory => {
                                                return <MenuItem key={inventory?.inventoryId} value={inventory?.inventoryId}>{inventory?.inventoryName}</MenuItem>
                                            }))
                                        }
                                    </Select>
                                    {
                                        Boolean(errors?.fromInventory) && <FormHelperText error>{errors?.fromInventory}</FormHelperText>
                                    }
                                </FormControl>

                                <FormControl fullWidth>
                                    <InputLabel>To</InputLabel>
                                    <Select
                                        label="To"
                                        className="form-select"
                                        fullWidth
                                        error={Boolean(errors?.toInventory)}
                                        onChange={inventroyDataChangeHandler}
                                        name='toInventory'
                                        value={inventoryAssignedData?.toInventory}
                                    >
                                        {
                                            inventoriesList?.filter((inventory=>inventory?.status==='Active'))?.filter((inventory => inventory.inventoryId !== inventoryAssignedData.fromInventory))?.map((inventory => {
                                                return <MenuItem key={inventory?.inventoryId} value={inventory?.inventoryId}>{inventory?.inventoryName}</MenuItem>
                                            }))
                                        }
                                    </Select>
                                    {
                                        Boolean(errors?.toInventory) && <FormHelperText error>{errors?.toInventory}</FormHelperText>
                                    }
                                </FormControl>

                                <TextField
                                    className="form-input"
                                    fullWidth
                                    onChange={inventroyDataChangeHandler}
                                    placeholder='Quantity'
                                    name='quantity'
                                    value={inventoryAssignedData?.quantity}
                                    type='number'
                                    error={Boolean(errors?.quantity)}
                                    helperText={errors?.quantity}
                                />

                                <MDButton
                                    sx={{ minWidth: "140px" }}
                                    onClick={inventoryAssignandler}
                                    type='button'
                                    variant='contained'
                                    color='primary'
                                >
                                    Transfer
                                </MDButton>
                            </Stack>
                        </Card>
                    </Grid>
                }
            </Grid>

            <AgGridTable col={columnDefs} getRows={getRows} pageRef={pageRef} onRowClicked={onRowClicked} isLoading={loading} mainGridApiRef={mainGridApiRef} />
        </>
    )
}