import React from 'react'

export default function ActivityLogIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
      <g clipPath="url(#clip0_8603_2979)">
          <path fillRule="evenodd" clipRule="evenodd" d="M6.98837 0C4.83342 0 3.16161 0.427144 2.04437 1.54437C0.927144 2.66161 0.5 4.33342 0.5 6.48837V11.5116C0.5 13.6666 0.927144 15.3384 2.04437 16.4556C3.16161 17.5729 4.83342 18 6.98837 18H12.0116C14.1666 18 15.8384 17.5729 16.9556 16.4556C18.0729 15.3384 18.5 13.6666 18.5 11.5116V6.48837C18.5 4.33342 18.0729 2.66161 16.9556 1.54437C15.8384 0.427144 14.1666 0 12.0116 0H6.98837ZM13.9062 7.29986C14.1186 7.02578 14.0687 6.63137 13.7946 6.41891C13.5205 6.20645 13.126 6.25641 12.9136 6.53048L10.9821 9.02218C10.9109 9.1105 10.7763 9.12917 10.6809 9.05667L9.15164 7.85335L9.14729 7.84992C8.50523 7.35379 7.58327 7.47007 7.08604 8.11355L5.09288 10.7013C4.88127 10.9761 4.93244 11.3703 5.20718 11.5819C5.48192 11.7935 5.87618 11.7424 6.08779 11.4677L8.07993 8.88119C8.15168 8.7886 8.28346 8.77076 8.3779 8.84251L9.90714 10.0458L9.91149 10.0493C10.5521 10.5442 11.4705 10.4294 11.9685 9.79953L13.9062 7.29986Z" fill="#F5F5FF"/>
      </g>
      <defs>
          <clipPath id="clip0_8603_2979">
          <rect width="18" height="18" fill="white" transform="translate(0.5)"/>
          </clipPath>
      </defs>
    </svg>
  )
}