import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material'
import { AgGridTable } from 'components/tables/AgGridTable'
import { sortAndFilter } from 'components/tables/AgGridTable'
import { Link, useNavigate } from 'react-router-dom'
import MDButton from 'components/MDButton'
import { formatDate } from 'utilities/common'
import { OpenInNewSharp } from '@mui/icons-material'
import { closeCustomModalDialog, openCustomModalDialog, setModalLoader } from '../../../redux/slices/modalSlice'
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Search from 'examples/Search'
import { useDebounce } from 'hooks/useDebounce'
import EditIcon from 'common/customIcons/edit'
import DeleteIcon from 'common/customIcons/delete'
import StatusText from 'common/component/StatusText'
import { openSnackbar } from '../../../redux/slices/common'
import colors from "assets/theme/base/colors";
import { deleteStrain, getStrainList } from '../../../redux/slices/strain'

export default function StrainTable() {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const pageRef = useRef(0)
  const searchTermRef = useRef("")
  const mainGridApiRef = useRef('')
  const gridRefApi = useRef(null)
  const [searchTerm, setSearchTerm] = useState("")
  const { text } = colors;

  const handleSearch = useDebounce((term) => {
    // Perform search operation with the debounced term
    pageRef.current = 0
    searchFn()
  }, 500);

  const { loading = false, strains = {} } = useSelector(state => state.strain)

  const deleteHandler = (strainId) => {
    dispatch(setModalLoader(true))
    dispatch(deleteStrain(strainId)).unwrap().then((res) => {
      showError(res?.message, 'success')
      dispatch(closeCustomModalDialog())
      pageRef.current = 0
      getRows(gridRefApi.current)
    }).catch((err) => {
      dispatch(setModalLoader(false))
      showError(err?.message, 'error')
    })
  }

  const showError = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
    }
  }

  const linkStyle = {
    color: text.main,
    display: "inline-flex",
    alignItems: "center",
    gap: 4,
  }

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: 'Strain Name',
      field: 'strainName',
      key: "fullName",
      suppressMenu: false,
      maxWidth: 140
    },
    {
      headerName: 'Strain URL',
      field: 'canonical',
      suppressMenu: false,
      minWidth: 500,
      cellRenderer: (props) => {
        return props?.value ? <Link target='_blank' style={linkStyle} to={props?.value}>
          {props?.value}
          <OpenInNewSharp />
        </Link> : "-"
      },
    },
    {
      headerName: 'Meta Title',
      field: 'metaTitle',
      suppressMenu: false,
      cellRenderer: (props) => {
        return <Typography className="truncate line-2" variant="caption" component="div" fontWeight="regular" sx={{ whiteSpace: "normal" }}>
          <div dangerouslySetInnerHTML={{
            __html: props?.data?.metaTitle
          }}></div>
        </Typography>
      },
      minWidth: 220
    },
    {
      headerName: 'Strain',
      field: 'strainCategory',
      suppressMenu: false,
      minWidth: 160,
    },
    {
      headerName: 'Created Date',
      field: 'createdDate',
      suppressMenu: false,
      minWidth: 160,
    },
    {
      headerName: 'Status',
      field: 'status',
      suppressMenu: false,
      cellRenderer: (props) => {
        return props?.data?.strainId && <StatusText status={props?.data?.status ? 'active' : "inactive"} />
      },
      minWidth: 100,
    },
    {
      headerName: 'Action',
      cellRenderer: (props) => {
        return <Box display="inline-flex" alignItems="center">
          <IconButton onClick={(e) => {
            e.stopPropagation()
            onEditClicked(props)
          }} color='primary' size='small' variant="primary">
            <EditIcon width='16px' height='16px' />
          </IconButton>
          <IconButton onClick={(e) => {
            e.stopPropagation()
            dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: props.data?._id }, modal: 'DELETE_MODAL' }))
          }} color='error' size='small' variant="primary" sx={{ marginLeft: 1 }}>
            <DeleteIcon width='16px' height='16px' />
          </IconButton>
        </Box>
      },
      cellStyle: { textAlign: 'right', justifyContent: 'end' },
      headerClass: 'right-aligned-cell-header',
      minWidth: 100,
      maxWidth: 100,
      pinned: 'right'
    },

  ]);

  const getRows = async (params) => {
    gridRefApi.current = params;
    const sortModel = params?.sortModel
    let sortedColumnName = '';
    let sortDirection = ''
    if (sortModel.length > 0) {
      const sortedColumn = sortModel[0];
      sortedColumnName = sortedColumn.colId;
      sortDirection = sortedColumn.sort === 'asc' ? true : false
    }
    dispatch(getStrainList({ limit: 20, skip: pageRef.current, searchTerm: searchTermRef.current, type: 'admin', [sortedColumnName]: sortDirection })).unwrap().then(res => {
      const { strain, total } = res
      const strainArray = strain?.map((item => {
        const date = new Date(item?.createdDate)
        return { ...item, createdDate: formatDate(date), id: item.strainId }
      }))
      const dataAfterSortingAndFiltering = sortAndFilter(
        strainArray,
        params.sortModel,
        params.filterModel
      );
      const rowsThisPage = dataAfterSortingAndFiltering.slice(
        params.startRow,
        params.endRow
      );
      let lastRow = -1;
      if (total <= params.endRow) {
        lastRow = dataAfterSortingAndFiltering.length;
      }
      params.successCallback([...dataAfterSortingAndFiltering], total);
      pageRef.current = pageRef.current + 20
    })
  }

  const searchFn = () => {
    const dataSource = {
      getRows
    }
    mainGridApiRef.current.ensureIndexVisible(0, null);
    mainGridApiRef.current.setGridOption('datasource', dataSource);
  }

  const onRowClicked = (row) => {
    if (row?.colDef?.headerName !== 'Action') {
    }
  }

  const onEditClicked = (row) => {
    navigate(`/strains/${row?.data.subStrainSlug}`)
  }

  const searchHandler = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    searchTermRef.current = value
    handleSearch(value);
  };

  useEffect(() => {
    if (mainGridApiRef?.current) {
      if (loading) {
        mainGridApiRef.current.showLoadingOverlay()
      } else if (strains?.total == 0) {
        mainGridApiRef.current.showNoRowsOverlay()
      }
      else {
        mainGridApiRef.current.hideOverlay()
      }
    }
  }, [strains, loading])

  return (
    <Box p={{ xs: 2, sm: 3, lg: 4 }}>
      <Grid container alignItems="center" rowSpacing={2} columnSpacing={2} mb={3}>
        <Grid item xs={12} sm={4}>
          <Typography variant='h4'>Strains</Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Stack direction="row" justifyContent={{ xs: "start", sm: "end" }} flexWrap="wrap" alignItems="stretch" gap={2}>
            <Search placeholder="Search" label="" onChange={searchHandler} />
            <MDButton
              startIcon={<AddRoundedIcon fontSize='large' />}
              variant="contained" color="primary"
              onClick={() => navigate('/strains/add')} >
              Add Strain
            </MDButton>
          </Stack>
        </Grid>
      </Grid>

      <AgGridTable col={columnDefs} getRows={getRows} mainGridApiRef={mainGridApiRef} pageRef={pageRef} isLoading={loading} onRowClicked={onRowClicked} style={{ height: "71vh" }} />
    </Box>
  )
}