import { Badge, Box,  CircularProgress, DialogActions, DialogContent, DialogTitle, IconButton, Stack, TextField, Tooltip, Typography } from '@mui/material'
import MDButton from 'components/MDButton'
import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close';
import { uploadImage } from '../../../redux/slices/common';
import { useDispatch } from 'react-redux';
import { getImageExtension } from 'utilities/common';

const uploadThumbnailImage = {
    borderRadius: "20px",
    width: "100px",
    height: "100px",
    objectFit: "cover",
    boxShadow: "0 0 1px rgb(0 0 0 / 20%)",
    marginRight: '10px'
};

const circularProgressIcon = {
    backgroundColor: "#fff",
    borderRadius: 10,
    p: .5,
    position: "absolute"
};
export default function BanMemberModal({ closeModal }) {
    const { modalData } = useSelector(state => state.modal)
    const { isLoading: submittingOrder = false } = useSelector(state => state.orders)
    const { isUploading = false } = useSelector(state => state.common)
    const [banMemberData, setBanMemberData] = useState({
        memberNotes: '',
        notesAttachment: []
    })
    const uploadTypeRef = useRef('')
    const dispatch = useDispatch()
    const [errors, setErrors] = useState({})
    const { banMemberSubmitHandler, cancelHandler, memberStatus = '' } = modalData || {}

    const submitHandler = () => {
        if (!banMemberData?.memberNotes) {
            setErrors({ BanReason: 'Reason is required' })
            return;
        }
        setErrors({})
        banMemberSubmitHandler({ ...banMemberData, status: memberStatus === 'active' ? true : false, })
    }

    const attachmentImageUploader = async (e,uploadType) => {
        uploadTypeRef.current = uploadType
        if (e.target.files.length) {
            const file = e.target.files[0];
            const body = new FormData()
            body.append("filePath", file);
            dispatch(uploadImage(body)).unwrap().then((res => {
                setBanMemberData({ ...banMemberData, notesAttachment: [...banMemberData?.notesAttachment, res.fileUrl,] })
            }))
        }
    }
    const closeModalHandler = () => {
        cancelHandler()
        closeModal()
    }

    return (
        <>
            <DialogTitle id="draggable-dialog-title" display="flex" alignItems="center" justifyContent="space-between">
                Ban Member
                <IconButton onClick={closeModalHandler} edge='end' size='small'>
                    <CloseIcon fontSize='medium' />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                <Box pt={1}>
                    <TextField
                        fullWidth
                        className="form-input"
                        multiline
                        rows={3}
                        error={Boolean(errors?.BanReason)}
                        helperText={errors?.BanReason}
                        onChange={(e) => setBanMemberData({ ...banMemberData, memberNotes: e.target.value })}
                        label={`Add Reason`}
                        name="memberNotes"
                        value={banMemberData?.memberNotes}
                    />
                </Box>
                <Box pt={1} mt={2}>
                    <Typography variant='h5' fontWeight="semibold" mb={1.5}>Upload Attachments</Typography>
                    <Stack direction="row" flexWrap="wrap" gap={1.25} mb={4.5}>
                        {
                            banMemberData?.notesAttachment?.filter((noteImage => getImageExtension(noteImage) !== 'pdf'))?.length > 0 && <>

                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                >
                                    {banMemberData?.notesAttachment?.filter((noteImage => getImageExtension(noteImage) !== 'pdf'))?.map(((image, index) => <img key={index} style={uploadThumbnailImage} src={image} alt="notesAttachment" />))}
                                </Badge>


                            </>
                        }
                        <label htmlFor="ban-member-notesAttachment">
                            <Box bgcolor="#F1F1F1" display="flex" alignItems="center" justifyContent="center" flexDirection="column" minWidth="100px" minHeight="100px" borderRadius="20px" p={2} sx={{ cursor: "pointer" }}>
                                {
                                  isUploading && uploadTypeRef.current === 'image'? <CircularProgress size={32} color='primary' sx={circularProgressIcon} /> : <Typography variant='h5' fontWeight="medium" textAlign="center">+<br />Upload</Typography>
                                }

                            </Box>
                        </label>

                        <input
                            id="ban-member-notesAttachment"
                            type="file"
                            name="notesAttachment"
                            onChange={(e)=>attachmentImageUploader(e,'image')}
                            style={{ display: 'none' }}
                            accept=".png,.jpg,.jpeg"
                        />
                    </Stack>
                    <Box>
                        <Stack direction='row' gap={2} alignItems='center' justifyContent='space-between' mb={2}>
                            <Typography sx={{ wordBreak: 'break-word' }} fontSize='14px' fontWeight='semibold'>PDF Links  </Typography>
                            <label htmlFor="ban-member-notesAttachment-pdf">
                                <Box bgcolor="#F1F1F1" display="flex" alignItems="center" justifyContent="center" flexDirection="column" borderRadius="20px" px={2} py={1} sx={{ cursor: "pointer" }}>
                                  {
                                    isUploading && uploadTypeRef.current === 'pdf' ? <CircularProgress size={32} color='primary' sx={circularProgressIcon}  /> :  <Typography fontSize='14px' fontWeight="medium" textAlign="center">Upload PDF  </Typography>
                                  } 
                                </Box>
                            </label>
                            <input
                                id="ban-member-notesAttachment-pdf"
                                type="file"
                                name="notesAttachment"
                                onChange={(e)=>attachmentImageUploader(e,'pdf')}

                                style={{ display: 'none' }}
                                accept=".pdf"
                            />
                        </Stack>
                        {
                            banMemberData?.notesAttachment && banMemberData?.notesAttachment?.filter((noteImage => getImageExtension(noteImage) === 'pdf'))?.length > 0 && <Box >
                                {
                                    banMemberData?.notesAttachment && banMemberData?.notesAttachment?.filter((noteImage => getImageExtension(noteImage) === 'pdf'))?.map((pdfUrl => {
                                        return <Tooltip key={pdfUrl} title={pdfUrl} arrow placement="top"><Box mr={2} className='text-truncate'>{pdfUrl}</Box></Tooltip>
                                    }))
                                }
                            </Box>
                        }
                    </Box>

                </Box>
            </DialogContent>

            <DialogActions>
                <MDButton onClick={closeModalHandler} size="small" color="secondary">
                    Cancel
                </MDButton>
                <MDButton onClick={submitHandler} disabled={isUploading} size="small" color='primary'>
                    {
                        submittingOrder ? <CircularProgress size={16} color='light' /> : "Save"
                    }

                </MDButton>
            </DialogActions>
        </>
    )
}