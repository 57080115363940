import { Close } from '@mui/icons-material'
import { Box, FormHelperText, Stack, Switch, TextField, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import GoogleSuggestion from 'components/googleMaps/GoogleSuggestion'
import { fetchAddressHQ, fetchHyperWolfFirstRenderHQ, fetchHQRegions, updateAddressReturnHQ, updateHQRegionInformation } from '../../../redux/slices/hyperdrive/createTask';
import noDataFound from "../../../assets/images/nothing-found.svg"
import Loader from 'common/Loader'
import { useLayoutEffect } from 'react'
import { MobileTimePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import dayjs from 'dayjs'
import { openSnackbar } from '../../../redux/slices/common'

export default function ReturnToHQ({ closeModal }) {
    const dispatch = useDispatch();
    const addressError = useRef(false)
    const [dataSource, setDataSource] = useState([])
    const [loading, setLoading] = useState(true)
    const [formData, setFormData] = useState({
        address: "",
    })
    const [errors, setErrors] = useState({})

    const googleAddressErrorHandler = (status = false) => {
        addressError.current = status
    }

    function throttle(fn, wait) {
        let timeout = null;
        return function (...args) {
            if (!timeout) {
                timeout = setTimeout(() => {
                    timeout = null;
                }, wait);
                fn(...args);
            }
        };
    }

    const handleChangeRegionsFields = (value, fieldName = '', fieldIndx, fieldClear) => {
        const timestamp = fieldName == "taskTime" ? value : "";
        const formattedTime = dayjs(timestamp).format("HH:mm");
        let field = dataSource[fieldIndx];
        field = {
            ...field,
            [fieldName]: fieldName == "taskTime" ? formattedTime.toString() : value
        }

        let newRegionsData = [...dataSource]
        newRegionsData[fieldIndx] = field;
        setDataSource(newRegionsData)
        throtallUpdateRegionInfo(field, field?.regionId)
    }

    const throtallUpdateRegionInfo = throttle((field) => updateRegionInformation(field, field?.regionId), 500)
    const updateRegionInformation = (regionData) => {
        dispatch(updateHQRegionInformation(regionData, regionData?.regionId)).unwrap().then((res => {
            showResponseMessage(res?.message, 'success');
        })).catch(err => {
            showResponseMessage(err?.message, 'error');
        })
    }
    const showResponseMessage = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
    }
    useEffect(() => {
        dispatch(fetchHQRegions({ limit: 100, skip: 0 })).unwrap().then((res => {
            const { returnToHQ = [] } = res || {}
            setDataSource(returnToHQ)
            setLoading(false)
        })).catch(err => {
            setLoading(false)
        })


        dispatch(fetchAddressHQ()).unwrap().then((res => {
            setFormData({ address: res?.HQAddress })
        })).catch(err => {
        })
    }, [])

    useLayoutEffect(() => {
        dispatch(fetchHyperWolfFirstRenderHQ()).unwrap().then((res => {
        })).catch(err => {
        })
    }, []);

    const handleAddressChange = (addressed) => {
        const HQAddress = {
            address1: addressed?.address || "",
            address: addressed?.address || "",
            city: addressed?.city || "",
            state: addressed?.state || "",
            zipcode: addressed?.zipcode || "",
            country: addressed?.country || "USA",
            latitude: addressed?.lat ? addressed?.lat.toFixed(8) : "",
            longitude: addressed?.long ? addressed?.long.toFixed(8) : "",
        };
        setFormData({ ...formData, HQAddress });
        dispatch(updateAddressReturnHQ({ HQAddress: HQAddress }))
    }

    return (
        <Box className="cus-wrapper-drawer">
            <Box display="flex" alignItems="center" justifyContent="space-between" p={2} style={{ borderBottom: "1px solid  #1E1E24" }} className="drawer-head-sec">
                <Typography className="text-14 text-white-700">Create Return to HQ Task</Typography>
                <Close onClick={closeModal} style={{ cursor: "pointer", color: "#FFFFFF" }} />
            </Box>
            <Box className="drawer-scroll-sec drawer-scroll-without-footer">
                <Box px={2} py={4}>
                    <Stack direction='column' mb={3} className='headquarters-address-field'>
                        <Typography mb={1} fontWeight={600} className='text-20 text-white-700'>Headquarters Address</Typography>
                        <Stack direction='column' gap={2} mb={1} className='dark-field hd-google-suggestions'>
                            <GoogleSuggestion addressValue={formData?.address?.address} googleAddressErrorHandler={googleAddressErrorHandler}
                                onClearAddress={(address) => setFormData({ ...formData, address })}
                                addressChangeHandler={(address) => handleAddressChange(address)} />
                            {errors?.address && <FormHelperText error>{errors?.address}</FormHelperText>}
                        </Stack>
                    </Stack>

                    <Typography mb={1} fontWeight={600} className='text-20 text-white-700'>Region List</Typography>
                    {loading ?
                        <Box className='w-100 d-flex align-item-center jc-center h-100 inner-scroll'>
                            <Loader />
                        </Box> :
                        <>
                            {
                                dataSource?.length === 0 ?
                                    <Box className='w-100 d-flex flex-column align-item-center jc-center h-100 no-data-found'>
                                        <img style={{ maxWidth: "120px" }} src={noDataFound} alt="No Results Found" />
                                        <p className='text-12'><strong>No Region Found</strong></p>
                                    </Box>
                                    :
                                    (dataSource?.map((data, fieldIndx) => {
                                        const time = data?.taskTime;

                                        // Parse the taskTime into a moment object if it's not empty
                                        let pickerValue = null;
                                        if (time) {
                                            const [hours, minutes] = time.split(':');
                                            pickerValue = moment().set({ hour: parseInt(hours, 10), minute: parseInt(minutes, 10), second: 0, millisecond: 0 });
                                        }
                                                                         
                                        return (
                                            <Box key={data?._id} display="flex" alignItems="start" flexDirection="column" justifyContent="space-between" className="region-listing" gap={1}>
                                                <Box display="flex" alignItems="center" className="region-txt">
                                                    <Switch checked={data?.isEnabled} onChange={(e, value) => handleChangeRegionsFields(e.target.checked, 'isEnabled', fieldIndx)} />
                                                    <Typography className="text-14 text-white-700">{data?.regionName}</Typography>
                                                </Box>
                                                <Stack gap={2} className='dark-field hd-google-suggestions'>
                                                    <MobileTimePicker className="mobile-time-picker" label="Time" value={pickerValue} ampm={false}
                                                        renderInput={(params) => <TextField    {...params} />} onChange={(e, value) => handleChangeRegionsFields(e, 'taskTime', fieldIndx)} />
                                                </Stack>
                                            </Box>
                                        )
                                    }))
                            }
                        </>
                    }
                </Box>
            </Box>
        </Box>
    )
}