import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CommonLayout from 'layouts/DashboardLayout';
import { Box, Card, Button, Typography, CircularProgress, Grid, FormHelperText, FormControl, TextField } from '@mui/material';
import CommonQuillEditor from 'common/editor';
import { validateHyperwolfMainStrainData } from 'validations/strain';
import { getAllMainStrains, updateMainStrain, uploadStrainImage } from '../../../redux/slices/hyperwolf/strains';
import { grey } from '@mui/material/colors';
import { openSnackbar } from '../../../redux/slices/common';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import ManageHyperwolfStrains from '../manageStrains';

function HyperwolfMainStrains(props) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { isLoading = false, allMainStrains } = useSelector(state => state.hyperwolfStrains)
    const [strainData, setStrainData] = useState({ ...allMainStrains })
    const [errors, setErrors] = useState({})
    const [currentTab, setCurrentTab] = useState('main_strain')
    const { search } = useLocation()
    const [searchParams, setSearchParams] = useSearchParams(search)
    const pageRef = useRef(0)

    useEffect(() => {
        dispatch(getAllMainStrains())
    }, [])

    useEffect(() => {
        setStrainData({ ...allMainStrains });
    }, [allMainStrains]);


    useEffect(() => {
        if (search) {
            const selectedTab = searchParams.get('strainTab');
            if (selectedTab !== currentTab) {
                navigate(`/hyperwolf/manage_main_strain?strainTab=${selectedTab}`)
                setCurrentTab(selectedTab)
                pageRef.current = 0
            }
        }
        else {
            navigate(`/hyperwolf/manage_main_strain?strainTab=main_strain`)
            setCurrentTab('')
        }
    }, [search])

    const onChangehandler = (e) => {
        const { name, value } = e.target;
        setStrainData({ ...strainData, [name]: value });
    };

    const showResponseMessage = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
    }

    const addMainStrain = () => {
        const { errors: validationErrors, isValid: isDataValid } = validateHyperwolfMainStrainData(strainData);
        if (!isDataValid) {
            setErrors(validationErrors);
            return;
        }
        setErrors({})
        const userForPayload = { ...strainData };
        dispatch(updateMainStrain(userForPayload)).unwrap().then(res => {
            showResponseMessage(res?.message, 'success')
            dispatch(getAllMainStrains());
        }).catch((err => {
            showResponseMessage(err?.message, 'error')
        }));
    };

    const imageChangeHandler = (e) => {
        let file = e.target.files[0]
        const formData = new FormData();
        formData.append('filePath', file)
        dispatch(uploadStrainImage(formData)).then((res) => {
            setStrainData({ ...strainData, image: res?.fileUrl })
        });
    }

    return (<>
        {
            currentTab && currentTab === 'main_strain' ?
                <CommonLayout>

                    <Box p={{ xs: 2, sm: 3, lg: 4 }}>
                        <Card sx={{ p: { xs: 2, md: 3 }, borderRadius: 2.5, boxShadow: "none" }}>
                            <Typography variant='h4' fontWeight="semibold" my={2}>Manage Main Strain</Typography>
                            <Grid container spacing={4} component="main" className={`add-zipcode-modal-container`}>
                                <Grid item xs={12} className="add-category-input">
                                    <Grid item xs={12} className="txt-left">
                                        <TextField
                                            name="metaTitle"
                                            value={strainData.metaTitle || ""}
                                            onChange={onChangehandler}
                                            label="Meta Title"
                                            className="w-100"
                                            margin="normal"
                                            error={errors.metaTitle}
                                            helperText={errors?.metaTitle}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className="txt-left" >
                                        <TextField
                                            name="metaDescription"
                                            value={strainData.metaDescription || ""}
                                            onChange={onChangehandler}
                                            label="Meta Description"
                                            className="w-100"
                                            margin="normal"
                                            error={errors.metaDescription}
                                            helperText={errors?.metaDescription}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <Typography variant="subtitle2" fontWeight="medium" color={grey[600]} mb={1}>Description*</Typography>
                                            <CommonQuillEditor onChange={(value) => setStrainData({ ...strainData, description: value })} id={"description"} value={allMainStrains.description} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <FormControl fullWidth>
                                            <Typography variant="subtitle2" fontWeight="medium" color={grey[600]} mb={1}>Title*</Typography>
                                            <CommonQuillEditor onChange={(value) => setStrainData({ ...strainData, title: value })} id={"title"} value={allMainStrains.title} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Grid container className="mb-2 mt-2" >
                                            <input type='file' name='filePath' onChange={imageChangeHandler} />
                                            {
                                                strainData?.image && <img style={{ height: 100, width: 200 }} src={strainData?.image} />
                                            }
                                            {
                                                errors.image && <FormHelperText > <div className='error' >{errors.image} </div> </FormHelperText>
                                            }
                                        </Grid>
                                    </Grid>


                                </Grid>

                            </Grid >
                            <Box textAlign='end'>
                                <Button onClick={addMainStrain} variant="contained" color="primary">{isLoading ? <CircularProgress size={22} style={{ color: "white" }} /> : 'Submit'}</Button>
                            </Box>
                        </Card>
                    </Box>
                </CommonLayout>
                :
                <ManageHyperwolfStrains />
        }

    </>);
}


export default HyperwolfMainStrains