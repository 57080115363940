import CommonLayout from "layouts/DashboardLayout";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchDriverDetails } from "../../../../redux/slices/hyperwolf/driver/driver";
import CreateDriver from "../createDriver";

export default function AddDriver() {
    const dispatch = useDispatch()
    const {driverId = ""} = useParams()

    useEffect(() => {
        if (driverId){
            console.log(driverId, 'this is driver Id')
            dispatch(fetchDriverDetails(driverId))
        }
    }, [driverId])

    return (
        <CommonLayout>
            <CreateDriver />
        </CommonLayout>
    )
}