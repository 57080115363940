import Grid from "@mui/material/Grid";
import { Box, Card, CardContent,  IconButton,  Stack, Typography } from "@mui/material";
import DotIndicatorIcon from "common/customIcons/dot_indicator";
import LineChart from "common/charts/LinChart";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { creditCalculationStats, taxCalculationStats } from '../../redux/slices/dashboard'
import FilterMenu from "common/FilterMenu";
import { FilterAltOutlined } from "@mui/icons-material";

const options = {
    responsive: true,
    plugins: {
        legend: {
            // position: 'top',
            display: false
        },
        title: {
            display: false,
            text: 'Chart.js Line Chart',
        },
        tooltip: {
            backgroundColor: "#fdfdfd",
            bodyColor: "#16161E",
            titleColor: "#16161E",
            borderWidth: 1,
            borderColor: "#F1F1F1",
            padding: 8
        },
    },
    scales: {
        y: {
            display: true,
            ticks: {
                beginAtZero: true,
                display: true,
                stepSize: 1000,
                max: 100
            },
            grid: {
                display: false
            }
        },
        x: {
            display: true,
        },
    }
};
const labels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

export default function CardRevenueAndTaxesStats() {
    const dispatch = useDispatch()
    const { taxCalculationStatsData, creditCalculationStatsData = {} } = useSelector(state => state.dashboard)
    const [taxesAndRevenueFilters, setTaxesAndRevenueFilters] = useState({
        creditRevenueWeekMonthToday: 'month',
        taxesWeekMonthToday: 'month'
    })
    const [cardRevenueFilterOpen, setCardRevenueFilterOpen] = useState(false)
    const [amountTaxesFilterOpen, setAmountTaxesFilterOpen] = useState(false)



    const taxAmountChartData = useMemo(() => {
        const { results = [] } = taxCalculationStatsData || {}
        const formatedChartData = results?.map((taxes => taxes?.map((item => item?.totalTax))))
        let chartData = {
            labels: [],
            datasets: [
                {
                    data: formatedChartData[0],
                    borderColor: '#ED64F7',
                    backgroundColor: '#ED64F7',
                    pointRadius: 3.5,
                    lineTension: 0.5
                },
                {
                    data: formatedChartData[1],
                    borderColor: '#0BDB2C',
                    backgroundColor: '#0BDB2C',
                    pointRadius: 3.5,
                    lineTension: 0.5,
                },
            ],
        };
        chartData.labels = taxesAndRevenueFilters?.taxesWeekMonthToday === 'today' ? ['Today', "Yesterday"] : results[0]?.map((item => item?.label))
        return chartData;
    }, [taxCalculationStatsData?.results, taxesAndRevenueFilters?.taxesWeekMonthToday])

    const cardRevenueChartData = useMemo(() => {
        const { results = [] } = creditCalculationStatsData || {}
        const formatedChartData = results?.map((taxes => taxes?.map((item => item?.totalRevenue))))
        let chartData = {
            labels: results[0]?.map((item => item?.label)),
            datasets: [
                {
                    data: formatedChartData[0],
                    borderColor: '#01CAA6',
                    backgroundColor: '#01CAA6',
                    pointRadius: 3.5,
                    lineTension: 0.5
                },
                {
                    data: formatedChartData[1],
                    borderColor: '#F3A557',
                    backgroundColor: '#F3A557',
                    pointRadius: 3.5,
                    lineTension: 0.5,
                },
            ],
        };
        return chartData;
    }, [creditCalculationStatsData?.results])

    useEffect(() => {
        dispatch(taxCalculationStats({ dashboardQuery: taxesAndRevenueFilters?.taxesWeekMonthToday }))
    }, [taxesAndRevenueFilters?.taxesWeekMonthToday])

    useEffect(() => {
        dispatch(creditCalculationStats({ dashboardQuery: taxesAndRevenueFilters?.creditRevenueWeekMonthToday }))
    }, [taxesAndRevenueFilters?.creditRevenueWeekMonthToday])

    return (
        <>
            <Grid item xs={12} md={6} lg={12} xxl={6}>
                <Card variant='dashboardCard' sx={{ height: '100%', overflow: 'hidden' }}>
                    <CardContent sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%", p: 2.5 }}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={5} mb={4}>
                            <Typography variant="h5" lineHeight={1.25}>$ Credit Card Revenue</Typography>

                            {/* filter ui  */}
                            <IconButton variant='btn-filter' color='secondary' onClick={(e) => setCardRevenueFilterOpen(e.currentTarget)}>
                                <FilterAltOutlined fontSize='medium' color='secondary' />
                            </IconButton>
                            <FilterMenu selectedMenu={taxesAndRevenueFilters?.creditRevenueWeekMonthToday} filter={cardRevenueFilterOpen} handleFilter={setCardRevenueFilterOpen} applyFilterHandler={(value) => setTaxesAndRevenueFilters({ ...taxesAndRevenueFilters, creditRevenueWeekMonthToday: value })} />
                            {/* filter ui end  */}
                        </Stack>
                        <Box width='100%' height='100%'>
                            <LineChart chartData={cardRevenueChartData} options={options} />
                        </Box>

                        <Stack direction="row" flexWrap="wrap" gap={2} mt={3}>
                            <Box display="inline-flex" alignItems="center" gap={1} minWidth={{ xs: 80, xxl: 100, xxxl: 140 }}>
                                <DotIndicatorIcon style={{ width: "6px", height: "6px", color: "#01CAA6", marginTop: "-1px" }} />
                                <Typography variant="body2" fontWeight="semibold" sx={{ textTransform: 'capitalize' }}> {taxesAndRevenueFilters?.creditRevenueWeekMonthToday === 'today' ? 'Today' : <>This  {taxesAndRevenueFilters?.creditRevenueWeekMonthToday} </>} </Typography>
                            </Box>

                            <Box display="inline-flex" alignItems="center" gap={1} minWidth={{ xs: 80, xxl: 100, xxxl: 140 }}>
                                <DotIndicatorIcon style={{ width: "6px", height: "6px", color: "#F3A557", marginTop: "-1px" }} />
                                <Typography variant="body2" fontWeight="semibold" sx={{ textTransform: 'capitalize' }}> {taxesAndRevenueFilters?.creditRevenueWeekMonthToday === 'today' ? 'Yesterday' : <>Previous  {taxesAndRevenueFilters?.creditRevenueWeekMonthToday} </>} </Typography>
                            </Box>
                        </Stack>
                    </CardContent>
                </Card>
            </Grid>
        </>
    )
}