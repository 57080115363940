import React from 'react'
import { Box, Stack, Skeleton } from '@mui/material';
export default function SkeletonNotificationDrawer() {
  return (
    <Stack style={{ width: "100%", padding: "10px", marginBottom:"20px" }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} style={{ marginBottom: 15 }}>
        <Skeleton variant="rectangular" height={20} width={"100%"} style={{ borderRadius: "8px" }} />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '20px'}} style={{ marginBottom: 10, margintop: 10, paddingLeft: 10, paddingRight: 10 }}>
        <Skeleton variant="rectangular" height={20} width="20%" style={{ borderRadius: "12px" }} />
        <Skeleton variant="rectangular" height={20} width="80%" style={{ borderRadius: "12px" }} />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '20px'}} style={{ marginBottom: 10, margintop: 10, paddingLeft: 10, paddingRight: 10 }}>
        <Skeleton variant="rectangular" height={20} width="20%" style={{ borderRadius: "12px" }} />
        <Skeleton variant="rectangular" height={20} width="80%" style={{ borderRadius: "12px" }} />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} style={{ marginTop: 5 }}>
        <Skeleton variant="rectangular" height={20} width={"40%"} style={{ borderRadius: "8px"}} />
      </Box>
    </Stack>
  )
}