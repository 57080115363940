import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchBreakDetails } from "../../../../redux/slices/hyperwolf/driver/breaks";
import CreateBreak from "../createBreak";

export default function AddBreak() {
    const dispatch = useDispatch()
    const { breakId=""} = useParams()

    useEffect(() =>  {
        if (breakId) {
            dispatch(fetchBreakDetails(breakId))
        }
    },[breakId])

    return(
        <CreateBreak />
    )

}