import { Box, Typography } from '@mui/material';
import ActiveIcon from 'common/customIcons/active';
import InActiveIcon from 'common/customIcons/inactive';
import InProgressIcon from 'common/customIcons/inprogress';
import NotStartedIcon from 'common/customIcons/notstarted';
import React from 'react';

const statusComponents = {
    deliveryTask: (showIcon) => (
        <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">Delivery Task</Typography>
    ),
    returnToHeadquarterTask: (showIcon) => (
        <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">Return to Headquarter Task</Typography>
    ),
    startTask: (showIcon) => (
        <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">Start Task</Typography>
    ),
    breakTask: (showIcon) => (
        <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">Break Task</Typography>
    ),
    pickUpTask: (showIcon) => (
        <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">Pickup Task</Typography>
    ),

    not_started: (showIcon) => (
        <Box color="#8A8A98" display="flex" alignItems="center">
            {showIcon && <NotStartedIcon width="18px" height="18px" flex="none" />}
            <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">Not Started</Typography>
        </Box>
    ),
    in_progress: (showIcon) => (
        <Box color="#8E0CF5" display="flex" alignItems="center">
            {showIcon && <InProgressIcon width="18px" height="18px" flex="none" />}
            <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">In Progress</Typography>
        </Box>
    ),
    completed: (showIcon) => (
        <Box color="#24CA49" display="flex" alignItems="center">
            {showIcon && <ActiveIcon width="18px" height="18px" flex="none" />}
            <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">Completed</Typography>
        </Box>
    ),
    cancelled: (showIcon) => (
        <Box color="#FD4438" display="flex" alignItems="center">
            {showIcon && <InActiveIcon width="18px" height="18px" flex="none" />}
            <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">Cancelled</Typography>
        </Box>
    ),
    onduty: () => (
        <Box color="#24CA49" display="flex" alignItems="center">
            <Typography variant='caption' fontWeight="regular"  textTransform="capitalize">On Duty</Typography>
        </Box>
    ),
    offduty: () => (
        <Box color="#f8584b" display="flex" alignItems="center">
            <Typography variant='caption' fontWeight="regular"  textTransform="capitalize">Off Duty</Typography>
        </Box>
    ),
    onTime: () => (
        <Box color="#24CA49" display="flex" alignItems="center">
            <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">On Time</Typography>
        </Box>
    ),  
    high: () => (
        <Box color="#ffF98B3C" display="flex" alignItems="center">
            <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">High</Typography>
        </Box>
    ),
    medium: () => (
        <Box color="#ffF4BB27" display="flex" alignItems="center">
            <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">Medium</Typography>
        </Box>
    ),
    critical: () => (
        <Box color="#ffF44848" display="flex" alignItems="center">
            <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">Critical</Typography>
        </Box>
    ),
    delayed: () => (
        <Box color="#ffF4BB27" display="flex" alignItems="center">
            <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">Delayed</Typography>
        </Box>
    )
};

const StatusTask = ({ value, icon }) => {
    const showIcon = icon === "showIcon";
    const content = statusComponents[value] ? statusComponents[value](showIcon) : <p style={{ color: "gray" }}>--</p>;

    return (
        <div style={{ fontWeight: "500" }}>
            {content}
        </div>
    );
};

export default StatusTask;
