import React, { useState, useEffect, useRef, useMemo } from 'react';
import {  Typography, CircularProgress, Grid, Box, Select, MenuItem, IconButton, Card, FormHelperText, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '@mui/material';
import { validateBannerData } from '../../validations/homepageBanner'
import { openSnackbar,  uploadImage } from '../../redux/slices/common';
import { isEmptyObject } from 'utilities/common';
import { useNavigate, useParams } from 'react-router-dom';
import MDButton from 'components/MDButton';
import { createCannabinoidBanners, updateCannabinoidBanners } from '../../redux/slices/cannabinoids';
import AngleRightIcon from 'common/customIcons/angle_right';

const initialEmployee = {
    banner_position: "",
    desktop_image: "",
    media_type: "",
    mobile_image: '',
    url: ""
}

function AddCannabinoidBannerForm(props) {
    const dispatch = useDispatch()
    const { isLoading = false, singleCannabinoidBanner = {} } = useSelector(state => state.cannabinoids)

    const [banners, setBanners] = useState({ ...initialEmployee })
    const [errors, setErrors] = useState({ ...initialEmployee })
    const mobileImageRef = useRef()
    const desktopImageRef = useRef()
    const [isDesktopUploading, setIsDesktopUploading] = useState(false)
    const [isMobileUploading, setIsMobileUploading] = useState(false)
    const { bannerId = '' } = useParams()
    const navigate = useNavigate()

    const mediaType = useMemo(() => {
        return banners?.media_type
    }, [banners])

    const addBanner = () => {
        const { errors: validationErrors, isValid: isDataValid } = validateBannerData(banners);
        if (!isDataValid) {
            setErrors(validationErrors);
            return;
        }
        setErrors({})
        if (bannerId) {
            dispatch(updateCannabinoidBanners({ data: banners, id: bannerId })).unwrap().then(res => {
                navigate('/manage-cannabinoids?cannabinoidTab=main_cannabinoid')
                showResponseMessage(res?.message, 'success')
            }).catch((err) => {
                showResponseMessage(err?.message, 'error')
            });
            return;
        }
        dispatch(createCannabinoidBanners(banners)).unwrap().then(res => {
            navigate('/manage-cannabinoids?cannabinoidTab=main_cannabinoid')
            showResponseMessage(res?.message, 'success')
        }).catch((err) => {
            showResponseMessage(err?.message, 'error')
        });
         };


    const showResponseMessage = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
    }

    const imageUploadHandler = (key, file) => {
        if (mediaType === 'video') {
            let fname = file.name
            let re = /(\.webm|\.mp4)$/i;
            if (!re.exec(fname)) {
                alert("File extension not supported!");
                return;
            }
        }
        if (mediaType === 'image') {
            let fname = file.name
            let re = /(\.jpg|\.jpeg|\.bmp|\.gif|\.png)$/i;
            if (!re.exec(fname)) {
                alert("File extension not supported!");
                return;
            }
        }
        if (key === 'desktop_image') {
            setIsDesktopUploading(true)
        }
        if (key === 'mobile_image') {
            setIsMobileUploading(true)
        }
        let formData = new FormData()
        formData.append("filePath", file);
        dispatch(uploadImage(formData)).unwrap().then((res) => {
            let errorsObj = { ...errors }
            if (errorsObj[key]) {
                delete errors[key]
                setErrors({ ...errors })
            }
            setBanners({ ...banners, [key]: res.fileUrl })
            if (key === 'desktop_image') {
                setIsDesktopUploading(false)
            }
            if (key === 'mobile_image') {
                setIsMobileUploading(false)
            }

        })
            .catch((err) => {
                setBanners({ ...banners, [key]: `${process.env.REACT_APP_PUBLIC_BASE_URL}test.jpg` })
                if (key === 'desktop_image') {
                    setIsDesktopUploading(false)
                }
                if (key === 'mobile_image') {
                    setIsMobileUploading(false)
                }
            });
    }

    useEffect(() => {
        if (!isEmptyObject(singleCannabinoidBanner)) {
            const { media_type, desktop_image, mobile_image, url } = singleCannabinoidBanner
            setBanners({ ...banners, media_type, desktop_image, mobile_image, url })
        }
    }, [singleCannabinoidBanner])

    const thumbnail = {
        width: "100%",
        maxWidth: "100%",
        height: "260px",
        borderRadius: "10px",
        display: "block",
        objectFit: "contain"
    }

    return (
        <Box p={{ xs: 2, sm: 3, lg: 4 }}>
            <Card sx={{ p: { xs: 2, md: 3 }, mb: 2, borderRadius: 2.5, boxShadow: "none" }}>

            <Stack direction={{ xs: "column", sm: "row" }} alignItems={{ sm: "center" }} justifyContent="space-between" gap={2} mb={4.5}>
                <Box display="flex" alignItems="center" color='text.main' lineHeight={1}>
                    <IconButton  onClick={() =>navigate('/manage-cannabinoids?cannabinoidTab=main_cannabinoid')}>
                        <AngleRightIcon fontSize="14px"  />
                    </IconButton>
                    <Typography variant='h4' fontWeight="semibold" color="secondary" ml={.75}>
                        {!bannerId ? "Add" : 'Update'} Banners
                    </Typography>
                </Box>
            </Stack>
            
                {isLoading ? <Box p={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '260px' }}>
                    <CircularProgress size={20} />
                </Box> : <>
                <Grid container spacing={2.5} mb={4.5}>
                    <Grid item xs={12} md={6} lg={3}>
                        <Typography variant='body2' fontWeight="semibold" mb={1} style={{ color: "#9A9AAF" }}>Media Type</Typography>
                        <Select key={mediaType} variant='outlined' name='mediaType' fullWidth value={mediaType} onChange={(e) => setBanners({ ...banners, media_type: e.target.value, desktop_image: "", mobile_image: "" })} className='select-arrow'>
                            <MenuItem value='video'>Video</MenuItem>
                            <MenuItem value='image'>Image</MenuItem>
                        </Select>
                        {
                            errors?.media_type && <FormHelperText error className="error">{errors?.media_type}</FormHelperText>
                        }
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Typography variant='body2' fontWeight="semibold" mb={1} style={{ color: "#9A9AAF" }}>URL</Typography>
                        <TextField
                            name="url"
                            value={banners?.url || ""}
                            onChange={(e) => { setBanners({ ...banners, url: e.target.value }) }}
                            label="URL"
                            fullWidth
                            error={Boolean(errors.url)}
                            helperText={errors.url}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2.5} mb={2}>
                    <Grid item xs={12} md={6} lg={3}>
                        <Typography variant='h5' fontWeight="semibold" mb={1} style={{ color: "#9A9AAF", textTransform: "capitalize" }}>Desktop {mediaType} (652x323)</Typography>
                        <input accept={mediaType === 'video' ? ".mp4,.webm" : '.png,.jpg,.jpeg,.gif'} ref={desktopImageRef} style={{ display: 'none' }} type='file' onChange={(e) => imageUploadHandler('desktop_image', e.target.files[0])} />
                        {
                            isDesktopUploading ? <>
                                <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '260px', backgroundColor: "#e4e6e7", borderRadius: "10px", objectFit: 'contain' }}>
                                    <CircularProgress size={20} />
                                </Box>
                            </> : <Box style={{ backgroundColor: "#e4e6e7", borderRadius: "10px", cursor: 'pointer', objectFit: 'contain' }} onClick={() => {
                                if (!mediaType) return alert('Plase select media type');
                                desktopImageRef.current.click()
                            }}>
                                {
                                    (!isDesktopUploading && banners?.desktop_image) ? mediaType === 'video' ? <video style={thumbnail} loop autoPlay playsInline muted>
                                        <source key={banners.desktop_image} src={banners.desktop_image} type="video/webm" />
                                        <source key={banners.desktop_image} src={banners.desktop_image} type="video/mp4" />
                                    </video> : <img style={thumbnail} src={banners.desktop_image} /> : <img style={thumbnail} src={`https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png`} />
                                }
                            </Box>
                        }
                        {
                            errors?.desktop_image && <FormHelperText error className="error">{errors?.desktop_image}</FormHelperText>
                        }
                        {
                            banners?.banner_position && <Typography color='red'><strong>Note:</strong> {banners?.banner_position === 'left' ? "Banner image should be 873x365" : "Banner image should be 275x365"}  </Typography>
                        }

                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Typography variant='h5' fontWeight="semibold" mb={1} style={{ color: "#9A9AAF", textTransform: "capitalize" }}>Mobile {mediaType}(375x240)</Typography>
                        <input accept={mediaType === 'video' ? ".mp4,.webm" : '.png,.jpg,.jpeg,.gif'} ref={mobileImageRef} type='file' style={{ display: 'none' }} onChange={(e) => imageUploadHandler('mobile_image', e.target.files[0])} />
                        {
                            isMobileUploading ? <>
                                <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '260px', backgroundColor: "#e4e6e7", borderRadius: "10px", objectFit: 'contain' }}>
                                    <CircularProgress size={20} />
                                </Box>

                            </> : <Box style={{ backgroundColor: "#e4e6e7", borderRadius: "10px", cursor: 'pointer', objectFit: 'contain' }} onClick={() => {
                                if (!mediaType) return alert('Plase select media type');
                                mobileImageRef.current.click()
                            }}>
                                {
                                    banners?.mobile_image ? mediaType === 'video' ? <video style={thumbnail} loop autoPlay playsInline muted>
                                        <source key={banners.mobile_image} src={banners.mobile_image} style={thumbnail} type="video/mp4" />
                                        <source key={banners.mobile_image} src={banners.mobile_image} style={thumbnail} type="video/webm" />
                                    </video> : <img src={banners.mobile_image} style={thumbnail} /> : <img style={thumbnail} src={`https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png`} />
                                }

                            </Box>
                        }
                        {
                            errors?.mobile_image && <FormHelperText error className="error">{errors?.mobile_image}</FormHelperText>
                        }
                        {
                            banners?.banner_position && <Typography color='red'><strong>Note:</strong> {banners?.banner_position === 'left' ? "Banner image should be 873x365" : "Banner image should be 275x365"}  </Typography>
                        }
                    </Grid>
                </Grid>

                <Stack direction={{ xs: "column", sm: "row" }} spacing={1.25} mt={2} mb={2}>
                    <MDButton
                        sx={{ minWidth: "140px" }}
                        type='button'
                        variant='contained'
                        color='primary'
                        onClick={addBanner}
                        loading={isLoading}
                    >
                        {isLoading ? <CircularProgress size={20} style={{ color: "white" }} /> : 'Submit'}
                    </MDButton>

                    <MDButton
                        sx={{ minWidth: "140px" }}
                        type='button'
                        variant='outlined'
                        color='dark'
                        onClick={() => navigate('/manage-cannabinoids?cannabinoidTab=main_cannabinoid')}
                    >
                        Cancel
                    </MDButton>
                </Stack>
                </>}
            </Card>
        </Box>
    );
}

export default AddCannabinoidBannerForm