import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchBreakDetailsHemp } from "../../../redux/slices/breaks";
import CreateBreakHemp from "../createBreak";

export default function AddBreakHemp() {
    const dispatch = useDispatch()
    const { breakId=""} = useParams()

    useEffect(() =>  {
        if (breakId) {
            dispatch(fetchBreakDetailsHemp(breakId))
        }
    },[breakId])

    return(
        <CreateBreakHemp />
    )

}