import AddOrderForm from 'components/orders/AddOrderForm'
import CommonLayout from 'layouts/DashboardLayout'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { fetchSingleOrder } from '../../../redux/slices/orders'

export default function AddOrder() {
  const dispatch = useDispatch()
  const { orderId = "" } = useParams()
  useEffect(() => {
    if (orderId) {
      dispatch(fetchSingleOrder(orderId))
    }
  }, [orderId])

  return (
   <CommonLayout>
     <AddOrderForm/>
   </CommonLayout>
  )
}
