import CommonLayout from 'layouts/DashboardLayout'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Grid, IconButton, InputAdornment, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { AgGridTable } from 'components/tables/AgGridTable'
import { useNavigate } from 'react-router-dom'
import MDButton from 'components/MDButton'
import { formatDate } from 'utilities/common'
import { closeCustomModalDialog, openCustomModalDialog, setModalLoader } from '../../redux/slices/modalSlice'
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useDebounce } from 'hooks/useDebounce'
import EditIcon from 'common/customIcons/edit'
import DeleteIcon from 'common/customIcons/delete'
import { openSnackbar } from '../../redux/slices/common'
import { CloseRounded } from '@mui/icons-material'
import StatusText from 'common/component/StatusText'
import SearchIcon from 'common/customIcons/search'
import { deleteRole, fetchRoleList } from '../../redux/slices/roleAndPermissions'

export default function Users() {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const pageRef = useRef(0)
    const searchTermRef = useRef("")
    const mainGridApiRef = useRef('')
    const gridRefApi = useRef(null)
    const [searchTerm, setSearchTerm] = useState("")
    const { roles = {}, isLoading: loading = false } = useSelector(state => state.roleAndPermissions)

    const deleteHandler = (promotionId) => {
        dispatch(setModalLoader(true))
        dispatch(deleteRole(promotionId)).unwrap().then((res) => {
            showResponseMessage(res?.message, 'success')
            dispatch(closeCustomModalDialog())
            pageRef.current = 0
            const dataSource = {
                getRows
            }
            mainGridApiRef.current.ensureIndexVisible(0, null);
            mainGridApiRef.current.setGridOption('datasource', dataSource);
        }).catch((err) => {
            dispatch(setModalLoader(false))
            showResponseMessage(err?.message, 'error')
        })
    }

    const showResponseMessage = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
    }

    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: 'Full Name',
            field: 'name',
            key: "name",
            suppressMenu: false,
            cellRenderer: (props) => {
                return <Typography variant="caption" display="block" fontWeight="regular" sx={{ cursor: "pointer" }}>{props?.data?.name || "-"}</Typography>
            },
            flex: 1
        },
        {
            headerName: 'Email',
            field: 'email',
            suppressMenu: false,
            minWidth: 200,
            flex: 1,
        },

        {
            headerName: 'Status',
            field: 'status',
            suppressMenu: false,
            cellRenderer: (props) => {
                return <StatusText status={props?.data?.status} />
            },
            minWidth: 100,
            flex: 1
        },

        {
            headerName: 'Joined Date',
            field: 'joinedDate',
            suppressMenu: false,
            minWidth: 120,
            flex: 1
        },
        {
            headerName: 'Roles',
            field: 'userRoles',
            suppressMenu: false,
            cellRenderer: (props) => {
                return props?.value?.length > 0 ? <Tooltip title={props?.value?.join(', ')} arrow placement="top"><Box mr={2} className='text-truncate'>{props?.value?.join(', ')}</Box></Tooltip> : '-'
            },
            minWidth: 100,
            flex: 1
        },
        {
            headerName: 'Actions',
            cellRenderer: (props) => {
                if (props?.data?.isSuperAdmin) return;
                return <>

                    <IconButton disabled={props?.data?.isSuperAdmin} onClick={(e) => {
                        e.stopPropagation()
                        onEditClicked(props)
                    }} color='primary' size='small' variant="primary">
                        <EditIcon width='16px' height='16px' />
                    </IconButton>
                    <IconButton disabled={props?.data?.isSuperAdmin} onClick={(e) => {
                        e.stopPropagation()
                        dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: props.data?._id }, modal: 'DELETE_MODAL' }))
                    }} color='error' size='small' variant="primary" sx={{ marginLeft: 1 }}>
                        <DeleteIcon width='16px' height='16px' />
                    </IconButton>
                </>
            },
            cellStyle: { textAlign: 'right', justifyContent: 'end' },
            headerClass: 'right-aligned-cell-header',
            minWidth: 120,
            flex: 1
        },

    ]);

    function getSortInfo(data) {
        const result = {};
        data.forEach(item => {
            if (item?.colId === 'memberType') {
                result[item.colId] = item.sort === "asc" ? 'AdultUse' : "MedicinalUser";
            } else {
                result[item.colId] = item.sort === "asc";
            }

        });
        return result;
    }

    const getRows = async (params) => {
        gridRefApi.current = params;
        let filter = {}
        if (params?.sortModel.length) {
            filter = { ...getSortInfo(params?.sortModel) }
        }
        dispatch(fetchRoleList({ skip: pageRef.current, limit: 20, searchTerm: searchTermRef.current, ...filter })).unwrap().then(res => {
            const { values, total } = res
            const adminArray = values?.map((item => {
                const date = new Date(item?.createdDate)
                return { ...item, status: item?.status || '-', joinedDate: formatDate(date), id: item._id }
            }))

            params.successCallback([...adminArray], total);
            pageRef.current = pageRef.current + 20
        }).catch(() => {
            params.successCallback([], 0);
        })
    }

    const searchFn = () => {
        const dataSource = {
            getRows
        }
        mainGridApiRef.current.ensureIndexVisible(0, null);
        mainGridApiRef.current.setGridOption('datasource', dataSource);
    }

    const onEditClicked = (row) => {
        navigate(`/user-roles/${row?.data?._id}`)
    }

    useEffect(() => {
        if (mainGridApiRef?.current) {
            if (loading) {
                mainGridApiRef.current.showLoadingOverlay()
            } else if (roles?.total == 0) {
                mainGridApiRef.current.showNoRowsOverlay()
            }
            else {
                mainGridApiRef.current.hideOverlay()
            }
        }

    }, [roles, loading])

    const handleSearch = useDebounce((term) => {
        // Perform search operation with the debounced term
        pageRef.current = 0
        searchFn()
    }, 500);

    const searchHandler = (searchValue) => {
        setSearchTerm(searchValue);
        searchTermRef.current = searchValue
        handleSearch(searchValue);
        // Debounce the search callback
    };

    return (
        <CommonLayout>
            <Box p={{ xs: 2, sm: 3, lg: 4 }}>
                <Grid container alignItems="center" rowSpacing={2} columnSpacing={2} mb={3}>
                    <Grid item xs={12} sm={4}>
                        <Typography variant='h4'>Users</Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Stack direction="row" justifyContent={{ xs: "start", sm: "end" }} flexWrap="wrap" alignItems="stretch" gap={2}>
                            <TextField className="custom-search-field" placeholder="Search" label="" value={searchTerm} onChange={(e) => searchHandler(e.target.value)}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">
                                        <IconButton size='small'>
                                            <SearchIcon width='18px' height='18px' />
                                        </IconButton>
                                    </InputAdornment>,
                                    endAdornment: searchTerm?.length > 0 ? <InputAdornment>
                                        <IconButton onClick={() => searchHandler('')} size='small'>
                                            <CloseRounded />
                                        </IconButton>
                                    </InputAdornment> : null
                                }}
                            />
                            <MDButton
                                startIcon={<AddRoundedIcon fontSize='large' />}
                                variant="contained" color="primary"
                                onClick={() => navigate('/user-roles/add')} >
                                Add User
                            </MDButton>
                        </Stack>
                    </Grid>
                </Grid>

                <AgGridTable col={columnDefs} getRows={getRows} mainGridApiRef={mainGridApiRef} pageRef={pageRef} isLoading={loading} style={{ height: "70vh" }}
                />
            </Box>
        </CommonLayout>
    )
}