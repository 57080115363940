import { Badge, createSvgIcon } from "@mui/material";
import React from "react";

const uploadThumbnailImage = {
    borderRadius: "20px",
    width: "160px",
    height: "160px",
    objectFit: "cover",
    boxShadow: "0 0 1px rgb(0 0 0 / 20%)",
    cursor: "move"
};

const PlusIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
        <path d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" />
    </svg>,
    'Plus',
);

const thumbnailCloseIcon = {
    backgroundColor: "#FD4438",
    borderRadius: 10,
    p: .25,
    mt: -4,
    mr: -4,
    cursor: "pointer"
};

function ImageDragger({ images = [
    "https://images.unsplash.com/photo-1683009680116-b5c04463551d?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxlZGl0b3JpYWwtZmVlZHw2fHx8ZW58MHx8fHx8",
    "https://images.unsplash.com/photo-1711139279274-ddff5036591a?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxNHx8fGVufDB8fHx8fA%3D%3D",
    "https://plus.unsplash.com/premium_photo-1709901916808-19de9683437e?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxN3x8fGVufDB8fHx8fA%3D%3D",
], imageDragHandler, removeImageHandler }) {

    const handleDragStart = (e, index) => {
        e.dataTransfer.setData("text/plain", index);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };
    const handleDrop = (e, targetIndex) => {
        e.preventDefault();
        const draggedIndex = parseInt(e.dataTransfer.getData("text/plain"));

        // Create a new array to hold the updated images
        const newImages = [...images];

        // Swap the images at the draggedIndex and targetIndex
        const draggedImage = newImages[draggedIndex];
        newImages[draggedIndex] = newImages[targetIndex];
        newImages[targetIndex] = draggedImage;

        // Update state with the new array
        imageDragHandler(newImages);
    };

    return (
        <>
            {images.map((image, index) => (
                <Badge
                    key={index}
                    className="draggable"
                    draggable={true}
                    onDragStart={(e) => handleDragStart(e, index)}
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, index)}
                    overlap="circular"
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    badgeContent={
                        <PlusIcon onClick={() => removeImageHandler(index)} color='white' fontSize='small' sx={thumbnailCloseIcon} />
                    }
                >
                    <img src={image} alt={image} key={index} style={uploadThumbnailImage} />
                </Badge>
            ))}
        </>
    );
}

export default ImageDragger;