import { Box, Typography } from '@mui/material'
import React from 'react'

const historyStatus = {
    declined: <Box display="flex" color="#FD4438"  alignItems="center">
        <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">Declined</Typography>
    </Box>,
    approved: <Box  color="#24CA49" display="flex" alignItems="center">
        <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">Approved</Typography>
    </Box>,
      override: <Box color="#8E0CF5" display="flex" alignItems="center">
      <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">Override</Typography>
  </Box>,
        pending: <Box color="#8E0CF5" display="flex" alignItems="center">
       <Typography variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize">Pending</Typography>
   </Box>
}

export default function HistoryStatus({ status="" }) {
    return historyStatus[status?.toLowerCase()]
}
