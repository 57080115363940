import React from 'react'

export default function DriverIcon() {
  return (
    <svg className="svg-icon" 
      style={{ width: "1em", height: "1em", verticalAlign: "middle", fill: "currentColor", overflow: "hidden" }} 
      viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M304.04 306.45a207.3 207.97 0 1 0 414.6 0 207.3 207.97 0 1 0-414.6 0Z"  />
      <path d="M327.77 928.54h150.76l20.88-18.78V746.18C404.86 754.11 330 832.5 327.77 928.54zM683.33 764.88a236.16 236.16 0 0 1 69.58 163.66H910a17.61 17.61 0 0 0 17.58-18.4 416.15 416.15 0 0 0-165.35-314.52l-117.32 138a240.33 240.33 0 0 1 38.42 31.26zM529.57 746.05v163l25.37 19.49h147.71C700.37 832 624.8 753.3 529.57 746.05z"  /><path d="M347.09 764.88a238.13 238.13 0 0 1 271.07-46.31L737.4 578.26a412.06 412.06 0 0 0-224.39-66c-223.15 0-405.17 176.7-414.61 398.24a17.39 17.39 0 0 0 17.41 18.09H277.5a236.21 236.21 0 0 1 69.59-163.71z"  />
    </svg>
  )
}