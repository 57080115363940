import React from 'react'

export default function ReturnIcon() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_11000_3863)">
        <path d="M10.3773 4.74659H2.08723L3.78333 3.05049C4.02209 2.81173 4.02209 2.42469 3.78333 2.18593C3.54451 1.9471 3.15754 1.94717 2.91871 2.18593L0.179069 4.92571C-0.0596896 5.16447 -0.0596896 5.55151 0.179069 5.79026L2.91871 8.5299C3.03812 8.64932 3.19456 8.70896 3.35099 8.70896C3.50742 8.70896 3.66385 8.64925 3.78327 8.5299C4.02203 8.29115 4.02203 7.90411 3.78327 7.66535L2.08717 5.96925H10.3772C11.7006 5.96925 12.7773 7.04594 12.7773 8.36933C12.7773 9.69272 11.7006 10.7694 10.3772 10.7694H5.33428C4.99662 10.7694 4.72295 11.0431 4.72295 11.3807C4.72295 11.7184 4.99662 11.9921 5.33428 11.9921H10.3772C12.3748 11.9921 13.9999 10.3669 13.9999 8.36933C13.9999 6.37177 12.3748 4.74659 10.3773 4.74659Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_11000_3863">
          <rect width="14" height="14" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}