import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isOpen: false,
    isOpenDrawer: false,
    drawer: null,
    modal: null,
    modalData: {},
    drawerData: {},
    isLoading: false,
    driver: null,
    driverData: [],
    regionData: [],
    region: null,
    drawerApi: "",
    activeScreen: "",
    modalError: "",
    dataSwitch:"",
    routeData:""
};

const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        openCustomModalDialog: (state, action) => {
            state.isOpen = true;
            state.modal = action.payload.modal;
            state.modalData = action.payload.modalData;
            state.modalError = action.payload.modalError
        },
        closeCustomModalDialog: (state) => {
            state.isOpen = false;
            state.isLoading = false
            state.modal = null;
            state.modalData = {};
            state.modalError = ""
        },
        setModalLoader: (state, action) => {
            state.isLoading = action?.payload || false;
        },
        openCustomDrawer: (state, action) => {
            state.isOpenDrawer = true;
            state.drawer = action.payload.drawer;
            state.drawerData = action.payload.drawerData;
            state.drawerApi = action.payload.drawerApi
            state.activeScreen = action.payload.activeScreen
        },
        closeCustomDrawer: (state) => {
            state.isOpenDrawer = false;
            state.isLoading = false
            state.drawer = null;
            state.drawerApi = ""
            state.drawerData = {};
            state.activeScreen = ""

        },
        driverDataCustom: (state, action) => {
            state.isLoading = false
            state.driver = action?.payload?.driver;
            state.driverData = action?.payload?.driverData;
        },
        regionDataCustom: (state, action) => {
            state.isLoading = false
            state.region = action?.payload?.region;
            state.regionData = action?.payload?.regionData;
        },
        customApiSwitch: (state, action) => {
            state.isLoading = false
            state.dataSwitch = action?.payload?.dataSwitch;
            state.routeData = action?.payload?.routeData;
        },
    },
});

export const { openCustomModalDialog, closeCustomModalDialog, setModalLoader, closeCustomDrawer, openCustomDrawer, driverDataCustom, regionDataCustom,customApiSwitch } = modalSlice.actions;
export const modalReducer = modalSlice.reducer;