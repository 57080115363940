import { Box, Chip, Grid, IconButton,  Stack,  Typography } from '@mui/material'
import MDButton from 'components/MDButton'
import { AgGridTable } from 'components/tables/AgGridTable'
import Search from 'examples/Search'
import CommonLayout from 'layouts/DashboardLayout'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { sortAndFilter } from 'components/tables/AgGridTable'
import { closeCustomModalDialog, openCustomModalDialog, setModalLoader } from '../../redux/slices/modalSlice'
import { deleteInventoryItem, fetchInventory } from '../../redux/slices/inventory'
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useDebounce } from 'hooks/useDebounce'
import EditIcon from 'common/customIcons/edit'
import DeleteIcon from 'common/customIcons/delete'
import StatusText from 'common/component/StatusText'
import { openSnackbar } from '../../redux/slices/common'
import { formatDate } from 'utilities/common'

export default function Products() {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const { loading = false, inventory = {} } = useSelector(state => state.inventory)
  const searchTermRef = useRef("")
  const mainGridApiRef = useRef('')
  const gridRefApi = useRef(null)
  const pageRef = useRef(0)
  const [searchTerm, setSearchTerm] = useState("")

  const handleSearch = useDebounce((term) => {
    // Perform search operation with the debounced term
    pageRef.current = 0
    searchFn()
  }, 500);


  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: 'Inventory Name',
      field: 'inventoryName',
      suppressMenu: false,
      minWidth: 120
    },
    {
      headerName: 'Inventory ID',
      field: 'inventoryId',
      key: "fullName",
      suppressMenu: false,
      minWidth: 300
    },
    {
      headerName: 'Region Name',
      field: 'regionName',
      suppressMenu: false,
      cellRenderer: (props) => {
        return props?.data?.regionName ? <Chip size="medium" label={props?.data?.regionName} /> : ""
      },
      minWidth: 220
    },
    {
      headerName: 'Status',
      field: 'status',
      suppressMenu: false,
      cellRenderer: (props) => {
        return <StatusText status={props?.data?.status} />
      },
      minWidth: 100,
    },
    {
      headerName: 'Created Date',
      field: 'createdDate',
      suppressMenu: false,
      minWidth: 100,
    },
    {
      headerName: 'Action',
      cellRenderer: (props) => {
        if (props?.data?.inventoryId === 'odkEgmqfW3MDJJedc3QJ') return  <Box display="inline-flex" minHeight={24}></Box>;
        return <Box display="inline-flex" alignItems="center">
          <IconButton onClick={() => onRowClicked(props)} color='primary' size='small' variant="primary">
            <EditIcon width='16px' height='16px' />
          </IconButton>
          <IconButton onClick={() => dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: props.data?.inventoryId }, modal: 'DELETE_MODAL' }))} color='error' size='small' variant="primary" sx={{ marginLeft: 1 }}>
            <DeleteIcon width='16px' height='16px' />
          </IconButton>
        </Box>
      },
      cellStyle: { textAlign: 'right', justifyContent: 'end' },
      headerClass: 'right-aligned-cell-header',
      minWidth: 120,
      maxWidth: 100,
      pinned: 'right'
    },
  ]);

  const getRows = async (params) => {
    gridRefApi.current = params
    dispatch(fetchInventory({ skip: pageRef.current, limit: 100, userType: 'admin', searchTerm: searchTermRef.current })).unwrap().then(res => {
      const { inventories, total } = res
      const inventoryArrays = inventories?.map((item => {
        return { ...item, id: item._id, createdDate:  item?.createdDate ? formatDate(new Date(item?.createdDate)) :"-" }
      }))
      const dataAfterSortingAndFiltering = sortAndFilter(
        inventoryArrays,
        params.sortModel,
        params.filterModel
      );
         params.successCallback(dataAfterSortingAndFiltering, total);
      pageRef.current = pageRef.current + 20
    })
  }

  const onRowClicked = (row) => {
    navigate(`/inventories/${row?.data.inventoryId}`)
  }

  const searchFn = () => {
    const dataSource = {
      getRows
    }
    mainGridApiRef.current.ensureIndexVisible(0,null);
    mainGridApiRef.current.setGridOption('datasource', dataSource);
  }

  const searchHandler = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    searchTermRef.current = value
    handleSearch(value);

    // Debounce the search callback
  };

  const deleteHandler = (inventoryId) => {
    dispatch(setModalLoader(true))
    dispatch(deleteInventoryItem(inventoryId)).unwrap().then((res) => {
      showError(res?.message, 'success')
      dispatch(closeCustomModalDialog())
      pageRef.current = 0
      getRows(gridRefApi.current)
    }).catch((err) => {
      dispatch(setModalLoader(false))
      showError(err?.message, 'error')
    })
  }

  const showError = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
    }
  }

  useEffect(() => {
    if (mainGridApiRef?.current) {
      if (loading) {
        mainGridApiRef.current.showLoadingOverlay()
      } else if (inventory?.total == 0) {
        mainGridApiRef.current.showNoRowsOverlay()
      }
      else {
        mainGridApiRef.current.hideOverlay()
      }
    }

  }, [inventory, loading])

  return (
    <CommonLayout>
      <Box p={{ xs: 2, sm: 3, lg: 4 }}>
        <Grid container alignItems="center" rowSpacing={2} columnSpacing={2} mb={3}>
          <Grid item xs={12} sm={4}>
            <Typography variant='h4'>Inventory</Typography>
          </Grid>

          <Grid item xs={12} sm={8}>
            <Stack direction="row" justifyContent={{ xs: "start", sm: "end" }} flexWrap="wrap" alignItems="stretch" gap={2}>
              <Search placeholder="Search" label="" value={searchTerm} onChange={searchHandler} />
              <MDButton
                startIcon={<AddRoundedIcon fontSize='large' />}
                variant="contained" color="primary"
                onClick={() => navigate('/inventories/add')} >
                Add Inventory
              </MDButton>
            </Stack>
          </Grid>
        </Grid>

        <AgGridTable col={columnDefs} getRows={getRows} mainGridApiRef={mainGridApiRef} pageRef={pageRef} isLoading={loading} style={{ height: "70vh" }} />
      </Box>
    </CommonLayout>
  )

}