import { Card, Typography, Grid, Divider, Box, CardContent, Stack, Avatar, ListItemAvatar, List, ListItem, ListItemText } from '@mui/material'
import CommonLayout from 'layouts/DashboardLayout'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { fetchMemberOrders, fetchMembersDetails } from '../../../redux/slices/member'
import AngleRightIcon from 'common/customIcons/angle_right'
import MDButton from 'components/MDButton'
import EditIcon from 'common/customIcons/edit'
import colors from "assets/theme/base/colors";
import productImage from "assets/images/placeholder-image.png";
import moment from 'moment'
import { formatTimestampToDateView } from 'utilities/common'
import { addSpaceBeforeCapital } from 'utilities/common'
import VisibilityIcon from '@mui/icons-material/Visibility';
import { formatUsPhoneNumber } from 'utilities/common'
import Zoom from 'react-medium-image-zoom'
import InActiveIcon from 'common/customIcons/inactive'
import DeliveredIcon from 'common/customIcons/delivered'
import ReturnApprovedIcon from 'common/customIcons/returnApproved'
import ReturnRequestIcon from 'common/customIcons/returnRequest'
import InProgressIcon from 'common/customIcons/inprogress'
import { orderDetailStatus } from 'utilities/common'
import { returnRequestStatus } from 'utilities/common'
import { capitalizeText } from 'utilities/common'

export default function MemberDetails() {
    const { grey } = colors;
    const { memberId } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { memberDetails = {}, memberOrders = [], ordersTotal = 0 } = useSelector(state => state.members) || {}
    const {
        age,
        createdDate,
        verifyMethod,
        deliveryAddress = {},
        dob,
        email,
        fullName,
        gender,
        idImage,
        memberShipGroup,
        memberType,
        phone,
        walletAmount = 0
    } = memberDetails

    const orderStatusColor = {
        "delivered": "#04B072",
        // "return_approved": "#24CA49",
        "approved": "#24CA49",
        "return_request": "#000000",
        "cancelled": "#FD4438",
        "rejected": "#FD4438",
        "awaiting_shipment": "#8E0CF5",
        "shipped": "#24CA49",
    }

    const orderStatusIcons = {
        "delivered": <DeliveredIcon width="18px" height="18px" flex="none" />,
        "approved": <ReturnApprovedIcon width="18px" height="18px" flex="none" />,
        "return_request": <ReturnRequestIcon width="18px" height="18px" flex="none" />,
        "cancelled": <InActiveIcon width="18px" height="18px" flex="none" />,
        "awaiting_shipment": <InProgressIcon width="18px" height="18px" flex="none" />,
        "rejected": <InActiveIcon width="18px" height="18px" flex="none" />,
    }
    useEffect(() => {
        if (memberId) {
            dispatch(fetchMembersDetails(memberId))
            dispatch(fetchMemberOrders({ filters: {}, memberId }))
        }
    }, [memberId])
    return (
        <CommonLayout>
            <Box p={{ xs: 2, sm: 3, lg: 4 }}>
                <Box onClick={() => navigate(-1)} display="flex" alignItems="center" color='text.main' lineHeight={1} mb={3.5}>
                    <AngleRightIcon fontSize="14px" />
                    <Typography variant='h6' fontWeight="semibold" ml={.75}>Members</Typography>
                </Box>

                <Grid container spacing={2.5} mb={4}>
                    <Grid item xs={12} lg={7}>
                        <Card variant='detailsCard' sx={{ height: '100%', overflow: 'hidden' }}>
                            <CardContent sx={{ p: { xs: 2, md: 3.5 }, pb: 0 }}>
                                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={3} mb={3}>
                                    <Typography variant='h4'>Member Details</Typography>
                                    <MDButton
                                        type='button'
                                        variant='outlined'
                                        color='dark'
                                        size="small"
                                        startIcon={<EditIcon width='15px' height='15px' />}
                                        sx={{ borderRadius: 1.5 }}
                                        onClick={() => navigate(`/members/${memberId}`)}
                                    >
                                        Edit
                                    </MDButton>
                                </Stack>
                                <Stack direction="row" spacing={1.25} mb={4}>
                                    <Zoom classDialog='custom-zoom'> <img style={{ borderRadius: "20px", width: "108px", height: "108px", objectFit: "contain" }} src={idImage || productImage} alt="Product Name" /></Zoom>
                                </Stack>

                                <Grid container alignItems="center" spacing={3.5} mb={1}>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant='body2' color={grey[600]}>Name</Typography>
                                        <Typography variant='h6' fontWeight="medium" sx={{ textTransform: 'capitalize' }}>{fullName?.toLowerCase() || '-'}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant='body2' color={grey[600]}>Email</Typography>
                                        <Typography variant='h6' fontWeight="medium">{email}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant='body2' color={grey[600]}>Age</Typography>
                                        <Typography variant='h6' fontWeight="medium">{age}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant='body2' color={grey[600]}>Gender</Typography>
                                        <Typography variant='h6' fontWeight="medium" sx={{ textTransform: 'capitalize' }}>{gender?.toLowerCase() || '-'}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant='body2' color={grey[600]}>DOB</Typography>
                                        <Typography variant='h6' fontWeight="medium">{dob ? moment(dob).format('MM/DD/YYYY') : '-'}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant='body2' color={grey[600]}>Phone</Typography>
                                        <Typography variant='h6' fontWeight="medium">{phone ? formatUsPhoneNumber(phone) : '-'}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant='body2' color={grey[600]}>Member ID</Typography>
                                        <Typography variant='h6' fontWeight="medium">{memberId}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant='body2' color={grey[600]}>Member Type</Typography>
                                        <Typography variant='h6' fontWeight="medium">{memberType ? addSpaceBeforeCapital(memberType) : '-'}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant='body2' color={grey[600]}>Membership Group</Typography>
                                        <Typography variant='h6' fontWeight="medium">{memberShipGroup ? addSpaceBeforeCapital(memberShipGroup) : '-'}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant='body2' color={grey[600]}>Source</Typography>
                                        <Typography variant='h6' fontWeight="medium">{verifyMethod ? verifyMethod === 'Site' ? 'Website' : capitalizeText(verifyMethod) || 'Admin' : '--'}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant='body2' color={grey[600]}>Created on</Typography>
                                        <Typography variant='h6' fontWeight="medium">{createdDate ? formatTimestampToDateView(createdDate) : '-'}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant='body2' color={grey[600]}>Total Number of Orders</Typography>
                                        <Typography variant='h6' fontWeight="medium">{ordersTotal}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant='body2' color={grey[600]}>Wallet</Typography>
                                        <Typography variant='h6' fontWeight="medium">{walletAmount ? "$" + walletAmount : "$0"}</Typography>
                                    </Grid>
                                    {
                                        memberDetails?.recId && <Grid item xs={12} md={6}>
                                            <Typography variant='caption' color={grey[600]}>Rec ID</Typography>
                                            <Link to={memberDetails?.recId}>
                                                <a target='_blank'>
                                                    <Typography variant='h6' fontWeight="medium" lineHeight="1.25">{memberDetails?.recId || '-'}</Typography>
                                                </a>
                                            </Link>
                                        </Grid>
                                    }

                                    <Grid item xs={12} mt={2}>
                                        <Typography variant='body2' fontWeight="semibold" mb={1} color={grey[600]}>Member Address</Typography>
                                        <Typography variant='h6' fontWeight="regular" lineHeight="1.85">
                                            {
                                                deliveryAddress?.apartment && <>

                                                    <b style={{ fontWeight: 600 }}>Apartment Number: </b>{deliveryAddress.apartment || '-'} <br />
                                                </>
                                            }
                                            <b style={{ fontWeight: 600 }}>Address: </b>{deliveryAddress.address} <br />
                                            <b style={{ fontWeight: 600 }}>City: </b>{deliveryAddress.city} <br />
                                            <b style={{ fontWeight: 600 }}>State: </b>{deliveryAddress.state} <br />
                                            <b style={{ fontWeight: 600 }}>Zip Code: </b>{deliveryAddress.zipcode}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} lg={5}>
                        <Card variant='detailsCard' sx={{ height: '100%', overflow: 'hidden', rowGap: '20px', p: { xs: 2, md: 3.5 } }}>
                            <CardContent sx={{ px: 0, pt: 0, pb: { xs: 2, md: 3 }, mb: '-20px' }}>
                                <Stack justifyContent='space-between' direction='row' alignItems='center'>
                                    <Typography variant='h4'>Recent Orders</Typography>
                                    {
                                        memberOrders?.length > 3 && <Link to={`/orders/member/${memberId}?orderFilter=`}>
                                            <MDButton
                                                type='button'
                                                variant='outlined'
                                                color='dark'
                                                size="small"
                                                startIcon={<VisibilityIcon width='15px' height='15px' />}
                                                sx={{ borderRadius: 1.5 }}
                                            >
                                                All Orders
                                            </MDButton>
                                        </Link>
                                    }
                                </Stack>

                            </CardContent>

                            {
                                memberOrders?.length > 0 ? memberOrders?.slice(0, 4)?.map((orders => {
                                    const { createdDate, items: orderItems = [], orderId } = orders;
                                    return <CardContent key={orderId} sx={{ backgroundColor: "#F5F5F5", p: 0, overflow: "hidden", borderRadius: 2, borderWidth: 1, borderStyle: "solid", borderColor: orders?.returnRequestStatus && !orders?.warrantyStatus ? orderStatusColor['return_request'] : orders?.returnRequestStatus && orders?.warrantyStatus ? orderStatusColor[orders?.warrantyStatus] : orderStatusColor[orders?.emailStatus] }} style={{ paddingBottom: 0 }}>
                                        {
                                            !orders?.returnRequestStatus && !orders?.warrantyStatus !== 'rejected' && <>
                                                <Box backgroundColor={orderStatusColor[orders?.emailStatus]} color="#fff" display="flex" alignItems="center" justifyContent="center" p={0.5}>
                                                    {
                                                        orderStatusIcons[orders?.emailStatus]
                                                    }
                                                    <Typography variant='caption' fontWeight="medium" ml={.5} textTransform="capitalize">{orderDetailStatus?.find((item => item?.key === orders?.emailStatus))?.value}</Typography>
                                                </Box>

                                            </>
                                        }

                                        {
                                            orders?.returnRequestStatus && orders?.warrantyStatus && <Box backgroundColor={orderStatusColor[orders?.warrantyStatus]} color="#fff" display="flex" alignItems="center" justifyContent="center" p={0.5}>
                                                {
                                                    orderStatusIcons[orders?.warrantyStatus]
                                                }
                                                <Typography variant='caption' fontWeight="medium" ml={.5} textTransform="capitalize">Return {returnRequestStatus?.find((item => item?.key === orders?.warrantyStatus))?.value}</Typography>
                                            </Box>
                                        }

                                        {
                                            orders?.returnRequestStatus && !orders?.warrantyStatus && <Box backgroundColor={orderStatusColor['return_request']} color="#fff" display="flex" alignItems="center" justifyContent="center" p={0.5}>
                                                {
                                                    orderStatusIcons['return_request']
                                                }
                                                <Typography variant='caption' fontWeight="medium" ml={.5} textTransform="capitalize">Return Request</Typography>
                                            </Box>
                                        }



                                        <Box px={3} pt={2} pb={2.5}>
                                            <Stack direction={{ xs: "column", md: "row" }} alignItems="center" justifyContent="space-between" flexWrap="wrap">
                                                <Typography variant='body2' fontWeight="semibold" mr={2}>#{orderId}</Typography>
                                                <Typography variant='body2' fontWeight="medium">{createdDate ? formatTimestampToDateView(createdDate) : "-"}</Typography>
                                            </Stack>
                                            <Divider />
                                            <List>
                                                {
                                                    orderItems?.map((order => {
                                                        const { product = {}, quantity } = order;
                                                        return <>
                                                            <ListItem key={product.productId} alignItems="center" sx={{ mt: 2.5 }}>
                                                                <ListItemAvatar sx={{ mr: 1.5 }} className="pro-avatar-sec">
                                                                    <Avatar
                                                                        alt="Product Name"
                                                                        src={product?.productImages?.[0]}
                                                                        sx={{ width: 82, height: 82, borderRadius: 4 }}
                                                                    />
                                                                </ListItemAvatar>
                                                                <ListItemText
                                                                    primary={
                                                                        <React.Fragment>
                                                                            <Typography variant="body2" fontWeight="medium" color={grey[600]}>{product?.brandName || "-"}</Typography>
                                                                            <Typography variant="h6" fontWeight="medium">{product?.productName || "-"}</Typography>
                                                                        </React.Fragment>
                                                                    }
                                                                    secondary={
                                                                        <React.Fragment>
                                                                            <Typography variant="h6" fontWeight="medium" mt={1}>x{quantity}</Typography>
                                                                        </React.Fragment>
                                                                    }
                                                                />

                                                            </ListItem>
                                                            <Divider />
                                                        </>
                                                    }))
                                                }
                                            </List>
                                        </Box>
                                    </CardContent>

                                })) : <Box height={"100%"} width="100%" display='flex' justifyContent='center' alignItems='center'>
                                    <Typography variant='body2'>No Orders Found</Typography>
                                </Box>
                            }
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </CommonLayout>
    )
}
