import { Box, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

export default function FilterApproval(props = {}) {
    const location = useLocation()
    const navigate = useNavigate()
    const [ formData, setFormData] = useState({ notificationStatus: ''})
    const { applyApprovalFilter} = props

    useEffect(() => {
        if (formData.notificationStatus) {
            applyFiltersHandler();
        }
    }, [formData.notificationStatus]);

    const applyFiltersHandler = () => {
        const { notificationStatus } = formData || {}
        let queryPayload =  {
            notificationStatus: notificationStatus
        }
        const currentParams = new URLSearchParams(location.search);

        if (queryPayload.notificationStatus === 'all') {
            queryPayload = { ...queryPayload, notificationStatus: '' }
        }

        Object.keys(queryPayload).forEach((key) => {
            if (typeof queryPayload[key] === 'object') {
                currentParams.set(key, JSON.stringify(queryPayload[key]));
            } else {
                currentParams.set(key, queryPayload[key]);
            }

            currentParams.set('driverFilter=approvals', '');
        const urlWithParams = `${location.pathname}?${currentParams.toString()}`;
            navigate(urlWithParams)
            applyApprovalFilter({ queryPayload })
        });
    }

    const handleStatusChange = (e) => {
        const newStatus = e.target.value;
        setFormData(prevData => ({...prevData, notificationStatus: newStatus}));
    }


    return (
        <Box className="cus-filter-sec" mb={1}>
            <Grid className='filter-box' container alignItems="center" justifyContent="flex-end" spacing={1.5}>
            <Grid item xs={12} sm={4} md={4} xxl={2}>
            <Box className="filter-pro-status">
            <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select  size='small' value={formData.notificationStatus} onChange={handleStatusChange} fullWidth className='form-select' name='notificationStatus' label='Status'>
                <MenuItem value='all'>All</MenuItem>
                <MenuItem value='pending' >Pending</MenuItem>
                <MenuItem value='approved' >Approved</MenuItem>
                <MenuItem value='declined' >Declined</MenuItem>
            </Select>   
            </FormControl>
            </Box>
            </Grid>
            </Grid>
        </Box>
    )
}