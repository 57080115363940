import CommonLayout from 'layouts/DashboardLayout'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Checkbox, Grid, IconButton, InputAdornment, Slide, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { AgGridTable } from 'components/tables/AgGridTable'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import MDButton from 'components/MDButton'
import { formatDate } from 'utilities/common'
import { CloseRounded } from '@mui/icons-material'
import { closeCustomModalDialog, openCustomModalDialog, setModalLoader } from '../../../redux/slices/modalSlice'
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useDebounce } from 'hooks/useDebounce'
import EditIcon from 'common/customIcons/edit'
import DeleteIcon from 'common/customIcons/delete'
import StatusText from 'common/component/StatusText'
import { openSnackbar } from '../../../redux/slices/common'
import FilterDriver from 'common/component/Hyperwolf/DriverFilters'
import { deleteDriver, fetchDrivers, exportCsv } from '../../../redux/slices/hyperwolf/driver/driver'
import { capitalizeText } from 'utilities/common'
import Break from '../Break'
import Approval from '../Approval'
import { CSVLink } from 'react-csv'
import MDBox from 'components/MDBox'
import Papa from 'papaparse';
import { fetchDriverApprovals } from '../../../redux/slices/hyperwolf/driver/approvals'
import SearchIcon from 'common/customIcons/search'

export default function Driver() {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const pageRef = useRef(0)
  const searchTermRef = useRef("")
  const mainGridApiRef = useRef(null)
  const { search } = useLocation()
  const gridRefApi = useRef(null)
  const [searchTerm, setSearchTerm] = useState("")
  const [currentTab, setCurrentTab] = useState('')
  const [searchParams, setSearchParams] = useSearchParams(search)
  const driversFiltersPayload = useRef({})
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [csv, setCsv] = useState([])
  const { adminDrivers = {}, loading = false } = useSelector(state => state.adminDrivers)

  const [showBulkDelete, setShowBulkDelete] = useState(false)

  useEffect(() => {
    dispatch(fetchDriverApprovals())
  }, [])


  useEffect(() => {
    if (search) {
      const selectedTab = searchParams.get('driverFilter');
      if (selectedTab !== currentTab) {
        navigate(`/hyperwolf/driver?driverFilter=${selectedTab}`)
        setCurrentTab(selectedTab)
        pageRef.current = 0
      }
    }
    else {
      navigate('/hyperwolf/driver?driverFilter=')
      setCurrentTab('')
    }
  }, [search])



  const parseCsv = (csv) => {
    return new Promise((resolve, reject) => {
      Papa.parse(csv, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          // Resolve with the parsed data
          resolve(result.data);
        },
        error: (error) => {
          console.error('Error parsing CSV:', error);
          reject(error);
        }
      });
    });
  };

  const handleCsvExport = async () => {
    try {
      const res = await dispatch(exportCsv()).unwrap()
      const parseData = await parseCsv(res?.data)
      setCsv(parseData)
    } catch (error) {
      console.error('Error handling CSV export or parsing:', error);
    }
  }

  useEffect(() => {
    handleCsvExport()
  }, [])


  const resetData = () => {
    pageRef.current = 0
    if (mainGridApiRef.current) {
      mainGridApiRef.current.forEachNode((node) => {
        node.setSelected(false);
      });
    }

    searchFn()
  }

  const bulkDelete = () => {
    const selectedRows = mainGridApiRef.current.getSelectedRows();
    const selectedIds = selectedRows.map(row => row._id);
    if (selectedIds?.length > 0) {

      setAnchorEl(null)
      dispatch(openCustomModalDialog({
        modalData: { "fleetIds": selectedIds, resetData }, modal: 'BULK_DELETE_DRIVER'
      }))
    }
  }

  const handleSearch = useDebounce((term) => {
    pageRef.current = 0
    searchFn()
  }, 500);

  const applyDriverFilter = (filterPayload) => {
    pageRef.current = 0
    driversFiltersPayload.current = filterPayload

    const dataSource = {
      getRows: (params) => {
        const page = params.endRow / 20;
        dispatch(fetchDrivers({ skip: pageRef.current, limit: 20, search: searchTermRef.current, ...filterPayload?.queryPayload })).unwrap()
          .then(res => {
            const { fleets, total } = res?.data
            const driversArray = fleets?.map((item => {
              const date = item?.createdDate ? new Date(item?.createdDate) : ""
              return {
                ...item, terminal: item?.terminalData?.terminalName, fleetStatus: item?.fleetStatus === "pending" ? "Pending" : item.fleetStatus === "active" ? "Active" : "inactive", region: item?.regionData?.regionName, joinedDate: date ? formatDate(date) : "-", id: item.memberId, address: `${item?.deliveryAddress?.address} ${item?.deliveryAddress?.city} ${item?.deliveryAddress?.state} ${item?.deliveryAddress?.zipcode}`
              }
            }))

            params.successCallback([...driversArray], total);
            pageRef.current = pageRef.current + 20
          })
      }
    }
    if (mainGridApiRef?.current) {
      mainGridApiRef.current.ensureIndexVisible(0, null);
      mainGridApiRef.current.setGridOption('datasource', dataSource);
    }
  }

  const deleteHandler = (deleteId) => {

    dispatch(setModalLoader(true))
    dispatch(deleteDriver({ fleetId: deleteId })).unwrap().then((res) => {
      showError(res?.data.message, 'success')
      dispatch(closeCustomModalDialog())
      searchFn()
      pageRef.current = 0
      getRows(gridRefApi.current)
    }).catch((err) => {
      dispatch(setModalLoader(false))
      showError(err?.message, 'error')
    })
  }


  const showError = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
    }
  }

  const selectAllHandler = (e) => {

    if (e.target.checked) {
      mainGridApiRef.current.forEachNode((node) => {
        node.setSelected(true);
      });
    } else {
      mainGridApiRef.current.forEachNode((node) => {
        node.setSelected(false);
      });
    }
  }

  const myComponent = (props) => {
    return <>
      <Stack direction='row' gap={2}>
        <Checkbox size='medium' sx={{ margin: '0px -6px' }} onChange={selectAllHandler} />
        <Typography variant='subtitle1' sx={{ fontSize: '12px', color: '#656575', lineHeight: 16, fontWeight: 400 }}>{props?.displayName}</Typography>
      </Stack>
    </>
  }

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: 'Driver ID',
      field: 'fleetDisplayId',
      checkboxSelection: true,
      suppressMenu: true,
      headerComponent: myComponent,
      wrapText: true,
      autoHeight: true,
      cellRenderer: (props) => {
        return <Tooltip title={capitalizeText(props?.data?.productName)} arrow placement="top">
          <Box display="flex" alignItems="center" sx={{ cursor: "pointer" }}>
            <MDBox textAlign="left" lineHeight={1.75} ml={1.25} mt={.125}>
              <Typography variant="h6" color="dark" lineHeight={1.25} fontWeight="semibold" className="text-14 truncate line-1">{(props?.data?.fleetDisplayId)}</Typography>
              <Typography className='text-12' variant="p" color="secondary" lineHeight={1.25}>{props?.data?.sku}</Typography>
            </MDBox>
          </Box>
        </Tooltip>
      },
      minWidth: 140,
    },
    {
      headerName: 'Driver Name',
      field: 'fleetName',
      key: "fleetName",
      suppressMenu: false,
      cellRenderer: (props) => {
        return <Typography variant="caption" display="block" fontWeight="regular" sx={{ cursor: "pointer", whiteSpace: "normal", textTransform: 'capitalize' }}>{props?.data?.fleetName}</Typography>
      },
      minWidth: 240,
      sortable: false
    },
    {
      headerName: 'Transportation',
      field: 'transportationDetail',
      suppressMenu: false,
      minWidth: 140,
      sortable: false,
      cellRenderer: (props) => {
        return props?.value?.name
      }
    },
    {
      headerName: 'Region',
      field: 'region',
      suppressMenu: false,
      minWidth: 200,
      sortable: false
    },
    {
      headerName: 'Terminal',
      suppressMenu: false,
      minWidth: 200,
      cellRenderer: (props) => {
        return <Tooltip title={props?.data?.terminalData.terminalName} arrow placement="top">
          <Typography className="truncate line-2" variant="caption" display="block" fontWeight="regular" sx={{ whiteSpace: "normal" }}>{props?.data?.terminalData.terminalName || '-'}</Typography>
        </Tooltip>
      },
      sortable: false
    },
    {
      headerName: 'Created Date',
      field: 'joinedDate',
      suppressMenu: false,
      minWidth: 150,
      sortable: false
    },
    {
      headerName: 'Created By',
      field: 'cratedBy',
      suppressMenu: false,
      minWidth: 120,
      cellRenderer: (props) => {
        return (props?.data?.adminDetail?.name)
      },
      minWidth: 120,
      sortable: false
    },
    {
      headerName: 'Status',
      field: 'fleetStatus',
      suppressMenu: false,
      cellRenderer: (props) => {
        if (props.data?.fleetStatus !== "pending") {
          return <StatusText status={props?.data?.fleetStatus} />
        }
      },
      minWidth: 100,
      sortable: false
    },
    {
      headerName: 'Actions',
      cellRenderer: (props) => {

        return <Box display="inline-flex" alignItems="center">
          <IconButton onClick={(e) => {
            e.stopPropagation()
            onEditClicked(props)
          }} color='primary' size='small' variant="primary">
            <EditIcon width='16px' height='16px' />
          </IconButton>
          <IconButton onClick={(e) => {
            e.stopPropagation()
            dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: props.data?._id, deleteType: 'driver' }, modal: 'DELETE_MODAL' }))
          }} color='error' size='small' variant="primary" sx={{ marginLeft: 1 }}>
            <DeleteIcon width='16px' height='16px' />
          </IconButton>
        </Box>
      },
      sortable: false,
      cellStyle: { textAlign: 'right', justifyContent: 'end' },
      headerClass: 'right-aligned-cell-header',
      minWidth: 120,
      maxWidth: 100,
      pinned: 'right'
    },

  ]);

  const getRows = async (params) => {
    gridRefApi.current = params;
    let filter = {}
    const sortModel = params?.sortModel
    let sortedColumnName = '';
    let sortDirection = ''
    if (sortModel.length > 0) {
      const sortedColumn = sortModel[0];
      sortedColumnName = sortedColumn.colId;
      sortDirection = sortedColumn.sort === 'asc' ? 'low' : 'high'
    }
    dispatch(fetchDrivers({ skip: pageRef.current, limit: 20, search: searchTermRef.current, ...filter, ...driversFiltersPayload.current.queryPayload })).unwrap().then(res => {
      const { fleets, total } = res?.data

      const driversArray = fleets?.map((item => {
        const date = item?.createdDate ? new Date(item?.createdDate) : ""
        return {
          ...item, fleetStatus: item?.fleetStatus === "pending" ? "Pending" : item.fleetStatus === "active" ? "Active" : "inactive", region: item?.regionData?.regionName, terminal: item?.terminalData?.terminalName, joinedDate: date ? formatDate(date) : "-", id: item._id, address: `${item?.deliveryAddress?.address} ${item?.deliveryAddress?.city} ${item?.deliveryAddress?.state} ${item?.deliveryAddress?.zipcode}`
        }
      }))

      params.successCallback([...driversArray], total);
      pageRef.current = pageRef.current + 20
    }).catch((error) => {
      console.error("Error fetching rows:", error);
      params.successCallback([], 0)
    })

  }

  const searchFn = () => {
    const dataSource = {
      getRows
    }
    mainGridApiRef.current.ensureIndexVisible(0, null);
    mainGridApiRef.current.setGridOption('datasource', dataSource);
  }

  const onRowClicked = (row) => {
    if (row?.colDef?.headerName !== 'Driver ID' && row?.colDef?.headerName !== 'Actions' && row?.colDef?.headerName !== 'Notes') {
      navigate(`/hyperwolf/driver/details/${row.data._id}`)
    }
  }


  const onEditClicked = (row) => {
    navigate(`/hyperwolf/driver/${row.data._id}`)
  }

  const searchHandler = (searchValue) => {
    setSearchTerm(searchValue);
    searchTermRef.current = searchValue
    handleSearch(searchValue);
  };

  useEffect(() => {

    if (mainGridApiRef?.current) {
      if (loading) {
        mainGridApiRef.current.showLoadingOverlay()
      }
      else if (adminDrivers?.data.total == 0) {
        mainGridApiRef.current.showNoRowsOverlay()
      }
      else {
        mainGridApiRef.current.hideOverlay()
      }
    }

  }, [adminDrivers, loading])

  const onSelectionChanged = () => {
    const selectedRows = mainGridApiRef.current.getSelectedRows();
    if (selectedRows?.length > 0) {
      setShowBulkDelete(true)
    } else {
      setShowBulkDelete(false)
    }
  }

  return (<>
    {currentTab === 'breaks' ? <Break /> : currentTab === 'approvals' ? <Approval /> :
      <CommonLayout>

        <Box p={{ xs: 2, sm: 3, lg: 4 }} className="row-cursor">

          <Grid container alignItems="center" rowSpacing={2} columnSpacing={2} mt={1} mb={3}>
            <Grid item xs={12}>
              <div className='w-100 d-flex align-item-center jc-s-btwn gap-2 page-res-head'>
                <Typography variant='h4'>Drivers</Typography>
                <Stack direction="row" justifyContent={{ xs: "start", sm: "end" }} flexWrap="wrap" alignItems="stretch" gap={2}>
                  <TextField className="custom-search-field" placeholder="Search" label="" value={searchTerm} onChange={(e) => searchHandler(e.target.value)}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">
                        <IconButton size='small'>
                          <SearchIcon width='18px' height='18px' />
                        </IconButton>
                      </InputAdornment>,
                      endAdornment: searchTerm?.length > 0 ? <InputAdornment>
                        <IconButton onClick={() => searchHandler('')} size='small'>
                          <CloseRounded />
                        </IconButton>
                      </InputAdornment> : null
                    }}
                  />
                  <MDButton
                    startIcon={<FileUploadOutlinedIcon fontSize='large' />}
                    variant="outlined" color="primary"
                    sx={{ minWidth: "165px" }}
                    onClick={() => dispatch(openCustomModalDialog({
                      modal: "IMPORT_DRIVERS", modalData: {
                        resetData
                      }
                    }))}
                  >
                    Import
                  </MDButton>
                  <CSVLink data={csv} style={{ color: "#ffffff", textTransform: "none" }}>
                    <MDButton
                      startIcon={<FileDownloadOutlinedIcon fontSize='large' />}
                      variant="contained" color="primary"
                      sx={{ minWidth: "165px", backgroundColor: "#656575", BorderRadius: "30px" }}
                      onClick={handleCsvExport}
                      style={{ minHeight: "40px" }}
                    >
                      Export as .CSV
                    </MDButton>
                  </CSVLink>
                  {showBulkDelete &&
                    <MDButton disabled={!showBulkDelete} onClick={() => bulkDelete()} variant="contained" color="warning" sx={{ minWidth: "170px" }}>
                      Bulk Delete
                    </MDButton>
                  }
                  <MDButton
                    startIcon={<AddRoundedIcon fontSize='large' />}
                    variant="contained" color="primary"
                    onClick={() => navigate('/hyperwolf/driver/create')}
                    sx={{ minWidth: "170px" }}
                  >
                    Add Driver
                  </MDButton>
                </Stack>
              </div>
            </Grid>
          </Grid>
          <FilterDriver applyDriverFilter={applyDriverFilter} />

          <AgGridTable
            onSelectionChanged={onSelectionChanged}
            col={columnDefs}
            mainGridApiRef={mainGridApiRef}
            getRows={getRows}
            pageRef={pageRef}
            isLoading={loading}
            onRowClicked={onRowClicked}
            style={{ height: "62vh" }}
            maxLimit={20}
          />

        </Box>
      </CommonLayout>

    }
  </>
  )
}