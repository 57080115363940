import CommonLayout from 'layouts/DashboardLayout'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Grid, IconButton, InputAdornment, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { AgGridTable } from 'components/tables/AgGridTable'
import { useDebounce } from 'hooks/useDebounce'
import { CloseRounded } from '@mui/icons-material'
import SearchIcon from 'common/customIcons/search'
import { rolesAndPermissionList } from '../../redux/slices/roleAndPermissions'

export default function Roles() {
    const dispatch = useDispatch()
    const pageRef = useRef(0)
    const searchTermRef = useRef("")
    const mainGridApiRef = useRef('')
    const gridRefApi = useRef(null)
    const [searchTerm, setSearchTerm] = useState("")
    const { roles = {}, isLoading: loading = false } = useSelector(state => state.roleAndPermissions)

    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: 'Role',
            field: 'Role',
            suppressMenu: false,
            minWidth: 160,
            flex: 0.5
        },
        {
            headerName: 'Count',
            field: 'count',
            suppressMenu: false,
            minWidth: 160,
            flex: 0.4
        },
        {
            headerName: 'Permissions',
            field: 'permissions',
            suppressMenu: false,
            cellRenderer: (props) => {
                return props?.value?.length > 0 ? <Tooltip title={props?.value?.join(', ')} arrow placement="top"><Box mr={2} className='text-truncate'>{props?.value?.join(', ')}</Box></Tooltip> : '-'
            },
            minWidth: 400,
            flex: 2
        },
    ]);

    function getSortInfo(data) {
        const result = {};
        data.forEach(item => {
            if (item?.colId === 'memberType') {
                result[item.colId] = item.sort === "asc" ? 'AdultUse' : "MedicinalUser";
            } else {
                result[item.colId] = item.sort === "asc";
            }

        });

        return result;
    }

    const getRows = async (params) => {
        gridRefApi.current = params;
        let filter = {}
        if (params?.sortModel.length) {
            filter = { ...getSortInfo(params?.sortModel) }
        }
        // params.successCallback(memberships, 15);
        dispatch(rolesAndPermissionList({ skip: pageRef.current, limit: 20, searchTerm: searchTermRef.current, ...filter })).unwrap().then(res => {
            const { roles, total } = res
            params.successCallback(roles, total);
            pageRef.current = pageRef.current + 20
        }).catch(() => {
            params.successCallback([], 0);
        })
    }

    const searchFn = () => {
        const dataSource = {
            getRows
        }
        mainGridApiRef.current.ensureIndexVisible(0, null);
        mainGridApiRef.current.setGridOption('datasource', dataSource);
    }

    useEffect(() => {
        if (mainGridApiRef?.current) {
            if (loading) {
                mainGridApiRef.current.showLoadingOverlay()
            } else if (roles?.total == 0) {
                mainGridApiRef.current.showNoRowsOverlay()
            }
            else {
                mainGridApiRef.current.hideOverlay()
            }
        }

    }, [roles, loading])

    const handleSearch = useDebounce((term) => {
        // Perform search operation with the debounced term
        pageRef.current = 0
        searchFn()
    }, 500);

    const searchHandler = (searchValue) => {
        setSearchTerm(searchValue);
        searchTermRef.current = searchValue
        handleSearch(searchValue);
        // Debounce the search callback
    };
    return (
        <CommonLayout>
            <Box p={{ xs: 2, sm: 3, lg: 4 }}>
                <Grid container alignItems="center" rowSpacing={2} columnSpacing={2} mb={3}>
                    <Grid item xs={12} sm={4}>
                        <Typography variant='h4'>Roles</Typography>
                    </Grid>

                    <Grid item xs={12} sm={8}>
                        <Stack direction="row" justifyContent={{ xs: "start", sm: "end" }} flexWrap="wrap" alignItems="stretch" gap={2}>
                            <TextField className="custom-search-field" placeholder="Search" label="" value={searchTerm} onChange={(e) => searchHandler(e.target.value)}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">
                                        <IconButton size='small'>
                                            <SearchIcon width='18px' height='18px' />
                                        </IconButton>
                                    </InputAdornment>,
                                    endAdornment: searchTerm?.length > 0 ? <InputAdornment>
                                        <IconButton onClick={() => searchHandler('')} size='small'>
                                            <CloseRounded />
                                        </IconButton>
                                    </InputAdornment> : null
                                }}
                            />
                        </Stack>
                    </Grid>
                </Grid>

                <AgGridTable col={columnDefs} getRows={getRows} mainGridApiRef={mainGridApiRef} pageRef={pageRef} isLoading={loading} style={{ height: "70vh" }}
                />
            </Box>
        </CommonLayout>
    )
}