import React from 'react'

export default function TaskTruckIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path d="M10.2686 2H0.518999C0.231589 2 0.00585938 2.22888 0.00585938 2.52029V8.76377C0.00585938 9.04988 0.231589 9.28406 0.518999 9.28406H10.2686C10.5509 9.28406 10.7818 9.04988 10.7818 8.76377V2.52029C10.7818 2.22888 10.5509 2 10.2686 2Z" fill="#FFDB1E"/>
      <path d="M0 11.886C0 12.1721 0.230913 12.4063 0.513139 12.4063H1.06733C1.28285 11.3189 2.16545 10.4656 3.26357 10.3251H0.518271C0.333489 10.3251 0.164256 10.2939 0 10.2314V11.886Z" fill="#FFDB1E"/>
      <path d="M14.8503 12.4059H15.4866C15.7688 12.4059 15.9998 12.1717 15.9998 11.8856V7.32788C15.9998 6.51098 14.0344 4.60156 12.9569 4.60156H11.8075V8.76389C11.8075 9.62231 11.1147 10.3248 10.268 10.3248H3.93066C5.02883 10.4652 5.91143 11.3185 6.1269 12.4059H9.79071C10.0268 11.2092 11.0685 10.2987 12.3205 10.2987C13.5675 10.2987 14.6143 11.2092 14.8503 12.4059ZM12.6489 5.88142C15.2762 5.75135 15.1685 8.50369 15.1685 8.50369H12.6489V5.88142Z" fill="#FFDB1E"/>
      <path d="M12.3182 14.4844C13.1741 14.4844 13.8679 13.7809 13.8679 12.9131C13.8679 12.0453 13.1741 11.3418 12.3182 11.3418C11.4624 11.3418 10.7686 12.0453 10.7686 12.9131C10.7686 13.7809 11.4624 14.4844 12.3182 14.4844Z" fill="#FFDB1E"/>
      <path d="M3.59558 14.4844C4.45144 14.4844 5.14526 13.7809 5.14526 12.9131C5.14526 12.0453 4.45144 11.3418 3.59558 11.3418C2.73971 11.3418 2.0459 12.0453 2.0459 12.9131C2.0459 13.7809 2.73971 14.4844 3.59558 14.4844Z" fill="#FFDB1E"/>
    </svg>
  )
}