import React from 'react'

export default function UserIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_5041_6419)">
        <path d="M7.88599 7.70728C8.94482 7.70728 9.86169 7.32751 10.6108 6.57825C11.36 5.8291 11.7397 4.91248 11.7397 3.85352C11.7397 2.79492 11.36 1.87817 10.6107 1.12878C9.86145 0.379761 8.9447 0 7.88599 0C6.82703 0 5.9104 0.379761 5.16125 1.12891C4.41211 1.87805 4.03223 2.7948 4.03223 3.85352C4.03223 4.91248 4.41211 5.82922 5.16138 6.57837C5.91064 7.32739 6.82739 7.70728 7.88599 7.70728Z" fill="#A9A9B7"/>
        <path d="M14.629 12.3031C14.6074 11.9913 14.5637 11.6512 14.4994 11.2921C14.4344 10.9303 14.3508 10.5883 14.2507 10.2756C14.1473 9.95251 14.0067 9.63342 13.8329 9.32764C13.6525 9.01025 13.4406 8.73389 13.2028 8.50647C12.9541 8.26855 12.6497 8.07727 12.2976 7.93774C11.9468 7.79895 11.558 7.72864 11.1421 7.72864C10.9788 7.72864 10.8208 7.79565 10.5157 7.99426C10.328 8.1167 10.1084 8.2583 9.86328 8.41492C9.65369 8.54846 9.36975 8.67358 9.01904 8.78687C8.67688 8.89758 8.32947 8.95374 7.98657 8.95374C7.64368 8.95374 7.29639 8.89758 6.95386 8.78687C6.60352 8.67371 6.31958 8.54858 6.11023 8.41504C5.86743 8.25989 5.64771 8.11829 5.45715 7.99414C5.15247 7.79553 4.99438 7.72852 4.83105 7.72852C4.41504 7.72852 4.02637 7.79895 3.67566 7.93787C3.32385 8.07715 3.01929 8.26843 2.77039 8.50659C2.53271 8.73413 2.32068 9.01038 2.1405 9.32764C1.9668 9.63342 1.82617 9.95239 1.72266 10.2758C1.62268 10.5884 1.53906 10.9303 1.47412 11.2921C1.40979 11.6508 1.36609 11.991 1.34448 12.3035C1.32324 12.6096 1.3125 12.9274 1.3125 13.2483C1.3125 14.0835 1.578 14.7596 2.10156 15.2583C2.61865 15.7504 3.30286 16 4.13489 16H11.839C12.671 16 13.355 15.7505 13.8722 15.2583C14.3959 14.76 14.6614 14.0837 14.6614 13.2482C14.6613 12.9258 14.6504 12.6078 14.629 12.3031Z" fill="#A9A9B7"/>
      </g>
      <defs>
        <clipPath id="clip0_5041_6419">
          <rect width="16" height="16.0001" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}