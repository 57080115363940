import { Autocomplete, Badge, Box, Card, Checkbox, CircularProgress, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import MDButton from 'components/MDButton'
import CommonLayout from 'layouts/DashboardLayout'
import React, { useEffect, useMemo, useState } from 'react'
import defaultImage from 'assets/images/placeholder-image.png';
import MDInput from 'components/MDInput'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { openSnackbar } from '../../../redux/slices/common'
import { uploadImage } from '../../../redux/slices/common'
import { validateStrainData } from 'validations/strain'
import { capitalizeText } from 'utilities/common'
import { createStrain, deleteStrain, getFlavoursList, getSingleStrain, updateStrain } from '../../../redux/slices/strain'
import { isEmptyObject } from 'utilities/common'
import { closeCustomModalDialog, openCustomModalDialog, setModalLoader } from '../../../redux/slices/modalSlice'
import CommonQuillEditor from 'common/editor'

export default function AddStrain() {
  const { flavors, singleStrain = {}, loading = false } = useSelector(state => state.strain)
  const { infoEffectData = [], isUploading = false } = useSelector(state => state.common)

  const { strainId } = useParams()
  const [formData, setFormData] = useState({
    strainName: '',
    strainCategory: '',
    metaTitle: '',
    metaDescription: '',
    title: '',
    description: '',
    infoEffects: [],
    isPopularStrain: false,
    isBestStrain: false,
    flavors: [],
    title2: '',
    status: true,
    image: ''
  });

  const [errors, setErrors] = useState({})
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { errors, isValid } = validateStrainData(formData)
    if (!isValid) {
      setErrors(errors)
      return;
    }
    setErrors({})

    if (strainId) {
      dispatch(updateStrain({ data: formData, strainId })).unwrap().then((res) => {
        showError(res?.message, 'success')
        navigate('/strains?orderFilter=strains')
      }).catch((err) => {
        showError(err?.response?.message, 'error')
      })
    } else {
      dispatch(createStrain(formData)).unwrap().then((res) => {
        showError(res?.message, 'success')
        navigate('/strains?orderFilter=strains')
      }).catch(() => {
        showError('Something went wrong', 'error')
      })
    }
  };

  const showError = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
    }
  }
  
  const infoEffectArr = useMemo(() => {
    return infoEffectData?.find((effect => effect?.strainType === formData.strainCategory))?.infoEffects || []
  }, [formData.strainCategory, infoEffectData])


  const deleteHandler = (strainId) => {
    dispatch(setModalLoader(true))
    dispatch(deleteStrain(strainId)).unwrap().then((res) => {
      showError(res?.message, 'success')
      dispatch(closeCustomModalDialog())
      navigate('/strains?orderFilter=strains')
    }).catch((err) => {
      dispatch(setModalLoader(false))
      showError(err?.message, 'error')
    })
  }

  const onChangeCheckbox = (e, name) => {
    e.preventDefault();
    let updatedEffects = [...(formData?.infoEffects || [])]
    let alreadyExist = updatedEffects.findIndex(v => v === name)
    if (alreadyExist === -1) {
      updatedEffects.push(name)
    } else {
      updatedEffects.splice(alreadyExist, 1)
    }
    setFormData({ ...formData, infoEffects: updatedEffects })
  }

  const strainImageUploader = async (e) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      const body = new FormData()
      body.append("filePath", file);
      // res.fileUrl
      dispatch(uploadImage(body)).unwrap().then((res => {
        setFormData({ ...formData, image: res.fileUrl })
      }))
    }
  }

  useEffect(() => {
    dispatch(getFlavoursList())
  }, [])

  useEffect(() => {
    if (!isEmptyObject(singleStrain)) {
      setFormData({ ...formData, ...singleStrain })
    }
  }, [singleStrain])
  useEffect(() => {
    if (strainId) {
      dispatch(getSingleStrain(strainId))
    }
  }, [strainId])

  const uploadThumbnailImage = {
    borderRadius: "20px",
    width: "160px",
    height: "160px",
    objectFit: "cover",
    boxShadow: "0 0 1px rgb(0 0 0 / 20%)"
  };
  const badgeCircularProgressIcon = {
    backgroundColor: "#fff",
    borderRadius: 10,
    p: .5,
    mr: 14,
    mt: 14
  };

  return (
    <CommonLayout>
      <Box p={{ xs: 2, sm: 3, lg: 4 }}>
        <Card sx={{ p: { xs: 2, md: 3 }, mb: 4, borderRadius: 2.5, boxShadow: "none" }}>
          <Stack direction={{ xs: "column", sm: "row" }} alignItems={{ sm: "center" }} justifyContent="space-between" gap={2} mb={4.5}>
            <Typography variant='h4' fontWeight="semibold" color="secondary">Add Strain</Typography>

            <Box display="flex" alignItems="center" gap={1.25}>
              <Typography variant='h5' fontWeight="semibold" color="secondary">Status</Typography>
              <Stack direction="row" alignItems="center" gap={.75} lineHeight={1} p={.75} borderRadius={2} bgcolor="#F2F3F7">
                <MDButton
                  sx={{ minWidth: "92px", borderRadius: 1.75, minHeight: "28px", p: .25, fontWeight: 500 }}
                  type='button'
                  variant={formData?.status ? 'contained' : 'text'}
                  color={formData?.status ? 'success' : 'dark'}
                  size="small"
                  onClick={() => setFormData({ ...formData, status: true })}
                >
                  Active
                </MDButton>
                <MDButton
                  sx={{ minWidth: "92px", borderRadius: 1.75, minHeight: "28px", p: .25, fontWeight: 500 }}
                  type='button'
                  variant={!formData?.status ? 'contained' : 'text'}
                  color={!formData?.status ? 'error' : 'dark'}
                  size="small"
                  onClick={() => setFormData({ ...formData, status: false })}
                >
                  Inactive
                </MDButton>
              </Stack>
            </Box>
          </Stack>

          <Typography variant='h5' fontWeight="semibold" mb={2}>Strain Image*</Typography>
          <Stack direction="row" alignItems="center" flexWrap="wrap" gap={1.25} mb={4.5}>
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
              badgeContent={
                isUploading ? <CircularProgress size={32} color='primary' sx={badgeCircularProgressIcon} /> : ""
              }
            >
              <img style={uploadThumbnailImage} src={formData?.image || defaultImage} alt="" />
            </Badge>
            <label htmlFor="image-input">
              <MDButton
                sx={{ minWidth: "180px" }}
                type='button'
                variant='outlined'
                color='secondary'
                component='span'
                disabled={isUploading}
              >
                {
                  formData?.image ? "Change Image" : "Add Image"
                }
              </MDButton>
            </label>
            <MDInput
              id="image-input"
              type="file"
              name="image"
              onChange={strainImageUploader}
              fullWidth
              disabled={isUploading}
              style={{ display: 'none' }}
            />
            {Boolean(errors?.image) && <FormHelperText sx={{ width: "100%", ml: 0, mt: -.5 }} error>{errors?.image}</FormHelperText>}
          </Stack>

          <Typography variant='h5' fontWeight="semibold" mb={2}>Strain Info</Typography>
          <Grid container spacing={2.5} mb={4.5}>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                label="Strain Name*"
                value={formData.strainName}
                fullWidth
                variant="outlined"
                onChange={handleInputChange}
                name='strainName'
                error={Boolean(errors?.strainName)}
                helperText={errors?.strainName}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <FormControl fullWidth>
                <InputLabel>Strain Category*</InputLabel>
                <Select
                  className="form-select"
                  error={Boolean(errors?.strainCategory)}
                  onChange={handleInputChange} label="Strain Category*" name="strainCategory" value={formData.strainCategory}>
                  {
                    infoEffectData?.map((infoStrain => {
                      return <MenuItem key={infoStrain.strainType} value={infoStrain.strainType}>{infoStrain?.strainType}</MenuItem>
                    }))
                  }

                </Select>
                {Boolean(errors?.strainCategory) && <FormHelperText error>{errors?.strainCategory}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" fontWeight="medium" color="secondary" mt={1} mb={0.5}>Title*</Typography>
              <CommonQuillEditor
                value={formData?.title || ""}
                onChange={(value) => setFormData({ ...formData, title: value })}
              />
              {Boolean(errors?.title) && <FormHelperText error>{errors?.title}</FormHelperText>}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" fontWeight="medium" color="secondary" mt={1} mb={0.5}>Description*</Typography>
              <CommonQuillEditor
                value={formData?.description || ""}
                onChange={(value) => setFormData({ ...formData, description: value })}
              />
              {Boolean(errors?.description) && <FormHelperText error>{errors?.description}</FormHelperText>}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" fontWeight="medium" color="secondary" mt={1} mb={0.5}>Meta Title*</Typography>
              <CommonQuillEditor
                value={formData?.metaTitle || ""}
                onChange={(value) => setFormData({ ...formData, metaTitle: value })}
              />
              {Boolean(errors?.metaTitle) && <FormHelperText error>{errors?.metaTitle}</FormHelperText>}
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Typography variant="subtitle2" fontWeight="medium" color="secondary" mt={1} mb={0.5}>Meta Description*</Typography>
                <CommonQuillEditor
                  value={formData?.metaDescription || ""}
                  onChange={(value) => setFormData({ ...formData, metaDescription: value })}
                />
                {Boolean(errors?.metaDescription) && <FormHelperText error>{errors?.metaDescription}</FormHelperText>}
              </Box>
            </Grid>

            <Grid item xs={12}>
              {
                infoEffectArr?.length ? <>
                  <Stack direction="row" flexWrap="wrap" gap={2} sx={{ backgroundColor: "#F1F1F1" }} px={2.5} py={2} borderRadius={4}>
                    {
                      infoEffectArr?.map((item, index) => {
                        const { title } = item;
                        const capatlizedTitle = capitalizeText(title);
                        let value = formData?.infoEffects?.includes(title);
                        return <FormControlLabel
                          key={index}
                          className='form-chip-checkbox'
                          control={<Checkbox checked={value} />}
                          label={capatlizedTitle}
                          onChange={(e) => onChangeCheckbox(e, title)}
                        />
                      })
                    }
                  </Stack>
                </>
                  :
                  null
              }
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" flexWrap="wrap" gap={2}>
                <FormControlLabel
                  className='form-chip-checkbox chip-success'
                  value="Best Strain"
                  control={<Checkbox />}
                  label="Best Strain"
                  onChange={(e) => setFormData({ ...formData, isBestStrain: e.target.checked })}
                />
                <FormControlLabel
                  className='form-chip-checkbox chip-error'
                  value="Popular Strain"
                  control={<Checkbox />}
                  label="Popular Strain"
                  onChange={(e) => setFormData({ ...formData, isPopularStrain: e.target.checked })}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                id="tags-outlined"
                options={flavors || []}
                getOptionLabel={(option) => option.flavorType}
                filterSelectedOptions
                onChange={(val, selectedOption, details) => {
                  setFormData({ ...formData, flavors: selectedOption })
                }}
                value={formData?.flavors}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors?.flavors)}
                    helperText={errors?.flavors}
                    label="Flavours"
                    placeholder="Categories"
                  />
                )}
              />
              {Boolean(errors?.flavors) && <FormHelperText error>{errors?.flavors}</FormHelperText>}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" fontWeight="medium" color="secondary" mt={1} mb={0.5}>Title2</Typography>
              <CommonQuillEditor
                value={formData?.title2 || ""}
                onChange={(value) => setFormData({ ...formData, title2: value })}
              />
              {Boolean(errors?.title2) && <FormHelperText error>{errors?.title2}</FormHelperText>}
            </Grid>
          </Grid>

          <Stack direction={{ xs: "column", sm: "row" }} spacing={1.25} mt={4} mb={2}>
            <MDButton
              sx={{ minWidth: "140px" }}
              type='button'
              variant='contained'
              color='primary'
              disabled={isUploading}
              onClick={handleSubmit}
              loading={loading}
            >
              {strainId ? "Update" : " Save"}
            </MDButton>
            {
              strainId && <MDButton
                sx={{ minWidth: "140px", backgroundColor: "#F1F1F1", color: "#FD4438" }}
                type='button'
                variant='contained'
                color='error'
                onClick={() => dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: strainId }, modal: 'DELETE_MODAL' }))}
              >
                Delete
              </MDButton>
            }
            <MDButton
              sx={{ minWidth: "140px" }}
              type='button'
              variant='outlined'
              color='dark'
              onClick={() => navigate('/strains')}
            >
              Cancel
            </MDButton>
          </Stack>
        </Card>
      </Box>
    </CommonLayout >
  )
}