import { Box, Card, CircularProgress, Grid, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MDButton from 'components/MDButton';
import { openSnackbar } from '../../redux/slices/common';
import { isEmptyObject } from 'utilities/common';
import CommonQuillEditor from '../../common/editor';
import { useNavigate } from 'react-router-dom';
import colors from "assets/theme/base/colors";
import { getMainCannabinoid, updateMainCannabinoid } from '../../redux/slices/cannabinoids';
import { validateMainCannabinoid } from 'validations/cannabinoids';

export default function AddMainCannabinoidsForm() {
    const [errors, setErrors] = useState({})
    const dispatch = useDispatch()
    const { isLoading, mainCannabinoid, loading = false } = useSelector(state => state.cannabinoids)
    const { grey } = colors;

    const [mainCannabinoidData, setMainCannabinoidData] = useState({
        metaTitle: '',
        metaDescription: '',
        title: '',
        description: '',
    });
    const navigate = useNavigate()

    const showError = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
    }

    const addMainStrain = () => {
        const { errors: validationErrors, isValid: isDataValid } = validateMainCannabinoid(mainCannabinoidData);
        if (!isDataValid) {
            setErrors(validationErrors);
            return;
        }
        setErrors({})
        dispatch(updateMainCannabinoid(mainCannabinoidData)).unwrap().then((res) => {
            showError(res?.message, 'success')
            navigate('/manage-cannabinoids?cannabinoidTab=main_cannabinoid')
        })
    };

    useEffect(() => {
        if (!isEmptyObject(mainCannabinoid)) {
            setMainCannabinoidData({ ...mainCannabinoidData, image: mainCannabinoid?.image, title: mainCannabinoid?.title, description: mainCannabinoid?.description, metaDescription: mainCannabinoid?.metaDescription, metaTitle: mainCannabinoid?.metaTitle })
        }
    }, [mainCannabinoid])

    useEffect(() => {
        dispatch(getMainCannabinoid())
    }, [])

    return (
        <Box p={{ xs: 2, sm: 3, lg: 4 }}>
            <Card sx={{ p: { xs: 2, md: 3 }, mb: 4, borderRadius: 2.5, boxShadow: "none" }}>
                <Typography variant='h4' fontWeight="semibold" color="secondary" mb={4.5}>Edit Main Cannabinoid</Typography>
                <Grid container spacing={3.5}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" fontWeight="medium" color={grey[600]} mb={1}>Title</Typography>
                        <CommonQuillEditor
                            value={mainCannabinoidData?.title || ""}
                            onChange={(value) => setMainCannabinoidData({ ...mainCannabinoidData, title: value })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" fontWeight="medium" color={grey[600]} mb={1}>Meta Title</Typography>
                        <CommonQuillEditor
                            value={mainCannabinoidData?.metaTitle || ""}
                            onChange={(value) => setMainCannabinoidData({ ...mainCannabinoidData, metaTitle: value })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" fontWeight="medium" color={grey[600]} mb={1}>Meta Description</Typography>
                        <CommonQuillEditor
                            value={mainCannabinoidData?.metaDescription || ""}
                            onChange={(value) => setMainCannabinoidData({ ...mainCannabinoidData, metaDescription: value })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" fontWeight="medium" color={grey[600]} mb={1}>Description</Typography>
                        <CommonQuillEditor
                            value={mainCannabinoidData?.description || ""}
                            onChange={(value) => setMainCannabinoidData({ ...mainCannabinoidData, description: value })}
                        />
                    </Grid>
                </Grid>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={1.25} mt={4} mb={2}>
                    <MDButton
                        sx={{ minWidth: "140px" }}
                        type='submit'
                        variant='contained'
                        color='primary'
                        onClick={addMainStrain}
                        loading={loading}
                    >
                        {loading || isLoading ? <CircularProgress size={22} style={{ color: "white" }} /> : 'Update'}
                    </MDButton>
                    <MDButton
                        sx={{ minWidth: "140px" }}
                        type='button'
                        variant='outlined'
                        color='dark'
                        onClick={() => navigate('/manage-cannabinoids?brandTab=main_cannabinoid')}
                    >
                        Cancel
                    </MDButton>
                </Stack>
            </Card>
        </Box>
    )
}