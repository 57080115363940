import { CloseRounded } from '@mui/icons-material';
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField, Tooltip, Typography } from '@mui/material';
import SearchIcon from 'common/customIcons/search';
import MDButton from 'components/MDButton';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'hooks/useDebounce';
import { AgGridTable } from 'components/tables/AgGridTable';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { addTaskArchive, fetchArchiveTaskTable, tableArchiveExport } from '../../../redux/slices/hyperdrive/drivers';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import StatusTask from 'common/component/StatusTask';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { CSVLink } from 'react-csv';
import Papa from 'papaparse';
import moment from 'moment';
import { openSnackbar } from '../../../redux/slices/common';
import { customApiSwitch, openCustomDrawer } from '../../../redux/slices/modalSlice';
import { formatTimestampToMapOld } from 'utilities/common';
import StartTaskIcon from 'common/customIcons/starttask';
import ReturnHQTaskIcon from 'common/customIcons/returnhqtask';
import BreakTaskIcon from 'common/customIcons/BreakTask';

export const ArchiveTask = ({ dataPassComponent }) => {
    if (!dataPassComponent || Object.keys(dataPassComponent).length === 0) {
        return <div>Loading...</div>;
    }
    const { routeData } = useSelector(state => state.modal)
    const today = new Date();
    const nextDate = moment(today).add(1, 'day').toDate()
    const [searchParams, setSearchParams] = useSearchParams()
    const dispatch = useDispatch()
    const [csv, setCsv] = useState([])
    const [searchTerm, setSearchTerm] = useState("")
    const mainGridApiRef = useRef('')
    const pageRef = useRef(0)
    const gridRefApi = useRef(null)
    const searchTermRef = useRef("")
    const { taskTable = {}, taskloading: taskloading = false } = useSelector(state => state.drivers)
    const dataPassRef = useRef(dataPassComponent);
    const [formData, setFormData] = useState({ activeDriver: '', taskType: [] });
    const activeTask = useRef("")

    useEffect(() => {
        dataPassRef.current = dataPassComponent;
    }, [dataPassComponent]);

    const searchHandler = (searchValue) => {
        setSearchTerm(searchValue);
        searchTermRef.current = searchValue
        handleSearch(searchValue);
    };

    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: 'Task ID',
            field: 'taskDisplayId',
            minWidth: 180,
            sortable: false,
            cellRenderer: useCallback((props) => (
                <>
                    {
                        props?.value ?
                            <div className='d-flex align-item-top jc-center flex-column gap-1'>
                                <Typography className='text-12'>{`${props?.value}`}</Typography>
                                {props?.data?.taskType === "startTask" ? <Typography className='d-flex align-item-center gap-2 text-12 color-start-task'>
                                    <StartTaskIcon /> <span>Start Task</span>
                                </Typography>
                                    : props?.data?.taskType === "returnToHeadquarterTask" ? <Typography className='d-flex align-item-center gap-2 text-12 color-return-to-hq'>
                                        <ReturnHQTaskIcon /> <span>Return To HQ </span>
                                    </Typography>
                                        : props?.data?.taskType === "breakTask" ? <Typography className='d-flex align-item-center gap-2 text-12 color-break-task'>
                                            <BreakTaskIcon /> <span>Break Task</span>
                                        </Typography>
                                            : ""}
                            </div>
                            : "-"
                    }
                </>
            ), []),
        },
        {
            headerName: 'Transaction Number',
            field: 'transactionNumber',
            suppressMenu: false,
            minWidth: 120,
            flex: 1,
            cellStyle: { whiteSpace: "normal", lineHeight: "1.5" },
            cellRenderer: useCallback((props) => (
                <>
                    {props.value
                        ? props?.value
                        : "-"}
                </>
            ), []),
            sortable: false
        },
        {
            headerName: 'Assigned To',
            field: 'driverNameSorting',
            cellRenderer: useCallback((props) => (
                props?.data?.fleet?.fleetName ? props?.data?.fleet?.fleetName : "-"
            ), []),
            minWidth: 120
        },
        {
            headerName: 'Destination',
            field: 'address',
            cellRenderer: useCallback((props) => (
                <>
                    {props?.data?.address?.address1 ?
                        <Tooltip title={props?.data?.address?.address1} arrow placement="top-end" >
                            <Box mr={2} className='text-truncate'>{props?.data?.address?.address1}</Box>
                        </Tooltip>
                        : "-"
                    }
                </>
            ), []),
            minWidth: 260,
            sortable: false
        },
        {
            headerName: 'Recipient',
            field: 'recipientDetails',
            cellRenderer: useCallback((props) => (
                <>
                    {props.data?.recipientDetails?.name
                        ? <Box mr={2} className='text-truncate'>{props.data.recipientDetails.name}</Box>
                        : <Box mr={2} className='text-truncate'>-</Box>}
                </>
            ), []),
            minWidth: 180,
            sortable: false
        },
        {
            headerName: 'Status',
            field: 'taskStatus',
            suppressMenu: false,
            minWidth: 120,
            flex: 1.5,
            cellStyle: { whiteSpace: "normal", lineHeight: "1.5" },
            cellRenderer: useCallback((props) => (
                <StatusTask value={props?.data?.taskStatus} icon={"showIcon"} />
            ), []),
            sortable: false
        },
        {
            headerName: 'Delivery Speed',
            field: 'orderType',
            suppressMenu: false,
            minWidth: 120,
            flex: 1.5,
            cellStyle: { whiteSpace: "normal", lineHeight: "1.5" },
            cellRenderer: (props) => (
                <Box>{props?.data?.orderType ? <p style={{ textTransform: props?.data?.orderType == "asap" ? "uppercase" : "capitalize" }}>{props?.data?.orderType} </p> : "-"}
                    <p> {props?.data?.orderType == "schedule" && `${props?.data?.slotDetails?.start} - ${props?.data?.slotDetails?.end}`}</p>
                </Box>
            )
            ,
            sortable: false
        },
        {
            headerName: 'Task Created',
            field: 'createdDateSorting',
            suppressMenu: false,
            cellRenderer: useCallback((props) => (
                props?.data?.createdDate ? props?.data?.createdDate : "-"
            ), []),
            minWidth: 180
        },
        {
            headerName: 'Last Updated',
            field: 'updatedDateSorting',
            suppressMenu: false,
            cellRenderer: useCallback((props) => (
                props?.data?.updatedDate ? props?.data?.updatedDate : "-"
            ), []),
            minWidth: 180
        },
        {
            headerName: 'Actions',
            suppressMenu: true,
            cellRenderer: (props) => {
                const taskId = props?.data?._id
                const data =
                {
                    taskId: taskId,
                    taskArchived: false
                }
                return <Box display="inline-flex" alignItems="center" >
                    <IconButton color='warning' size='small' variant="primary" sx={{ marginLeft: 1, color: "#06D7F6" }}
                        onClick={(e) => {
                            e.stopPropagation();
                            dispatch(addTaskArchive({ data })).unwrap()
                                .then(res => {
                                    searchFn()
                                    showResponseMessage(res?.message, 'success');
                                })
                                .catch(err => {
                                    showResponseMessage(err?.message, 'error');
                                })
                        }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                            <g clipPath="url(#clip0_10785_8378)">
                                <path d="M13.8671 3.06576L11.5417 0.218305C11.4864 0.150191 11.4166 0.0952578 11.3373 0.0574965C11.2581 0.0197352 11.1715 9.40197e-05 11.0837 0L2.91864 0C2.83057 0.000241248 2.74364 0.0199347 2.66406 0.0576727C2.58448 0.0954108 2.51422 0.150262 2.4583 0.218305L0.135254 3.06576C0.0481071 3.1713 0.000300802 3.30381 0 3.44068L0 11.5085C0.000628247 12.1691 0.263329 12.8024 0.730445 13.2696C1.19756 13.7367 1.83092 13.9994 2.49153 14H11.5085C12.1691 13.9994 12.8024 13.7367 13.2696 13.2696C13.7367 12.8024 13.9994 12.1691 14 11.5085V3.44068C13.9994 3.30427 13.9526 3.1721 13.8671 3.06576ZM9.31831 8.36915C9.26319 8.42431 9.1977 8.46801 9.12562 8.49774C9.05354 8.52747 8.97628 8.54264 8.8983 8.54237C8.82065 8.54286 8.74369 8.52777 8.67196 8.49802C8.60023 8.46827 8.53519 8.42445 8.48068 8.36915L7.59322 7.48407V11.0434C7.59322 11.2007 7.53072 11.3516 7.41947 11.4629C7.30822 11.5741 7.15733 11.6366 7 11.6366C6.84267 11.6366 6.69178 11.5741 6.58053 11.4629C6.46928 11.3516 6.40678 11.2007 6.40678 11.0434V7.48407L5.5217 8.36915C5.46791 8.42932 5.40242 8.47787 5.32923 8.51186C5.25604 8.54584 5.17668 8.56453 5.09602 8.56679C5.01535 8.56905 4.93507 8.55482 4.86009 8.52499C4.78512 8.49515 4.71701 8.45033 4.65995 8.39327C4.60289 8.33621 4.55807 8.2681 4.52823 8.19313C4.4984 8.11815 4.48418 8.03787 4.48643 7.9572C4.48869 7.87654 4.50738 7.79718 4.54136 7.72399C4.57535 7.6508 4.6239 7.58531 4.68407 7.53153L6.58237 5.63322C6.62492 5.59151 6.67279 5.55561 6.72475 5.52644C6.82816 5.47273 6.94501 5.4504 7.06094 5.46221C7.17687 5.47401 7.28682 5.51943 7.37729 5.59288L7.42 5.63322L7.45797 5.67356L9.31831 7.53153C9.37348 7.58643 9.41726 7.65169 9.44713 7.72356C9.47701 7.79544 9.49238 7.8725 9.49238 7.95034C9.49238 8.02817 9.47701 8.10524 9.44713 8.17711C9.41726 8.24899 9.37348 8.31425 9.31831 8.36915ZM1.84373 2.84746L3.20102 1.18644H10.7942L12.1515 2.84746H1.84373Z" fill="#06D7F6" />
                            </g>
                            <defs>
                                <clipPath id="clip0_10785_8378">
                                    <rect width="14" height="14" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </IconButton>
                </Box>
            },
            cellStyle: { textAlign: 'right', justifyContent: 'end' },
            headerClass: 'right-aligned-cell-header',
            minWidth: 120,
            maxWidth: 100,
            sortable: false
        },
    ]);

    const rowClassRules = useMemo(() => {
        return {
            // row style function
            "task-start": (params) => {
                return params?.data?.taskType === "startTask";
            },
            "task-return": (params) => {
                return params?.data?.taskType === "returnToHeadquarterTask";
            },
            "task-break": (params) => {
                return params?.data?.taskType === "breakTask";
            },
        };
    }, []);

    const onRowClicked = (row) => {
        const data = {
            skip: 0,
            limit: 20,
            searchTerm: searchTermRef.current,
            startDate: dataPassRef.current?.startDate ? formatTimestampToMapOld(dataPassRef.current.startDate) : formatTimestampToMapOld(today),
            endDate: dataPassRef.current?.endDate ? formatTimestampToMapOld(dataPassRef.current.endDate) : formatTimestampToMapOld(nextDate),
            taskStatus: dataPassRef.current?.taskStatus ? dataPassRef.current.taskStatus : "not_started,in_progress,unassigned,completed,cancelled",
            driverStatus: dataPassRef.current?.driverStatus ? dataPassRef.current.driverStatus : "offduty,intransit,idle",
        }
        if (row?.colDef?.headerName !== 'Actions') {
            dispatch(openCustomDrawer({
                drawer: 'TASK_DETAIL',
                drawerData: row?.data._id,
                drawerApi: data,
                activeScreen: "archive"
            }))
        }
    }

    const showResponseMessage = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
    }

    const getSortInfo = useCallback((data) => {
        const result = {};
        data.forEach(item => {
            if (item?.colId === 'driverNameSorting') {
                result[item.colId] = item.sort === "asc" ? 'asc' : "desc";
            } else if (item?.colId === 'createdDateSorting') {
                result[item.colId] = item.sort === "asc" ? 'asc' : "desc";
            } else if (item?.colId === 'updatedDateSorting') {
                result[item.colId] = item.sort === "asc" ? 'asc' : "desc";
            }
        });
        return result;
    }, []);

    const getRows = useCallback(async (params, filters) => {
        gridRefApi.current = params;
        let filter = {};
        if (params?.sortModel.length) {
            filter = { ...getSortInfo(params?.sortModel) };
        }
        try {
            const res = await dispatch(fetchArchiveTaskTable({
                data: {
                    skip: searchTermRef.current ? 0 : pageRef.current,
                    limit: 20,
                    ...filter,
                    searchTerm: searchTermRef.current,
                    taskType: formData?.taskType ? formData?.taskType : "",
                    orderType: activeTask?.current ? activeTask?.current : "",
                    startDate: dataPassRef.current?.startDate ? formatTimestampToMapOld(dataPassRef.current.startDate) : formatTimestampToMapOld(today),
                    endDate: dataPassRef.current?.endDate ? formatTimestampToMapOld(dataPassRef.current.endDate) : formatTimestampToMapOld(nextDate),
                    taskStatus: dataPassRef.current?.taskStatus ? dataPassRef.current.taskStatus : "not_started,in_progress,unassigned,completed,cancelled",
                    driverStatus: dataPassRef.current?.driverStatus ? dataPassRef.current.driverStatus : "offduty,intransit,idle",
                },
            })).unwrap();
            const { tasks } = res?.data;
            const { totalTasks } = res
            const driverListArray = tasks?.map((item) => ({
                ...item,
            }));
            params.successCallback([...driverListArray], totalTasks);
            pageRef.current += 20;
        } catch {
            params.successCallback([], 0);
        }
    }, [dispatch, dataPassComponent, formData]);

    useEffect(() => {
        if (mainGridApiRef?.current) {
            if (taskloading) {
                mainGridApiRef.current.showLoadingOverlay();
            } else if (taskTable?.totalTasks === 0) {
                mainGridApiRef.current.showNoRowsOverlay();
            } else {
                mainGridApiRef.current.hideOverlay();
            }
        }
    }, [taskTable, taskloading]);

    const searchFn = useCallback((dataPassComponent) => {
        pageRef.current = 0
        const dataSource = {
            getRows: (params) => getRows(params, dataPassComponent)  // Pass status to getRows
        };
        if (mainGridApiRef.current) {
            mainGridApiRef.current.ensureIndexVisible(0, null);
            mainGridApiRef.current.setGridOption('datasource', dataSource);
        }
    }, [getRows]);

    const handleSearch = useDebounce((term) => {
        pageRef.current = 0
        searchFn(dataPassComponent)
    }, 500);

    useEffect(() => {
        if (dataPassComponent) {
            searchFn(dataPassComponent)
        }
    }, [dataPassComponent, formData])

    useEffect(() => {
        if (routeData == "archive") {
            searchFn(dataPassComponent)
            dispatch(customApiSwitch({
                dataSwitch: '',
                routeData: ""
            }))
        }
    }, [routeData])

    const parseCsv = (csv) => {
        return new Promise((resolve, reject) => {
            Papa.parse(csv, {
                header: true,
                skipEmptyLines: true,
                complete: (result) => {
                    resolve(result.data);
                },
                error: (error) => {
                    reject(error);
                }
            });
        });
    };

    const handleExport = async (filters) => {
        try {
            const res = await dispatch(tableArchiveExport({
                data: {
                    searchTerm: searchTermRef.current,
                    orderType: activeTask?.current ? activeTask?.current : "",
                    taskType: formData?.taskType ? formData?.taskType : "",
                    startDate: filters?.startDate ? formatTimestampToMapOld(filters?.startDate) : formatTimestampToMapOld(today),
                    endDate: filters?.endDate ? formatTimestampToMapOld(filters?.endDate) : formatTimestampToMapOld(nextDate),
                    taskStatus: filters?.taskStatus ? filters?.taskStatus : "not_started,in_progress,intransit,completed,cancelled",
                    driverStatus: filters?.driverStatus ? filters?.driverStatus : "offduty,intransit,idle"
                },
            })).unwrap()
            const parseData = await parseCsv(res)
            setCsv(parseData)
        } catch (error) {
        }
    }

    useEffect(() => {
        handleExport(dataPassComponent)
    }, [dataPassComponent, formData])

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;

        setFormData((prevData) => {
            if (checked) {
                return { taskType: [...prevData.taskType, value] };
            } else {
                return { taskType: prevData.taskType.filter((task) => task !== value) };
            }
        });
    };

    return (
        <Box p={2} className="h-100 row-pointer table-pg">
            <Grid container alignItems="center" rowSpacing={2} columnSpacing={2} mt={0} mb={3}>
                <Grid item xs={12} sm={2}>
                    <Typography variant='h4' color='#ffffff' className='text-24 d-flex align-item-center gap-2'>
                        <ArrowBackIosNewIcon style={{ fontSize: "16px !important", marginRight: "8px", cursor: "pointer" }} onClick={() => setSearchParams({ roleTab: "table" })} />
                        <span>Archived Tasks</span>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={10}>
                    <Stack direction="row" justifyContent={{ xs: "start", sm: "end" }} flexWrap="wrap" alignItems="stretch" gap={1.5}>
                        <FormGroup className="multi-checkbox-sec gap-3">
                            <FormControlLabel control={<Checkbox value="deliveryTask" onChange={handleCheckboxChange} />} label="Delivery Task" />
                            <FormControlLabel control={<Checkbox value="startTask" onChange={handleCheckboxChange} />} label="Start Task" />
                            <FormControlLabel control={<Checkbox value="returnToHeadquarterTask" onChange={handleCheckboxChange} />} label="Return to HQ" />
                            <FormControlLabel control={<Checkbox value="breakTask" onChange={handleCheckboxChange} />} label="Break Task" />
                        </FormGroup>
                        <TextField autoComplete="off" className="custom-search-field dark-field" placeholder="Search" label=""
                            value={searchTerm} onChange={(e) => searchHandler(e.target.value)}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">
                                    <IconButton size='small'>
                                        <SearchIcon width='18px' height='18px' />
                                    </IconButton>
                                </InputAdornment>,
                                endAdornment: searchTerm?.length > 0 ? <InputAdornment>
                                    <IconButton onClick={() => searchHandler('')} size='small'>
                                        <CloseRounded />
                                    </IconButton>
                                </InputAdornment> : null
                            }}
                            style={{ width: "270px" }}
                        />
                        <Stack direction='row' alignItems="center" gap={1} className="delivery-speed-field dark-field">
                            <FormControl fullWidth sx={{ width: 270 }}>
                                <InputLabel>Delivery Speed</InputLabel>
                                <Select size='small' value={activeTask.current}
                                    onChange={(e) => { setFormData((prevData) => ({ ...prevData, activeDriver: e.target.value })); activeTask.current = e.target.value }}
                                    fullWidth className='form-select' name='status' label='Product Status' MenuProps={{ className: "cus-popover" }}>
                                    <MenuItem value='asap'>ASAP</MenuItem>
                                    <MenuItem value='schedule'>Schedule</MenuItem>
                                    <MenuItem className="dropdown-reset">
                                        <Button onClick={() => { setFormData((prevData) => ({ ...prevData, activeDriver: "" })); activeTask.current = "" }}>Reset</Button>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                        <CSVLink data={csv} style={{ color: "#ffffff", textTransform: "none" }}>
                            <MDButton onClick={() => { handleExport() }} style={{ height: "100%", textTransform: "none", minWidth: "180px", borderRadius: "30px", minHeight: "40px" }}
                                startIcon={<FileDownloadOutlinedIcon fontSize='large' />}
                                variant="contained" color="primary"  >
                                Export as .CSV
                            </MDButton>
                        </CSVLink>
                    </Stack>
                </Grid>
            </Grid>
            <div className="ag-theme-quartz-dark">
                <AgGridTable
                    col={columnDefs}
                    mainGridApiRef={mainGridApiRef}
                    getRows={getRows}
                    pageRef={pageRef}
                    isLoading={taskloading}
                    style={{ height: "70vh" }}
                    gridOptions={{ rowHeight: 64 }}
                    maxLimit={20}
                    onRowClicked={onRowClicked}
                    rowClassRules={rowClassRules}
                />
            </div>
        </Box>
    )
}

export default ArchiveTask;