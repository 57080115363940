import React from 'react'

export default function TaskStartedIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_10798_330)">
        <path d="M10 11.9996H2.66667C1.95942 11.9996 1.28115 11.7186 0.781049 11.2185C0.280952 10.7184 0 10.0402 0 9.33293V3.99959C0 3.11554 0.351189 2.26769 0.976311 1.64257C1.60143 1.01745 2.44928 0.66626 3.33333 0.66626H6.66667C7.10441 0.66626 7.53786 0.752479 7.94228 0.919995C8.3467 1.08751 8.71416 1.33304 9.02369 1.64257C9.33322 1.9521 9.57875 2.31956 9.74627 2.72398C9.91378 3.1284 10 3.56185 10 3.99959V11.9996ZM16 7.33293V6.66626C16 6.22852 15.9138 5.79507 15.7463 5.39065C15.5788 4.98623 15.3332 4.61877 15.0237 4.30924C14.7142 3.99971 14.3467 3.75418 13.9423 3.58666C13.5379 3.41915 13.1044 3.33293 12.6667 3.33293H11.3333V7.33293H16ZM11.3333 8.66626V11.9996H13.3333C14.0406 11.9996 14.7189 11.7186 15.219 11.2185C15.719 10.7184 16 10.0402 16 9.33293V8.66626H11.3333ZM2.70533 13.3329C2.68091 13.4424 2.66795 13.5541 2.66667 13.6663C2.66667 14.1083 2.84226 14.5322 3.15482 14.8448C3.46738 15.1573 3.89131 15.3329 4.33333 15.3329C4.77536 15.3329 5.19928 15.1573 5.51184 14.8448C5.82441 14.5322 6 14.1083 6 13.6663C5.99871 13.5541 5.98576 13.4424 5.96133 13.3329H2.70533ZM10.0387 13.3329C10.0142 13.4424 10.0013 13.5541 10 13.6663C10 14.1083 10.1756 14.5322 10.4882 14.8448C10.8007 15.1573 11.2246 15.3329 11.6667 15.3329C12.1087 15.3329 12.5326 15.1573 12.8452 14.8448C13.1577 14.5322 13.3333 14.1083 13.3333 13.6663C13.332 13.5541 13.3191 13.4424 13.2947 13.3329H10.0387Z" fill="#8E0CF5"/>
      </g>
      <defs>
        <clipPath id="clip0_10798_330">
          <rect width="16" height="16" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}