import { Box,  Grid, IconButton,  Stack,  Typography } from '@mui/material'
import MDButton from 'components/MDButton'
import { AgGridTable } from 'components/tables/AgGridTable'
import Search from 'examples/Search'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { deleteProductBatch, fetchProductBatches } from '../../../redux/slices/products'
import { sortAndFilter } from 'components/tables/AgGridTable'
import EditIcon from 'common/customIcons/edit'
import DeleteIcon from 'common/customIcons/delete'
import { closeCustomModalDialog, openCustomModalDialog, setModalLoader } from '../../../redux/slices/modalSlice'
import StatusText from 'common/component/StatusText'
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useDebounce } from 'hooks/useDebounce'
import { formatTimestampToWithoutTime } from 'utilities/common'
import CircularProgressWithLabel from 'common/CircularProgressWithLabel'

export default function BatchTable({ productId = "", batchHandler = () => { }, editBatchHandler = () => { } }) {
  const dispatch = useDispatch()
  const { isFetching = false, productBatches = {} } = useSelector(state => state.products)
  const { pathname } = useLocation()
  const { user } = useSelector(state => state.auth)
  const pageRef = useRef(0)
  const searchTermRef = useRef("")
  const mainGridApiRef = useRef('')
  const gridRefApi = useRef(null)
  const [searchTerm, setSearchTerm] = useState("")
  const isDetailPage = useMemo(() => {
    return pathname.includes('details')
  }, [pathname])

  const handleSearch = useDebounce((term) => {
    // Perform search operation with the debounced term
    pageRef.current = 0
    searchFn()
  }, 500);

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: 'Purchase Date',
      field: 'purchaseDate',
      key: "fullName",
      suppressMenu: false,
      cellRenderer: (props) => {
        if (props.value !== undefined) {
          return props.value
        } else {
          return (
            <img src="https://www.ag-grid.com/example-assets/loading.gif" />
          );
        }
      },
      minWidth: 120
    },
    {
      headerName: 'Batch ID',
      field: 'productBatchId',
      suppressMenu: false,
      wrapText: true,
      autoHeight: true,
      minWidth: 200,
      cellRenderer: (props) => {
        return <Typography variant='body2' fontSize={12} lineHeight={1.25}>{props?.data?.productBatchId}</Typography>
      }
    },
    {
      headerName: 'Unique ID',
      field: 'uniqueNumber',
      suppressMenu: false,
      minWidth: 100
    },
    {
      headerName: 'Expiration Date',
      field: 'expirationDate',
      suppressMenu: false,
      minWidth: 120,
    },
    {
      headerName: 'Status',
      field: 'status',
      suppressMenu: false,
      cellRenderer: (props) => {
        return <StatusText status={props?.data?.status} />
      },
      minWidth: 100
    },
    {
      headerName: 'Purchase Quantity',
      field: 'purchaseQuantity',
      suppressMenu: false,
      cellRenderer: (props) => {
        if (!props?.value) return '-'
        if (props.value?.toString()) {
          return `${props.value?.toString()}`
        }
      },
      minWidth: 140
    },
    {
      headerName: 'Current Quantity',
      field: 'currentQuantity',
      suppressMenu: false,
      cellRenderer: (props) => {
        if (!props?.value) return '-'
        if (props.value?.toString()) {
          return `${props.value?.toString()}`
        }
      },
      minWidth: 140
    },
    {
      headerName: 'Sold Percentage',
      field: 'soldPercentage',
      suppressMenu: false,
      cellRenderer: (props) => {
        if (props.value?.toString()) {
          return <CircularProgressWithLabel variant="determinate" value={props?.value?.toString() || "0"} />
        }
        return "-"
      },
      minWidth: 140,
      cellStyle: { display: 'inline-flex' }
    },
      {
      headerName: 'Unit Cost',
      field: 'unitCost',
      suppressMenu: false,
      cellRenderer: (props) => {
        if (!props?.value) return '-'
        if (props.value) {
          return <Typography variant='body2' fontWeight="medium" lineHeight={1}>${props.value}</Typography>
        }
      },
      minWidth: 100
    },
  ]);

  const deleteHandler = (productIds) => {
    dispatch(setModalLoader(true))
    dispatch(deleteProductBatch(productIds)).unwrap().then(() => {
      dispatch(closeCustomModalDialog())
      pageRef.current = 0
      getRows(gridRefApi.current)
    }).catch(() => {
      dispatch(setModalLoader(false))
    })
  }

  const getRows = async (params) => {
    gridRefApi.current = params;
    dispatch(fetchProductBatches({ filters: { skip: pageRef.current, limit: 100, userType: 'admin', searchTerm: searchTermRef.current }, productId })).unwrap().then(res => {
      const { productBatchDetails = {}, total = 0 } = res
      const batchesArray = productBatchDetails?.batchData?.map((item => {
        return { ...item, expirationDate: item?.expirationDate ? formatTimestampToWithoutTime(item?.expirationDate) : "-", purchaseDate: item?.purchaseDate ? formatTimestampToWithoutTime(item?.purchaseDate) : "-", id: item._id }
      }))
      const dataAfterSortingAndFiltering = sortAndFilter(
        batchesArray,
        params.sortModel,
        params.filterModel
      );
      const rowsThisPage = dataAfterSortingAndFiltering?.slice(
        params.startRow,
        params.endRow
      );
          params.successCallback(dataAfterSortingAndFiltering, total);
      pageRef.current = pageRef.current + 20
    }).catch(() => { })
  }

  const searchFn = () => {
    const dataSource = {
      getRows
    }
    mainGridApiRef.current.ensureIndexVisible(0, null);
    mainGridApiRef.current.setGridOption('datasource', dataSource);
  }

  const onRowClicked = (row) => {
    batchHandler()
    editBatchHandler(row.data)
  }

  const searchHandler = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    searchTermRef.current = value
    handleSearch(value);
    // Debounce the search callback
  };

  useEffect(() => {
    if (!isDetailPage) {
      setColumnDefs([...columnDefs, {
        field: 'Actions',
        cellRenderer: (props) => {
          return <Box>
            <IconButton onClick={() => onRowClicked(props)} color='primary' size='small' variant="primary">
              <EditIcon width='16px' height='16px' />
            </IconButton>
            <IconButton onClick={() => dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: { productId: productId, productBatchId: props.data?.productBatchId, updatedBy: user?.name } }, modal: 'DELETE_MODAL' }))} color='error' size='small' variant="primary" sx={{ marginLeft: 1 }}>
              <DeleteIcon width='16px' height='16px' />
            </IconButton>
          </Box>
        },
        cellStyle: { textAlign: 'right' },
        headerClass: 'right-aligned-cell-header',
        minWidth: 120
      }])
    }
  }, [isDetailPage])


  useEffect(() => {
    if (mainGridApiRef?.current) {
      if (isFetching) {
        mainGridApiRef.current.showLoadingOverlay()
      } else if (productBatches?.total == 0 || !productBatches?.total) {
        mainGridApiRef.current.showNoRowsOverlay()
      }
      else {
        mainGridApiRef.current.hideOverlay()
      }
    }
  }, [productBatches, isFetching])

  return (
    <>
      <Grid container alignItems="center" rowSpacing={2} columnSpacing={2} mb={4}>
        <Grid item xs={12} sm={4}>
          <Typography variant='h4'>Batches</Typography>
        </Grid>

        <Grid item xs={12} sm={8}>
          <Stack direction="row" justifyContent={{ xs: "start", sm: "end" }} flexWrap="wrap" alignItems="stretch" gap={2}>
            <Search placeholder="Search" label="" value={searchTerm} onChange={searchHandler} />
            <MDButton
              startIcon={<AddRoundedIcon fontSize='large' />}
              variant="contained" color="primary"
              onClick={batchHandler} >
              Add Batch
            </MDButton>

          </Stack>
        </Grid>
      </Grid>

      <AgGridTable col={columnDefs} getRows={getRows} pageRef={pageRef} isLoading={isFetching} mainGridApiRef={mainGridApiRef}
        gridOptions={{
          rowHeight: 74,
        }}
      />
    </>
  )
}