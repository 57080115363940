import React from 'react'

export default function ReturnRequestIcon({width = '20px', height = '20px', flex = 'none'}) {
  return (
    <svg style={{width, height, flex}} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.7076 3.53084L9.92969 4.15198V1H7.01172V4.15198L8.23257 3.53153C8.37663 3.45847 8.55227 3.45256 8.7076 3.53084Z" fill="currentColor"/>
      <path d="M15.9414 7.37166V1.52734C15.9414 1.23607 15.7054 1 15.414 1H10.9844V5.01001C10.9844 5.38917 10.5798 5.681 10.1847 5.46155L8.4707 4.5906L6.72126 5.47968C6.37012 5.65752 5.95799 5.39797 5.95827 5.01001L5.95703 1H1.52734C1.23607 1 1 1.23607 1 1.52734V15.414C1 15.7054 1.23607 15.9414 1.52734 15.9414H7.37166C6.72113 14.3385 6.728 12.5555 7.38978 10.958C8.77365 7.61692 12.5903 6.01169 15.9414 7.37166Z" fill="currentColor"/>
      <path d="M17.3807 9.56261C16.3803 8.56216 14.9982 7.94336 13.4717 7.94336C11.9452 7.94336 10.563 8.56216 9.56261 9.56261C8.56216 10.563 7.94336 11.9452 7.94336 13.4717C7.94336 14.9982 8.56216 16.3803 9.56261 17.3807C10.563 18.3812 11.9452 19 13.4717 19C14.9982 19 16.3803 18.3812 17.3807 17.3807C18.3812 16.3803 19 14.9982 19 13.4717C19 11.9452 18.3812 10.563 17.3807 9.56261ZM15.6948 15.2027C15.3257 15.5718 14.8157 15.8008 14.2538 15.8008H11.793C11.5017 15.8008 11.2656 15.5647 11.2656 15.2735C11.2656 14.9821 11.5017 14.7461 11.793 14.7461H14.2538C14.7954 14.7461 15.2383 14.3032 15.2383 13.7616C15.2383 13.4687 15.1468 13.229 14.9491 13.0314C14.7707 12.8529 14.5246 12.7422 14.2538 12.7422H12.7848L13.098 13.0549C13.304 13.2596 13.305 13.5927 13.1002 13.7985C12.8955 14.0046 12.5624 14.0054 12.3566 13.8006L11.1448 12.5911C10.9388 12.3864 10.938 12.0534 11.1427 11.8475L12.3522 10.6359C12.5569 10.43 12.8898 10.429 13.0958 10.6338C13.3017 10.8385 13.3026 11.1714 13.0979 11.3775L12.7883 11.6875H14.2538C15.3777 11.6875 16.293 12.6028 16.293 13.7267C16.293 14.312 16.0828 14.8146 15.6948 15.2027Z" fill="currentColor"/>
    </svg>
  )
}