import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Riple } from 'react-loading-indicators'

const FetchDriveMessage = ({ closeModal }) => {
    const {  modalData, modalError } = useSelector(state => state?.modal)
    useEffect(() => {
        if (modalData?.message) {
            setTimeout(() => {
                closeModal()
            }, 2000);
        }
    }, [modalData])

    return (
        <div className="d-flex align-item-center flex-column gap-3 jc-center p-4">
            <p className='text-white-700 text-16 my-2 font-w-600'>{modalData?.message ? modalData?.message : "We are searching for the best driver"}</p>
            {
                modalData?.message ? <>{modalError ? <CancelIcon className='icon_cross' /> : <CheckCircleIcon className='icon_tick' />}</> :
                    <Riple color="#3135cc" size="medium" text="" textColor="" />
            }
        </div>
    )
}

export default FetchDriveMessage
