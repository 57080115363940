import { Close } from '@mui/icons-material'
import { Box, Button, FormControl, FormHelperText, Grid, Stack, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import moment from 'moment'
import ReassignDriverIcon from 'common/customIcons/reassigndriver'
import MapDriveListDrawer from './MapDriveListDrawer';
import { addStartTask } from '../../../redux/slices/hyperdrive/createTask';
import { openSnackbar } from '../../../redux/slices/common';
import MDButton from 'components/MDButton';
import { useSelector } from 'react-redux';
import RegionListDrawer from './RegionList';
import { customApiSwitch, driverDataCustom, regionDataCustom } from '../../../redux/slices/modalSlice';
import { useSearchParams } from 'react-router-dom';

export default function BreakTask({ closeModal }) {
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({})
    const { loading: loading = false } = useSelector(state => state?.taskHyperDrive)
    const [openingHours, setOpeningHours] = useState(null);
    const [closingHours, setClosingHours] = useState(null);
    const [regionList, setRegionList] = useState(false);
    const [formData, setFormData] = useState({
        taskName: "",
        taskAssignmentMode: "driver",
        taskType: "breakTask",
        fleetIds: [],
        regionIds: [],
        fromTime: "",
        toTime: "",
    })
    const [searchParams, setSearchParams] = useSearchParams();
    let roleTab = searchParams.get('roleTab')
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showManualList, setShowManualList] = useState(false);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setShowManualList(true)
    };

    const formatTime = (event) => {
        const date = moment(event);
        if (!date.isValid()) {
            return '';
        }
        return date.format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    };

    const handleOpeningHoursChange = (newTime) => {
        setClosingHours(null)
        const date = new Date(newTime);
        const formattedTime = formatTime(date);
        setOpeningHours(newTime);
        setFormData(prevData => ({ ...prevData, fromTime: formattedTime }));
        setErrors(prevErrors => ({ ...prevErrors, openingHours: '', sendBreakAt: "" }));
    };

    const handleClosingHoursChange = (newTime) => {
        const date = new Date(newTime);
        const formattedTime = formatTime(date);
        if (openingHours && new Date(newTime) <= new Date(openingHours)) {
            setErrors(prevErrors => ({
                ...prevErrors,
                closingHours: 'Closing time must be after opening time.'
            }));
            return;
        }
        setClosingHours(newTime);
        setFormData(prevData => ({ ...prevData, toTime: formattedTime }));
        setErrors(prevErrors => ({ ...prevErrors, closingHours: '' }));
    };

    const startTaskHandler = (event) => {
        const { name, value } = event.target;
        const formatDateString = (dateString) => {
            const date = moment(dateString);
            if (!date.isValid()) {
                return '';
            }
            return date.format('YYYY-MM-DD HH:mm:ss');
        };
        const formattedValue = (name === 'taskAssignmentMode') ? value : (name === 'taskStartTime' || name === 'taskEndTime') ? formatDateString(value) : value; setFormData(prevData => {
            const newData = {
                ...prevData,
                [name]: formattedValue
            };

            if (name === 'taskAssignmentMode') {
                setAnchorEl(null);
                setErrors({ taskAssignmentMode: "" })
                if (value === "driver") {
                    setShowManualList(true)
                    setRegionList(false)
                    dispatch(regionDataCustom({
                        region: 'REGION_DATA',
                        regionData: []
                    }))
                }
                else {
                    dispatch(driverDataCustom({
                        driver: 'DRIVER_DATA',
                        driverData: []
                    }))
                    setRegionList(true)
                    setShowManualList(false)
                }
            }
            return newData;
        });
    };

    const validateTask = () => {
        let isError = false
        let errors = {}
        if (!formData?.taskName?.trim()) {
            isError = true
            errors = { ...errors, taskName: 'Break Name is required' }
        }
        if (!openingHours) {
            isError = true
            errors = { ...errors, openingHours: 'Break Start Time Name is required' }
        }
        if (!closingHours) {
            isError = true
            errors = { ...errors, closingHours: 'Break End Time Name is required' }
        }
        if (!formData?.taskAssignmentMode?.trim()) {
            isError = true
            errors = { ...errors, taskAssignmentMode: 'Assign Mode is required' }
        }
        return { errors, isError }
    }

    const submitHandler = () => {
        const validationResult = validateTask();
        let isError = validationResult.isError;
        let errors = { ...validationResult.errors };
        setErrors(errors);
        if (isError) return;
        if (formData?.taskAssignmentMode === "driver") {
            if (formData?.fleetIds?.length == 0) {
                isError = true;
                errors = { ...errors, fleetIds: 'Please select Driver' };
                setErrors(errors);
                return false
            }
        } else if (formData?.taskAssignmentMode === "region") {
            if (formData?.regionIds?.length == 0) {
                isError = true;
                errors = { ...errors, regionIds: 'Region ID is required' };
                setErrors(errors);
                return false
            }
        }

        dispatch(addStartTask({ data: formData }))
            .unwrap()
            .then(res => {
                dispatch(customApiSwitch({
                    dataSwitch: 'API_DATA_ROUTE',
                    routeData: roleTab
                }))
                showResponseMessage(res?.message, 'success');
                closeModal()
            })
            .catch(err => {
                showResponseMessage(err?.message, 'error');
            });
    }

    const showResponseMessage = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
    }

    function handleDataFromChild() {
        setShowManualList(false)
        setRegionList(false)
    }

    const handleFleetValueChange = (value) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            fleetIds: value
        }));
        setErrors({ ...errors, fleetIds: "" });
    };

    const handleRegionValueChange = (value) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            regionIds: value,
            fleetIds: []
        }));
        setErrors({ ...errors, regionIds: "" });
    };

    return (
        <Box className="cus-wrapper-drawer">
            <Box display="flex" alignItems="center" justifyContent="space-between" p={2} style={{ borderBottom: "1px solid  #1E1E24" }} className="drawer-head-sec">
                <Typography className="text-14 text-white-700">Create Break Task</Typography>
                <Close onClick={closeModal} style={{ cursor: "pointer", color: "#ffffff" }} />
            </Box>
            <Box className="drawer-scroll-sec">
                <Box px={2} py={4}>
                    <Stack direction='column' mb={4}>
                        <Typography mb={1} fontWeight={600} className='text-20 text-white-700'>Break Name</Typography>
                        <Stack direction='column' gap={2} mb={1} className='dark-field'>
                            <TextField autoComplete="off" fullWidth helperText={errors?.taskName}
                                error={errors?.taskName} label={'Name'} name="taskName" onChange={(e) => startTaskHandler(e)} className="form-input" value={formData?.taskName || ''}
                            />
                        </Stack>
                    </Stack>
                    <Stack direction='column' mb={4}>
                        <Typography mb={1} fontWeight={600} className='text-20 text-white-700'>Break Details</Typography>
                        <Grid container spacing={2} className='dark-field'>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <FormControl fullWidth>
                                    <MobileTimePicker className="mobile-time-picker" label="Break Start Time" value={openingHours} onChange={handleOpeningHoursChange}
                                        renderInput={(params) => <TextField    {...params} error={Boolean(errors?.openingHours)} helperText={errors?.openingHours} />} />
                                    {Boolean(errors?.openingHours) && <FormHelperText error>{errors?.openingHours}</FormHelperText>}
                                </FormControl>
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <FormControl fullWidth>
                                    <MobileTimePicker
                                        className="mobile-time-picker" label="Break End Time" value={closingHours} onChange={handleClosingHoursChange} disabled={!openingHours}
                                        renderInput={(params) => <TextField {...params} error={Boolean(errors?.closingHours)} helperText={errors?.closingHours} />} />
                                    {Boolean(errors?.closingHours) && <FormHelperText error>{errors?.closingHours}</FormHelperText>}
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Stack>
                    <Stack>
                        <div className='d-flex reassign-driver-btn'>
                            <Button id="" aria-controls={open ? '' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick} className='d-flex gap-2 text-white-900'  >
                                <ReassignDriverIcon />
                                <Typography fontWeight="semibold" className='text-12 text-white-900 text-transform-none'>Assign Driver</Typography>
                                <p className='button-error text-left'>{errors?.fleetIds}</p>
                            </Button>
                        </div>
                    </Stack>
                </Box>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="space-between" p={2} gap={2} style={{ borderTop: "1px solid  #1E1E24" }} className="drawer-bottom-btns">
                <Button variant="outlined" color="secondary" className='d-flex align-item-center gap-2' onClick={closeModal}>
                    Cancel
                </Button>
                <MDButton variant="contained" color="primary" className='d-flex align-item-center gap-2 green-btn' onClick={() => submitHandler()} disabled={loading} loading={loading}>
                    Create Task
                </MDButton>
            </Box>
            {showManualList &&
                <MapDriveListDrawer sendDataToParent={handleDataFromChild} onValueChange={handleFleetValueChange} breakTask={"startTask"} />
            }
            {
                regionList &&
                <RegionListDrawer sendDataToParent={handleDataFromChild} onValueChange={handleRegionValueChange} breakTask={"startTask"} />
            }
        </Box>
    )
}