import React from 'react'

export default function RegionsIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.3048 20.8238L11.2957 16.6588C11.087 16.55 10.8325 16.565 10.6352 16.6963L4.51102 20.8612C4.28194 21.0175 4.18267 21.3012 4.26667 21.5625C4.34812 21.8212 4.59502 22 4.87247 22H19.0057C19.2972 22 19.553 21.805 19.623 21.525C19.6942 21.2462 19.5619 20.9575 19.3048 20.8238Z" fill="currentColor"/>
      <path d="M21.9851 19.6363L20.1015 11.3075C20.0557 11.1038 19.9093 10.9375 19.7121 10.8613C19.5135 10.785 19.2908 10.8113 19.1177 10.9288L12.9922 15.0938C12.809 15.2188 12.7059 15.4275 12.7199 15.6438C12.7326 15.8625 12.8612 16.0575 13.0572 16.1587L21.0649 20.3238C21.1591 20.3725 21.2609 20.3975 21.3627 20.3975C21.4989 20.3975 21.6351 20.355 21.7484 20.2687C21.9456 20.1212 22.0385 19.875 21.9851 19.6363Z" fill="currentColor"/>
      <path d="M17.5536 9.93875C17.4696 9.6775 17.2239 9.5 16.9452 9.5H11.1367C10.0803 11.3787 8.51746 13.175 7.87475 13.8775C7.51331 14.2725 6.99532 14.5 6.45443 14.5C5.91353 14.5 5.39555 14.2725 5.0341 13.8775C4.78593 13.6062 4.40157 13.1725 3.96122 12.635L2.42126 19.4425C2.36527 19.6937 2.4709 19.9525 2.68726 20.0962C2.79416 20.1675 2.91889 20.2025 3.04234 20.2025C3.16961 20.2025 3.2956 20.1662 3.40506 20.0912L17.3067 10.6375C17.537 10.4837 17.635 10.2 17.5536 9.93875Z" fill="currentColor"/>
      <path d="M6.45442 2C3.99813 2 2 3.9625 2 6.375C2 8.62 5.57372 12.5962 5.98098 13.0425C6.10189 13.1737 6.2737 13.25 6.45442 13.25C6.63515 13.25 6.80696 13.1737 6.92786 13.0425C7.33513 12.5962 10.9088 8.62 10.9088 6.375C10.9088 3.9625 8.91072 2 6.45442 2ZM6.45442 8.25C5.40063 8.25 4.54538 7.41 4.54538 6.375C4.54538 5.34 5.40063 4.5 6.45442 4.5C7.50821 4.5 8.36346 5.34 8.36346 6.375C8.36346 7.41 7.50821 8.25 6.45442 8.25Z" fill="currentColor"/>
    </svg>
  )
}