import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addOrUpdateStrain, getAllFlavors, getAllInfoEffect } from '../../../redux/slices/hyperwolf/strains';
import { validateStrainDraftData } from 'validations/strain';
import { validateHyperwolfStrainData } from 'validations/strain';
import { TextField, Button, Typography, CircularProgress, Grid, FormHelperText, FormControl, FormGroup, Box, Card } from '@mui/material';
import CommonQuillEditor from 'common/editor';
import { isEmptyObject } from 'utilities/common';
import CustomCheckbox from 'components/common/CustomCheckbox';
import CustomSelect from 'components/common/CustomSelect';
import CustomMultiSelect from 'components/common/CustomMultiSelect';
import CommonLayout from 'layouts/DashboardLayout';
import { useNavigate } from 'react-router-dom';
import { openSnackbar, uploadImage } from '../../../redux/slices/common';

const initialEmployee = {}

function AddHyperwolfStrain(props) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { isUploading = false } = useSelector(state => state.common)
    const { isLoading = false, infoEffects, allFlavorsData, allStrains, singleStrain = {} } = useSelector(state => state.hyperwolfStrains)
    const [strainData, setStrainData] = useState({ ...initialEmployee })
    const [errors, setErrors] = useState({})
    const [selectedFlavors, setSelectedFlavors] = useState([])
    const isEditdata = useMemo(() => {
        return !isEmptyObject(singleStrain)
    }, [singleStrain])

    const infoEffectArr = useMemo(() => {
        return infoEffects?.find((effect => effect?.strainType === strainData.strainCategory))?.infoEffects || []
    }, [strainData.strainCategory, infoEffects])

    const capitalizeString = (str) => {
        return (str?.slice(0, 1)?.toUpperCase() + str?.slice(1)?.toLowerCase());
    };


    useEffect(() => {
        if (singleStrain?.flavors?.length) {
            setSelectedFlavors([...selectedFlavors, ...singleStrain.flavors?.map((flavour => {
                return { name: flavour }
            }))])
        }
    }, [singleStrain])

    const allFlavorsDataArr = useMemo(() => {
        return allFlavorsData?.length > 0 ? allFlavorsData?.map((flavour => {
            return { name: flavour.flavorType }
        })) : []
    }, [allFlavorsData])

    const onChangeCheckbox = (e, name) => {
        e.preventDefault();
        let updatedEffects = [...(strainData?.infoEffects || [])]
        let alreadyExist = updatedEffects.findIndex(v => v === name)
        if (alreadyExist === -1) {
            updatedEffects.push(name)
        } else {
            updatedEffects.splice(alreadyExist, 1)
        }
        setStrainData({ ...strainData, infoEffects: updatedEffects })
    }

    useEffect(() => {
        if (isEditdata) {
            setStrainData({ ...singleStrain, flavors: singleStrain.flavors?.join(',') });

        }

    }, [isEditdata, singleStrain]);

    const onChangehandler = (e) => {
        const { name, value } = e.target;
        setStrainData({ ...strainData, [name]: value });
    };

    const onEditorChangehandler = (value, name) => {
        setStrainData({ ...strainData, [name]: value });
    };
    const showResponseMessage = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
    }

    const addStrainHandler = (submitType) => {
        const { errors: validationErrors, isValid: isDataValid } = submitType === 'save_as_draft' ? validateStrainDraftData(strainData) : validateHyperwolfStrainData(strainData);
        if (!isDataValid) {
            setErrors(validationErrors);
            return;
        }
        setErrors({})
        const userForPayload = { ...strainData, status: submitType === 'save_as_draft' ? false : true, flavors: selectedFlavors?.map((flavour => Object.values(flavour)[0])) };
        dispatch(addOrUpdateStrain({ data: userForPayload, id: (strainData._id || '') })).unwrap().then(res => {
            showResponseMessage(res?.message, 'success')
            navigate('/hyperwolf/manage_main_strain?strainTab=strains')
        }).catch(err => {
            showResponseMessage(err?.message, 'error')
        });
    };

    const imageChangeHandler = (e) => {
        let file = e.target.files[0]
        const formData = new FormData();
        formData.append('filePath', file)
        dispatch(uploadImage(formData)).unwrap().then((res) => {
            setStrainData({ ...strainData, image: res?.fileUrl })
        });
    }

    useEffect(() => {
        dispatch(getAllInfoEffect())
        dispatch(getAllFlavors())
    }, [])

    const handleChangeFlavor = (e, val, index) => {
        setSelectedFlavors(val)
    };

    return (
        <CommonLayout>
            <Box p={{ xs: 2, sm: 3, lg: 4 }}>
                <Card sx={{ p: { xs: 2, md: 3 }, borderRadius: 2.5, boxShadow: "none" }}>
                    <Typography variant="h4" className="d-flex jc-ctr" mb={2}>{singleStrain?.id ? 'Edit' : 'Add'} Strain</Typography>
                    <Grid container spacing={4} component="main" className={`add-zipcode-modal-container `}>
                        <Grid item xs={12} className="add-category-input">
                            <Grid item xs={12}>
                                <CustomSelect
                                    name="strainCategory"
                                    value={strainData.strainCategory || ""}
                                    menuItem={infoEffects?.map((effect => ({ title: effect.strainType, value: effect.strainType }))) || []}
                                    onChange={onChangehandler}
                                    label="Strain Category"
                                    className="w-100"
                                    margin="normal"
                                    error={errors.strainCategory}
                                />

                                {
                                    errors?.strainCategory && <FormHelperText error >{errors?.strainCategory}</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="strainName"
                                    value={strainData.strainName || ""}
                                    onChange={onChangehandler}
                                    label="Strain Name"
                                    className="w-100"
                                    margin="normal"
                                    error={errors.strainName}
                                    helperText={errors.strainName}
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <TextField
                                    name="metaTitle"
                                    value={strainData.metaTitle || ""}
                                    onChange={onChangehandler}
                                    label="Meta Title"
                                    className="w-100"
                                    margin="normal"
                                    error={errors.metaTitle}
                                    helperText={errors.metaTitle}
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <TextField
                                    name="metaDescription"
                                    value={strainData.metaDescription || ""}
                                    onChange={onChangehandler}
                                    label="Meta Description"
                                    className="w-100"
                                    margin="normal"
                                    error={errors.metaDescription}
                                    helperText={errors.metaDescription}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <FormHelperText style={{ fontSize: '14px' }}>Description</FormHelperText>
                                    <CommonQuillEditor onChange={(value) => setStrainData({ ...strainData, description: value })} id={"description"} value={strainData.description} />
                                    {/* {
                            errors.description && <FormHelperText>{errors.description}</FormHelperText>
                        } */}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <FormHelperText style={{ fontSize: '14px' }}>Title</FormHelperText>
                                    <CommonQuillEditor onChange={(value) => setStrainData({ ...strainData, title: value })} id={"title"} value={strainData.title} />
                                    {/* {
                            errors.title && <FormHelperText>{errors.title}</FormHelperText>
                        } */}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    infoEffectArr?.length ?
                                        <FormGroup className="effects-listing" >
                                            <Grid container>
                                                {
                                                    infoEffectArr?.length ? infoEffectArr?.map((item, index) => {
                                                        const { title } = item;
                                                        const capatlizedTitle = capitalizeString(title);
                                                        let value = strainData?.infoEffects?.includes(title);
                                                        const selectedImage = []?.filter(data => data.title === title);
                                                        return <Grid key={index} item md={3} className='innerGridstyle'  >
                                                            <div key={index} className={`effect-chkbox ${value ? "active" : ""}`} onClick={(e) => onChangeCheckbox(e, title)}
                                                            >
                                                                <img src={`${process.env.REACT_APP_AMZAON_S3_URL}infoEffects${item.url}` || "/images/ProductEffectImage/default.svg"} alt={item.url || "default_image"} />
                                                                <CustomCheckbox
                                                                    label={capatlizedTitle}
                                                                    value={value || false}

                                                                />
                                                            </div>
                                                        </Grid>
                                                    })
                                                        :
                                                        null
                                                }
                                            </Grid>
                                        </FormGroup>
                                        :
                                        null
                                }
                            </Grid>
                            <hr></hr>
                            <Grid item xs={12}>
                                <Grid container className="mb-2">
                                    <Grid item xs={3}>
                                        <CustomCheckbox
                                            label={"Best Strain"}
                                            value={strainData?.isBestStrain || false}
                                            onChange={e => onEditorChangehandler(e.target.checked, "isBestStrain")}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <CustomCheckbox
                                            label={"Popular Strain"}
                                            value={strainData.isPopularStrain || false}
                                            onChange={e => onEditorChangehandler(e.target.checked, "isPopularStrain")}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} >
                                <CustomMultiSelect
                                    label="Flavors"
                                    name="name"
                                    fullWidth
                                    options={allFlavorsDataArr || []}
                                    handleChange={(e, val) =>
                                        handleChangeFlavor(e, val)
                                    }
                                    value={selectedFlavors || []}
                                    className="w-50"
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <TextField
                                    name="createdBy"
                                    value={strainData.createdBy || ""}
                                    onChange={onChangehandler}
                                    label="Created By"
                                    className="w-100"
                                    margin="normal"
                                    error={errors.createdBy}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <FormHelperText style={{ fontSize: '14px' }}>Title 2</FormHelperText>
                                    <CommonQuillEditor onChange={(value) => setStrainData({ ...strainData, title2: value })} id={"title2"} value={strainData.title2} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} >
                                <input type='file' name='filePath' onChange={imageChangeHandler} />
                                {
                                    strainData?.image && <img style={{ height: 100, width: 200 }} src={strainData?.image} />
                                }
                                {
                                    errors.image && <FormHelperText><div className='error' >{errors.image}</div></FormHelperText>
                                }
                            </Grid>

                            <Grid item xs={12} >
                                <Box my={4} className="d-flex jc-ctr add-button">
                                    <Grid container spacing={4}>
                                        <Grid item xs={6} lg={6}>
                                            <Button disabled={isUploading} onClick={() => addStrainHandler('save')} variant="contained" color="primary">{isLoading ? <CircularProgress size={22} style={{ color: "white" }} /> : 'Publish'}</Button>
                                        </Grid>
                                        <Grid item xs={6} lg={6}>
                                            <Box display='flex' justifyContent='flex-end' alignItems='center'>
                                                <Button disabled={isUploading} onClick={() => addStrainHandler('save_as_draft')} variant="contained" style={{ background: '#ff0000ad', color: 'white', display: 'flex', justifyContent: 'flex-end' }}>{isLoading ? <CircularProgress size={22} style={{ color: "white" }} /> : 'Save as Draft'}</Button>

                                            </Box>

                                        </Grid>
                                    </Grid>
                                </Box>

                            </Grid>
                        </Grid>

                    </Grid >
                </Card>
            </Box>
        </CommonLayout>
    );
}

export default AddHyperwolfStrain